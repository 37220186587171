import React, {
    ReactElement,
    KeyboardEvent,
    useState,
    Dispatch,
    SetStateAction,
} from 'react';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import Label from './Label';
import {
    EditFieldValueFileInterface,
    EditFieldValueType,
    FieldTableEntitiesInterface,
    FilterSize,
    Option,
    StateInterface,
    TableSelectFilterInterface,
    TextAreaHeight,
} from 'components/interfaces/GeneralInterface';
import Checkbox from 'components/forms/Checkbox';
import Select from 'components/forms/Select';
import Input, { InputType } from 'components/forms/Input';
import Autocomplete from 'components/forms/Autocomplete';
import { logErrorMessage } from 'utils/ErrorLogger';
import { useTranslation } from 'utils/localization';
import TextArea from 'components/forms/TextArea';
import DatePicker from 'components/forms/datepicker/DatePicker';
import File from 'components/forms/File';
import { FieldTypeApiEnum } from 'submodules/api_middleware';
import MultipleSelect from 'components/forms/MultipleSelect';
import PhotoUploader from 'components/photoUploader/PhotoUploader';
import { H3 } from 'components/typography';
import TableSelect from 'components/forms/TableSelect';
import TableEntities from 'components/forms/TableEntities';
import Phone from 'components/forms/Phone';
import Radio from 'components/forms/Radio';
import SubSubMenuWrapper from 'components/subSubMenuWrapper/SubSubMenuWrapper';
import MultipleSelectTable from 'components/forms/MultipleSelectTable';
import { MultipleSelectTableFilterInterface } from 'components/interfaces/MultipleSelectTableInterface';
import NumberInput from 'components/forms/NumberInput';

interface Props<MultipleSelectTableType = void> {
    biggerInput?: boolean;
    placeholder?: string;
    staticValues?: Option[];
    initValuesCb?: (value: string) => Promise<Option[]>;
    filterValue: EditFieldValueType;
    onChange: (
        name: string,
        value: boolean | string | string[] | number | Date
    ) => void;
    onKeyPress?: (e: KeyboardEvent<HTMLElement>) => void;
    onChangeFile?: (name: string, value: EditFieldValueFileInterface[]) => void;
    onBlur?: (name: string, value: boolean | string | number | Date) => void;
    type: FieldTypeApiEnum;
    name?: string;
    errorMessage?: string;
    className?: string;
    whiteBackground?: boolean;
    disabled?: boolean;
    min?: number | Date;
    max?: number | Date;
    onSearch?: (value: NextApiRequestQuery) => Promise<Option[]>;
    onSearchProperty?: string;
    attributes?: { [key: string]: string };
    hideArrows?: boolean;
    noEmptyOption?: boolean;
    withBorder?: boolean;
    usePopperContainer?: boolean;
    firstEmptyOption?: Option;
    setDisabledByImageUploader?: Dispatch<SetStateAction<boolean>>;
    acceptedFileTypes?: string[];
    textAreaHeight?: TextAreaHeight;
    checkboxLabel?: string;
    sizeFile?: FilterSize;
    disabledValues?: string[];
    withAdditionalModal?: boolean;
    alwaysRerenderOptions?: boolean;
    isEditForm?: boolean;
    autoFocus?: boolean;
    autocomplete?: string;
    tableSelect?: TableSelectFilterInterface;
    tableEntities?: FieldTableEntitiesInterface;
    multipleSelectTable?: MultipleSelectTableFilterInterface<MultipleSelectTableType>;
    fieldNameChanged?: StateInterface;
    reInitOnFields?: string[];
    reInitWithValueOnFields?: string[];
    withoutSort?: boolean;
    isDecimalNumber?: boolean;
    minLength?: number;
    maxLength?: number;
    snapToMin?: boolean;
    snapToMax?: boolean;
    onArrowClick?: (e: React.MouseEvent) => void;
}

/**
 *  A field component that displays concrete edit component for the field
 *  {@link type} (e.g. checkbox for {@link FieldTypeApiEnum.CHECKBOX}).
 */
const FilterInput = ({
    name,
    type,
    filterValue,
    placeholder,
    staticValues,
    onChange,
    onChangeFile,
    min,
    max,
    minLength,
    maxLength,
    onSearch,
    onSearchProperty,
    errorMessage,
    biggerInput,
    whiteBackground = true,
    attributes = {},
    onBlur,
    disabled,
    className,
    hideArrows,
    noEmptyOption,
    onKeyPress,
    firstEmptyOption,
    initValuesCb,
    setDisabledByImageUploader,
    withBorder,
    acceptedFileTypes,
    textAreaHeight,
    usePopperContainer,
    checkboxLabel,
    sizeFile,
    disabledValues,
    withAdditionalModal,
    tableSelect,
    tableEntities,
    alwaysRerenderOptions,
    autoFocus,
    autocomplete,
    fieldNameChanged,
    reInitOnFields,
    reInitWithValueOnFields,
    isEditForm,
    withoutSort,
    multipleSelectTable,
    onArrowClick,
    isDecimalNumber,
    snapToMin,
    snapToMax,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const [tempPhoto, setTempPhoto] = useState<string>('');

    switch (type) {
        case FieldTypeApiEnum.FILE:
            return (
                <File
                    name={name}
                    className={className}
                    value={
                        filterValue
                            ? (filterValue as EditFieldValueFileInterface[])
                            : []
                    }
                    maxFiles={typeof max === 'number' && max}
                    onChange={onChangeFile}
                    acceptedTypes={acceptedFileTypes}
                    size={sizeFile}
                    withAdditionalModal={withAdditionalModal}
                />
            );
        case FieldTypeApiEnum.IMAGE:
            return (
                <PhotoUploader
                    value={filterValue as string}
                    setValue={(value: string) => onChange(name, value)}
                    setTempPhoto={setTempPhoto}
                    tempPhoto={tempPhoto}
                    disableButtons={setDisabledByImageUploader}
                />
            );
        case FieldTypeApiEnum.CHECKBOX: {
            let checkBoxValue;
            if (
                (typeof filterValue === 'string' && filterValue === 'true') ||
                (typeof filterValue === 'boolean' && filterValue)
            ) {
                checkBoxValue = true;
            }

            return (
                <Checkbox
                    name={name}
                    onChange={({
                        target,
                    }: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(target.name, target.value)
                    }
                    value={checkBoxValue}
                    disabled={disabled}
                    bigger={biggerInput}
                    checkboxLabel={checkboxLabel}
                />
            );
        }
        case FieldTypeApiEnum.SELECT:
            return (
                <Select
                    name={name}
                    bigger={biggerInput}
                    options={staticValues}
                    errorMessage={errorMessage}
                    firstEmptyOption={firstEmptyOption}
                    onChange={({
                        target,
                    }: React.ChangeEvent<HTMLSelectElement>) => {
                        onChange(target.name, target.value);
                        onBlur && onBlur(target.name, target.value);
                    }}
                    initValuesCb={initValuesCb}
                    value={filterValue ? (filterValue as string) : undefined}
                    placeholder={placeholder}
                    disabled={disabled}
                    noEmptyOption={!!firstEmptyOption || noEmptyOption}
                    autoFocus={autoFocus}
                    isEditForm={isEditForm}
                />
            );
        case FieldTypeApiEnum.SELECT_MULTIPLE:
            return (
                <MultipleSelect
                    name={name}
                    bigger={biggerInput}
                    options={staticValues}
                    value={filterValue ? (filterValue as string[]) : ['']}
                    disabled={disabled}
                    allText={placeholder}
                    placeholder={
                        typeof placeholder === 'undefined'
                            ? t('general.all')
                            : placeholder
                    }
                    onChange={({
                        target,
                    }: React.ChangeEvent<HTMLSelectElement>) => {
                        onChange(target.name, target.value);
                        onBlur && onBlur(target.name, target.value);
                    }}
                    withoutSort={withoutSort}
                />
            );
        case FieldTypeApiEnum.SELECT_MULTIPLE_TABLE:
            return (
                <MultipleSelectTable
                    name={name}
                    values={filterValue as number[]}
                    table={multipleSelectTable.table}
                    searchTable={multipleSelectTable.searchTable}
                    searchPlaceholder={multipleSelectTable.searchPlaceholder}
                    onChange={({
                        target,
                    }: React.ChangeEvent<HTMLSelectElement>) => {
                        onChange(target.name, target.value);
                    }}
                    initValuesCb={multipleSelectTable.initValuesCb}
                    onSearch={multipleSelectTable.onSearch}
                    selectIdentifier={multipleSelectTable.selectIdentifier}
                    title={multipleSelectTable.title}
                    disabled={disabled}
                    advancedFilters={multipleSelectTable.advancedFilters}
                />
            );
        case FieldTypeApiEnum.AUTOCOMPLETE:
            return (
                <Autocomplete
                    name={name}
                    bigger={biggerInput}
                    options={staticValues}
                    onChange={({
                        target,
                    }: React.ChangeEvent<HTMLSelectElement>) => {
                        onChange(target.name, target.value);
                        onBlur && onBlur(target.name, target.value);
                    }}
                    errorMessage={errorMessage}
                    value={filterValue ? (filterValue as string) : undefined}
                    placeholder={
                        typeof placeholder === 'undefined'
                            ? t('general.enter')
                            : placeholder
                    }
                    onSearch={onSearch}
                    onSearchProperty={onSearchProperty}
                    disabled={disabled}
                    initValuesCb={initValuesCb}
                    whiteBackground={!withBorder && whiteBackground}
                    withBorder={withBorder}
                    disabledValues={disabledValues}
                    alwaysRerenderOptions={alwaysRerenderOptions}
                    reInitOnFields={reInitOnFields}
                    reInitWithValueOnFields={reInitWithValueOnFields}
                    fieldNameChanged={fieldNameChanged}
                    autoFocus={autoFocus}
                    isEditForm={isEditForm}
                />
            );

        case FieldTypeApiEnum.RADIO:
            return (
                <Radio
                    name={name}
                    className={className}
                    bigger={biggerInput}
                    value={filterValue as string | number}
                    options={staticValues}
                    onChange={onChange}
                    disabled={disabled}
                />
            );
        case FieldTypeApiEnum.NUMBER:
            return (
                <NumberInput
                    name={name}
                    className={className}
                    bigger={biggerInput}
                    errorMessage={errorMessage}
                    onKeyPress={onKeyPress}
                    placeholder={
                        typeof placeholder === 'undefined'
                            ? t('general.enter')
                            : placeholder
                    }
                    max={typeof max === 'number' ? max : undefined}
                    min={typeof min === 'number' ? min : undefined}
                    isDecimalNumber={isDecimalNumber}
                    value={filterValue as number | ''}
                    onChange={(value) => onChange(name, value)}
                    onBlur={
                        onBlur
                            ? ({
                                  target,
                              }: React.ChangeEvent<HTMLInputElement>) => {
                                  onBlur(target.name, target.value);
                              }
                            : undefined
                    }
                    onArrowClick={onArrowClick}
                    whiteBackground={!withBorder && whiteBackground}
                    withBorder={withBorder}
                    attributes={attributes}
                    disabled={disabled}
                    hideArrows={hideArrows}
                    autoFocus={autoFocus}
                    snapToMin={snapToMin}
                    snapToMax={snapToMax}
                />
            );
        case FieldTypeApiEnum.TIME:
        case FieldTypeApiEnum.DATE:
        case FieldTypeApiEnum.DATETIME:
            return (
                <DatePicker
                    name={name}
                    className={className}
                    bigger={biggerInput}
                    errorMessage={errorMessage}
                    placeholder={placeholder}
                    max={max as Date}
                    min={min as Date}
                    showOnlyTime={type === FieldTypeApiEnum.TIME}
                    showTime={type === FieldTypeApiEnum.DATETIME}
                    value={filterValue ? (filterValue as string | Date) : ''}
                    onChange={(date: string) => {
                        onChange(name, date);
                    }}
                    onBlur={
                        onBlur
                            ? ({
                                  target,
                              }: React.ChangeEvent<HTMLInputElement>) =>
                                  onBlur(
                                      target.name,
                                      filterValue as string | Date
                                  )
                            : undefined
                    }
                    whiteBackground={!withBorder && whiteBackground}
                    withBorder={withBorder}
                    attributes={attributes}
                    disabled={disabled}
                    usePopperContainer={usePopperContainer}
                />
            );
        case FieldTypeApiEnum.TEXT:
        case FieldTypeApiEnum.PASSWORD:
        case FieldTypeApiEnum.EMAIL:
            return (
                <Input
                    name={name}
                    className={className}
                    bigger={biggerInput}
                    onKeyPress={onKeyPress}
                    errorMessage={errorMessage}
                    type={type.toLocaleLowerCase() as InputType}
                    placeholder={placeholder}
                    value={filterValue ? (filterValue as string) : ''}
                    onChange={({
                        target,
                    }: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(target.name, target.value)
                    }
                    onBlur={
                        onBlur
                            ? ({
                                  target,
                              }: React.ChangeEvent<HTMLInputElement>) =>
                                  onBlur(target.name, target.value)
                            : undefined
                    }
                    whiteBackground={!withBorder && whiteBackground}
                    attributes={attributes}
                    disabled={disabled}
                    withBorder={withBorder}
                    autoFocus={autoFocus}
                    autocomplete={autocomplete}
                    minLength={minLength}
                    maxLength={maxLength}
                />
            );
        case FieldTypeApiEnum.TEXTAREA:
            return (
                <TextArea
                    name={name}
                    className={className}
                    bigger={biggerInput}
                    errorMessage={errorMessage}
                    placeholder={placeholder}
                    value={filterValue ? (filterValue as string) : ''}
                    onChange={({
                        target,
                    }: React.ChangeEvent<HTMLTextAreaElement>) =>
                        onChange(target.name, target.value)
                    }
                    onBlur={
                        onBlur
                            ? ({
                                  target,
                              }: React.ChangeEvent<HTMLTextAreaElement>) =>
                                  onBlur(target.name, target.value)
                            : undefined
                    }
                    whiteBackground={!withBorder && whiteBackground}
                    attributes={attributes}
                    disabled={disabled}
                    withBorder={withBorder}
                    height={textAreaHeight}
                    autoFocus={autoFocus}
                />
            );
        case FieldTypeApiEnum.LABEL:
            return (
                <Label value={filterValue as string} className={className} />
            );
        case FieldTypeApiEnum.HEADING:
            return <H3 className={className}>{filterValue as string}</H3>;
        case FieldTypeApiEnum.EMPTY:
            return <div />;
        case FieldTypeApiEnum.TABLE_SELECT:
            return (
                <TableSelect
                    name={name}
                    values={filterValue as string[]}
                    columns={tableSelect.columns}
                    searchPlaceholder={tableSelect.searchPlaceholder}
                    onChange={({
                        target,
                    }: React.ChangeEvent<HTMLSelectElement>) => {
                        onChange(target.name, target.value);
                    }}
                    initValuesCb={tableSelect.initValuesCb}
                    onSearch={tableSelect.onSearch}
                    labelKeyPrefix={tableSelect.labelKeyPrefix}
                    fieldNameChanged={fieldNameChanged}
                    reInitOnFields={reInitOnFields}
                />
            );
        case FieldTypeApiEnum.TABLE_ENTITIES:
            return (
                <TableEntities
                    name={name}
                    title={tableEntities.title}
                    modalTitle={tableEntities.modalTitle}
                    columns={tableEntities.columns}
                    initData={tableEntities.initData}
                    modalFields={tableEntities.modalFields}
                    onChange={({
                        target,
                    }: React.ChangeEvent<HTMLSelectElement>) => {
                        onChange(target.name, target.value);
                    }}
                />
            );
        case FieldTypeApiEnum.PHONE:
            return (
                <Phone
                    name={name}
                    className={className}
                    errorMessage={errorMessage}
                    placeholder={placeholder}
                    value={filterValue ? (filterValue as string) : ''}
                    onChange={(val: string) => onChange(name, val)}
                    onBlur={
                        onBlur ? (val: string) => onBlur(name, val) : undefined
                    }
                    disabled={disabled}
                    autoFocus={autoFocus}
                    bigger={biggerInput}
                    onKeyPress={onKeyPress}
                    whiteBackground={!withBorder && whiteBackground}
                    attributes={attributes}
                    withBorder={withBorder}
                />
            );
        case FieldTypeApiEnum.SUBSUBMENU:
            return (
                <SubSubMenuWrapper
                    name={name}
                    options={staticValues}
                    value={filterValue ? (filterValue as string) : undefined}
                    onChange={(name, value) => onChange(name, value)}
                    disabled={disabled}
                />
            );
        default:
            logErrorMessage(
                'FilterInput FieldTypeApiEnum - unknown value: ' + type
            );
            return null;
    }
};

export default FilterInput;
