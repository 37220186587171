import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Option } from 'components/interfaces/GeneralInterface';
import styles from './Radio.module.scss';

interface Props {
    name: string;
    value: string | number;
    options: Option[];
    disabled?: boolean;
    bigger?: boolean;
    onChange: (name: string, value: string | number) => void;
    className: string;
}

const Radio = ({
    name,
    value,
    options = [],
    onChange,
    disabled,
    bigger,
    className,
}: Props): ReactElement => {
    const handleChange = (innerValue: string | number) => {
        if (disabled) {
            return;
        }

        onChange(name, innerValue + '' === value + '' ? undefined : innerValue);
    };

    return (
        <div data-div-input="true" className={styles.root}>
            <div
                className={classNames(styles.wrapper, className, {
                    [styles.disabled]: disabled,
                    [styles.bigger]: bigger,
                })}
            >
                <input type={'radio'} name={name} value={value} />
                {options.map((option, key) => (
                    <div
                        key={'radio-' + key}
                        className={classNames(styles.radio, {
                            [styles.active]: option.value + '' === value + '',
                        })}
                        onClick={() => handleChange(option.value)}
                    >
                        <span>{option.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Radio;
