import {
    FileChangeInterface,
    FileInterface,
    NewFileInterface,
} from 'components/forms/File';
import { FileStateInterface } from 'components/interfaces/EditFormInterface';
import { EditFieldValueFileInterface } from 'components/interfaces/GeneralInterface';

export const toBase64 = (file: File, maxWidth?: number): Promise<any> => {
    if (maxWidth) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                let width = image.width;
                let ratio = 1;
                if (width > maxWidth) {
                    ratio = maxWidth / width;
                    width = maxWidth;
                }
                const height = image.height * ratio;
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(image, 0, 0, width, height);
                resolve(canvas.toDataURL());
            };
            image.onerror = (error) => reject(error);
        });
    }
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const getNewAndDeletedFiles = (
    filesState: FileStateInterface
): FileChangeInterface => {
    let changed: FileChangeInterface = {};
    if (!filesState) return changed;
    Object.keys(filesState).forEach((fieldName) => {
        const newFiles: NewFileInterface[] = [];
        const deletedFiles: FileInterface[] = [];
        const files: EditFieldValueFileInterface[] = filesState[fieldName];
        for (const file of files) {
            if (file.isDeleted && file.id && !file.id.startsWith('NEW')) {
                deletedFiles.push({
                    id: file.id,
                    fileName: file.fileName,
                });
            } else if (
                !file.isDeleted &&
                file.file &&
                file.id.startsWith('NEW')
            ) {
                newFiles.push({
                    file: file.file,
                    additionalData: file.additionalData,
                });
            }
        }
        changed = { ...changed, [fieldName]: { deletedFiles, newFiles } };
    });

    return changed;
};

export const createFileEditFieldData = (
    id: string,
    fileName: string,
    url: string
): EditFieldValueFileInterface => ({ id, fileName, url });
