import React, {
    ChangeEvent,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { useTranslation } from 'utils/localization';
import Table, { createTd, createTh } from 'components/tables/Table';
import {
    ColumnHeadInterface,
    ColumnSize,
    ColumnValueInterface,
    ColumnValueType,
    FieldTableSelectColumnInterface,
    FiltersInterface,
    StateInterface,
} from 'components/interfaces/GeneralInterface';
import { getMainFilter } from 'components/tables/filters/Filters';
import { PaginatorPageInterface } from 'components/interfaces/Paginator';
import styles from './TableSelect.module.scss';

interface Props {
    name: string;
    values: string[];
    onSearch: (query: NextApiRequestQuery) => Promise<any>;
    searchPlaceholder: string;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    initValuesCb?: (value: string | string[]) => Promise<any>;
    columns: FieldTableSelectColumnInterface[];
    labelKeyPrefix: string;
    fieldNameChanged: StateInterface;
    reInitOnFields: string[];
}

interface SelectedData {
    id: ColumnValueType;
    data: ColumnValueInterface[];
}

const getLastData = (
    data: ColumnValueInterface[][],
    id: ColumnValueType
): ColumnValueInterface[] => {
    let result: ColumnValueInterface[] = null;
    for (let i = 0; i < data.length; i++) {
        const row = data[parseInt(`${i}`)];
        if (row[row.length - 1].value === id) {
            result = row;
            break;
        }
    }

    return result;
};

export const getTableSelectIds = (
    initValues: ColumnValueInterface[][]
): ColumnValueType[] => initValues?.map((init) => init[init.length - 1].value);

const getData = (data: any[], columns: FieldTableSelectColumnInterface[]) => {
    return data?.map((obj) => {
        const rows: ColumnValueInterface[] = [];
        columns.forEach((row) => {
            rows.push(
                createTd(
                    typeof row.value === 'string'
                        ? obj[row.value as string]
                        : row.value(obj),
                    row.columnValueVeight
                )
            );
        });

        return rows;
    });
};

interface DataInterface extends PaginatorPageInterface {
    data: any[];
}

const TableSelect = ({
    name,
    values,
    columns,
    onSearch,
    searchPlaceholder,
    onChange,
    labelKeyPrefix,
    initValuesCb,
    reInitOnFields,
    fieldNameChanged,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const [data, setData] = useState<DataInterface>();
    const [selected, setSelected] = useState<SelectedData[]>([]);

    const initData = async () => {
        if (initValuesCb) {
            const result = await initValuesCb(values);
            if (result.data?.data) {
                setData({
                    data: result.data.data,
                    paginator: result.data.meta,
                });
                if (values && result.data.data.length > 0) {
                    const newSelected = selected.filter((s) =>
                        result.data.data.find(
                            (row: any) => row.id + '' === s.id + ''
                        )
                    );
                    setSelected(newSelected);
                    handleChange(newSelected);
                } else {
                    setSelected([]);
                    handleChange([]);
                }
            }
        }
    };

    useEffect(() => {
        initData().then(() => {
            if (values && onSearch) {
                onSearch({
                    id: values,
                }).then((result) => {
                    const initData = result.data?.data?.map(
                        (init: any): SelectedData => ({
                            id: init.id,
                            data: getData([init], columns)[0],
                        })
                    );
                    setSelected(initData || []);
                });
            }
        });
    }, []);

    useEffect(() => {
        if (reInitOnFields?.includes(Object.keys(fieldNameChanged)[0])) {
            initData();
        }
    }, [fieldNameChanged]);

    const searchHandler = (query: NextApiRequestQuery) => {
        if (onSearch) {
            onSearch(query).then((result) =>
                setData({
                    data: result.data.data,
                    paginator: result.data.meta,
                })
            );
        }
    };

    const tableColumns: ColumnHeadInterface[] = useMemo<
        ColumnHeadInterface[]
    >(() => {
        const cols: ColumnHeadInterface[] = [];
        columns.forEach((column) => {
            if (!column.isContextMenuIdentifier) {
                cols.push(
                    createTh(
                        t,
                        `${labelKeyPrefix}.${column.title}`,
                        column.columnSize
                    )
                );
            }
        });

        return cols;
    }, []);

    const tableSelectData = getData(data?.data || [], columns);

    let addColumns: ColumnHeadInterface[] = [];
    let selectedColumns: ColumnHeadInterface[] = [];
    if (columns.some((col) => col.isContextMenuIdentifier)) {
        addColumns = tableColumns.concat(
            createTh(
                t,
                undefined,
                ColumnSize.SFIX,
                undefined,
                undefined,
                undefined,
                {
                    onClick: (id) => {
                        setSelected(
                            selected.concat({
                                id: id,
                                data: getLastData(tableSelectData, id),
                            })
                        );
                        handleChange([...selected.map((s) => s.id), id]);
                    },
                    label: t('actions.add').toUpperCase(),
                    disabled: selected.length
                        ? (id) => selected.some((s) => s.id === id)
                        : false,
                }
            )
        );

        selectedColumns = tableColumns.concat(
            createTh(
                t,
                undefined,
                ColumnSize.SFIX,
                undefined,
                undefined,
                undefined,
                {
                    onClick: (id) => {
                        const newSelected = selected.filter((s) => s.id !== id);
                        setSelected(newSelected);
                        handleChange(
                            newSelected.length
                                ? [...newSelected.map((s) => s.id)]
                                : undefined
                        );
                    },
                    label: t('actions.remove').toUpperCase(),
                }
            )
        );
    }

    const handleChange = (ids: ColumnValueType[]) => {
        onChange({
            target: {
                name,
                //@ts-ignore-next-line
                value: ids,
            },
        });
    };

    const filterConfig: FiltersInterface = {
        fixed: {
            mainField: getMainFilter(
                searchPlaceholder
                    ? t(searchPlaceholder)
                    : 'general.searchPlaceholder',
                'query'
            ),
            showAdvancedBtn: false,
            onSearch: searchHandler,
        },
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.flexItem}>
                <Table
                    filterConfig={filterConfig}
                    columns={addColumns}
                    data={tableSelectData}
                    paginator={data?.paginator}
                    isModalView
                />
            </div>
            <div className={styles.flexItem}>
                <Table
                    columns={selectedColumns}
                    data={[...selected.map((s) => s.data)]}
                    isModalView
                />
            </div>
        </div>
    );
};

export default TableSelect;
