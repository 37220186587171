import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import Modal from './Modal';
import { DialogStatus } from 'types/types';
import Alert from 'components/icon/icons/alert.svg';
import Button from 'components/forms/Button';
import { useTranslation } from 'utils/localization';
import { OnSuccessType } from 'components/interfaces/EditFormInterface';
import styles from './Modal.module.scss';

interface Props {
    status?: DialogStatus;
    onHide: () => void;
    show: boolean;
    onConfirm: () => OnSuccessType;
    title: string;
    text?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    retryButtonText?: string;
    successText?: string;
    errorText?: string;
    loadingText?: string;
    isLoading?: boolean;
    textToLeft?: boolean;
}

const ConfirmModal = ({
    status = 'default',
    onHide,
    show,
    onConfirm,
    confirmButtonText,
    cancelButtonText,
    retryButtonText,
    title,
    text,
    successText,
    loadingText,
    errorText,
    isLoading: loading,
    textToLeft,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState<boolean>(loading);
    const innerLoading = isLoading || loading;

    const onConfirmAction = async () => {
        const result = await onConfirm();
        if (typeof result === 'boolean') {
            setLoading(!result);
        } else {
            setLoading(false);
        }
    };

    return (
        <Modal
            badge={
                status === 'default' || status === 'error'
                    ? {
                          background: 'warning',
                          icon: Alert,
                      }
                    : null
            }
            onHide={onHide}
            show={show}
            status={status}
            successText={successText}
            loadingText={loadingText}
            isAlertModal
            title={title}
            centerTitle
        >
            <div className={styles.modalContent}>
                <span
                    className={classNames(styles.modalText, {
                        [styles.left]: textToLeft,
                    })}
                    dangerouslySetInnerHTML={{
                        __html: status === 'error' ? errorText : text,
                    }}
                />
                <div className={styles.modalButtons}>
                    <Button
                        isTransparent
                        onClick={onHide}
                        resolveImmediately
                        isLoading={innerLoading}
                    >
                        {cancelButtonText || (t('general.cancel') as string)}
                    </Button>
                    <Button onClick={onConfirmAction} isLoading={innerLoading}>
                        {status === 'error'
                            ? retryButtonText || (t('general.retry') as string)
                            : confirmButtonText ||
                              (t('general.confirm') as string)}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
