import React, { ReactElement } from 'react';
import Modal from 'components/modal/Modal';
import EditForm from 'components/editForm/EditForm';
import { ValuesStringInterface } from 'submodules/api_middleware';
import { GroupEditFieldInterface } from 'components/interfaces/GeneralInterface';
import { FileStateInterface } from 'components/interfaces/EditFormInterface';

interface Props {
    show: boolean;
    onHide: () => void;
    fields: GroupEditFieldInterface[];
    action: 'add' | 'edit';
    onAction: (
        action: 'add' | 'edit',
        values: ValuesStringInterface,
        files: FileStateInterface
    ) => void;
    modalTitle: string;
}

const TableEntitiesModal = ({
    show,
    onHide,
    fields,
    onAction,
    action,
    modalTitle,
}: Props): ReactElement => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            showCross
            title={modalTitle}
            centerTitle
        >
            <>
                <EditForm
                    fields={fields}
                    submit={(values, files) => {
                        onAction(action, values, files);
                        return Promise.resolve({ ok: true });
                    }}
                    onSuccess={() => onHide()}
                    withCancelChangesBtn
                    cancelButtonTextKey={'general.cancel'}
                    buttonTextKey={
                        action === 'add' ? 'actions.add' : 'general.edit'
                    }
                    cancel={onHide}
                    centerButtons
                />
            </>
        </Modal>
    );
};

export default TableEntitiesModal;
