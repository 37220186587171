import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useTranslation } from 'utils/localization';
import { LinkInterface } from 'components/interfaces/GeneralInterface';
import A from 'components/layouts/A';
import SubSubMenuSC from 'components/subSubMenuWrapper/SubSubMenu.styled';
import { getDynamicPathName } from 'utils/index';

export interface Props {
    links: LinkInterface[];
}

const SubSubMenu = ({ links }: Props): ReactElement => {
    const { t } = useTranslation();
    const router = useRouter();

    return (
        <SubSubMenuSC>
            {links.map(({ text, link }, key) => {
                const linkNoDiscipline = link ? link.split('?')[0] : link;
                // eslint-disable-next-line security/detect-non-literal-regexp
                const regex = new RegExp(`^${linkNoDiscipline}$`);
                const currentRoutePath = getDynamicPathName(router);
                const isActive = regex.test(currentRoutePath);

                return (
                    <A
                        key={`${key}-${text}`}
                        className={classNames('item', {
                            active: isActive,
                            noHover: !link,
                        })}
                        href={link}
                    >
                        {t(text) as string}
                    </A>
                );
            })}
        </SubSubMenuSC>
    );
};

export default SubSubMenu;
