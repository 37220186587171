import { AxiosResponse } from 'axios';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { MessageType, pushMessage } from 'components/layouts/Toast';
import { ExportApiType } from 'submodules/api_middleware';
import { getLocationSearchQuery } from 'submodules/api_middleware/src/client';

export interface ExportTableProps {
    fetchApi: ExportTableFetchApiType;
    outputKey: string;
    messageErrorKey: string;
}

export interface ConfirmModalInterface {
    response?: ExportApiType;
    type: 'loading' | 'success' | 'error';
}

export type ExportTableFetchApiType = (
    query?: NextApiRequestQuery
) => Promise<ExportApiType | AxiosResponse<ExportApiType>>;

export const exportTable = async ({
    fetchApi,
    outputKey,
    messageErrorKey,
}: ExportTableProps): Promise<ExportApiType> => {
    const query = getLocationSearchQuery();
    const res: any = await fetchApi({
        ...query,
        output: outputKey,
    });

    if (res) {
        if (res.data && (res.data.url || res.data.email)) {
            return res.data;
        } else if (res.url || res.email) {
            return res;
        }
    } else {
        pushMessage(messageErrorKey, MessageType.DANGER);
        return {
            errorKey: messageErrorKey,
        };
    }
};
