import React, {
    ChangeEvent,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import MultipleSelectTableModal from './MultipleSelectTableModal';
import { useTranslation } from 'utils/localization';
import Table, { createTd, createTh } from 'components/tables/Table';
import {
    ColumnColor,
    ColumnHeadInterface,
    ColumnSize,
    ColumnValueWeight,
    FilterFieldInterface,
} from 'components/interfaces/GeneralInterface';
import { MultipleSelectTableBaseInterface } from 'components/interfaces/MultipleSelectTableInterface';

interface Props<ModelType> extends MultipleSelectTableBaseInterface<ModelType> {
    name: string;
    values: number[];
    onSearch: (query: NextApiRequestQuery) => Promise<any>;
    initValuesCb?: (value: string | string[]) => Promise<any>;
    searchPlaceholder: string;
    advancedFilters: FilterFieldInterface[];
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    disabled: boolean;
}

const MultipleSelectTable = <ModelType,>({
    name,
    values,
    table,
    searchTable,
    onSearch,
    initValuesCb,
    onChange,
    searchPlaceholder,
    advancedFilters,
    selectIdentifier,
    title,
    disabled,
}: Props<ModelType>): ReactElement => {
    const { t } = useTranslation();
    const [selectedData, setSelectedData] = useState<ModelType[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        // init values
        if (values && values.length > 0 && onSearch) {
            onSearch({
                id: values.map((v) => v + ''),
            }).then((result) => {
                setSelectedData(result.data?.data || []);
            });
        }
    }, []);

    const tableColumns: ColumnHeadInterface[] = useMemo<ColumnHeadInterface[]>(
        () => [
            ...table.map((tableColumn) => tableColumn.column),
            ...(disabled
                ? []
                : [createTh(t, undefined, ColumnSize.SMFIX, undefined, true)]),
        ],
        [table, disabled]
    );

    const tableSelectData = selectedData.map((selected) => [
        ...table.map((tableColumn) => tableColumn.data(selected)),
        ...(disabled
            ? []
            : [
                  createTd(
                      {
                          linkLabel: t('actions.remove'),
                          onClick: () => handleRemove(selected),
                      },
                      ColumnValueWeight.BOLD,
                      ColumnColor.RED
                  ),
              ]),
    ]);

    const handleChange = (value: ModelType) => {
        const identifier = selectIdentifier(value);
        setSelectedData([...selectedData, value]);

        if (values) _onChange([...values, identifier]);
        else _onChange([identifier]);
    };

    const handleRemove = (value: ModelType) => {
        const identifier = selectIdentifier(value);
        setSelectedData(
            selectedData.filter((sd) => selectIdentifier(sd) !== identifier)
        );

        _onChange(values.filter((v) => v !== identifier));
    };

    const _onChange = (values: unknown) => {
        onChange({
            target: {
                name,
                //@ts-ignore-next-line
                value: values,
            },
        });
    };

    return (
        <div data-name={name}>
            {showModal && (
                <MultipleSelectTableModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    searchTable={searchTable}
                    onSearch={onSearch}
                    initValuesCb={initValuesCb}
                    onSelect={handleChange}
                    onRemove={handleRemove}
                    values={values}
                    selectIdentifier={selectIdentifier}
                    searchPlaceholder={searchPlaceholder}
                    title={title}
                    advancedFilters={advancedFilters}
                />
            )}
            <Table
                columns={tableColumns}
                data={tableSelectData}
                actions={
                    disabled
                        ? undefined
                        : [
                              {
                                  type: 'add',
                                  onClick: () => setShowModal(true),
                              },
                          ]
                }
                cardTitle={title}
                smallerTitle
                isModalView
            />
        </div>
    );
};

export default MultipleSelectTable;
