import React, { ReactElement } from 'react';

import styles from './MobileBreadcrumbs.module.scss';

interface Props {
    step: number;
    stepCount: number;
}

const MobileBreadcrumbs = ({ step, stepCount }: Props): ReactElement => {
    return (
        <div className={styles.root}>
            {step + 1} / {stepCount}
        </div>
    );
};

export default MobileBreadcrumbs;
