import React, { FC, KeyboardEvent } from 'react';
import Input, { InputType } from './Input';

interface Props {
    name?: string;
    value?: string;
    placeholder?: string;
    errorMessage?: string;
    className?: string;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (value: string) => void;
    attributes?: { [key: string]: string };
    disabled?: boolean;
    autoFocus?: boolean;
    bigger?: boolean;
    whiteBackground?: boolean;
    withBorder?: boolean;
}

const defaultPrefix = '+420';

const Phone: FC<Props> = ({
    name,
    value,
    errorMessage,
    placeholder,
    className,
    onChange,
    onBlur,
    onKeyPress,
    onFocus,
    attributes,
    disabled = false,
    autoFocus,
    bigger,
    whiteBackground,
    withBorder,
}) => {
    const _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const defaultVal = placeholder == null ? defaultPrefix : placeholder;
        if (!value || !value.length) {
            e.currentTarget.value = defaultVal;
            e.currentTarget.setSelectionRange(
                defaultVal.length,
                defaultVal.length
            );
            onChange?.(e.currentTarget.value);
        }
        onFocus?.(e.currentTarget.value);
    };
    return (
        <Input
            name={name}
            className={className}
            errorMessage={errorMessage}
            type={InputType.text}
            placeholder={placeholder == null ? defaultPrefix : placeholder}
            value={value}
            onChange={(e) => onChange?.(e.currentTarget.value)}
            onBlur={(e) => onBlur?.(e.currentTarget.value)}
            onFocus={_onFocus}
            onKeyPress={onKeyPress}
            whiteBackground={!withBorder && whiteBackground}
            attributes={attributes}
            disabled={disabled}
            withBorder={withBorder}
            bigger={bigger}
            autoFocus={autoFocus}
        />
    );
};

export default Phone;
