import React, {
    ChangeEvent,
    KeyboardEvent,
    ReactElement,
    Ref,
    CSSProperties,
    useRef,
} from 'react';
import classNames from 'classnames';
import { TextAreaHeight } from 'components/interfaces/GeneralInterface';
import styles from './TextArea.module.scss';

interface Props {
    name?: string;
    value?: string | number;
    placeholder?: string;
    className?: string;
    bigger?: boolean;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
    autocomplete?: string;
    whiteBackground?: boolean;
    withBorder?: boolean;
    withBottomBorder?: boolean;
    defaultValue?: string;
    onClick?: () => void;
    highlighted?: boolean;
    forwardRef?: Ref<any>;
    errorMessage?: string;
    style?: CSSProperties;
    inputStyle?: CSSProperties;
    attributes?: { [key: string]: string };
    disabled?: boolean;
    height?: TextAreaHeight;
    autoFocus?: boolean;
}

const TextArea = ({
    name,
    placeholder,
    className,
    bigger,
    onChange,
    onKeyDown,
    autocomplete,
    value,
    disabled,
    whiteBackground,
    withBorder,
    onClick,
    onBlur,
    highlighted,
    forwardRef,
    style,
    inputStyle,
    errorMessage,
    withBottomBorder,
    attributes = {},
    height,
    autoFocus,
}: Props): ReactElement => {
    const inputRef = useRef(null);

    return (
        <div className={styles.wrapper}>
            <div
                className={classNames(styles.root, className, {
                    [styles.withBottomBorder]: !!withBottomBorder,
                    [styles.highlighted]: highlighted,
                    [styles.whiteBackground]: whiteBackground,
                })}
                onClick={onClick}
                style={style}
            >
                <textarea
                    name={name}
                    className={classNames(styles.input, {
                        [styles.bigger]: bigger,
                        [styles.withBorder]: withBorder,
                        [styles.noPadding]: whiteBackground,
                        [styles[`height-${height}`]]: !!height,
                    })}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    autoComplete={autocomplete}
                    value={value}
                    disabled={disabled}
                    ref={forwardRef || inputRef}
                    style={inputStyle}
                    autoFocus={autoFocus}
                    {...attributes}
                />
                {!!errorMessage && (
                    <div className={styles.error}>{errorMessage}</div>
                )}
            </div>
        </div>
    );
};

export default TextArea;
