import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { TFunction } from 'next-i18next';
import Tooltip from 'components/layouts/Tooltip';
import Select from 'components/forms/Select';
import DatePicker from 'components/forms/datepicker/DatePicker';
import TextArea from 'components/forms/TextArea';
import {
    isNumberField,
    validateNumberMinMax,
} from 'utils/helpers/validateNumber';
import {
    ColumnInputInterface,
    ColumnValueType,
} from 'components/interfaces/GeneralInterface';
import NumberInput from 'components/forms/NumberInput';
import styles from './Table.module.scss';

interface Props {
    t: TFunction;
    value: ColumnValueType;
    input: ColumnInputInterface;
}

const ColumnContentInput = (props: Props): ReactElement => {
    const { t, value, input } = props;
    let validation;
    const isNumberType = input.type === 'number';
    if (isNumberType) {
        const numberError =
            value &&
            isNumberField(
                t,
                value as string | number,
                input.isDecimalNumber ? 'decimal' : 'integer'
            );
        if (!numberError) {
            if (
                value &&
                input.withValidation &&
                input.validation &&
                (input.validation.min || input.validation.max)
            ) {
                validation = validateNumberMinMax(
                    t,
                    value as number,
                    input.validation.min,
                    input.validation.max
                );
            }
        } else {
            validation = numberError;
        }
    }

    let el;
    const isRequired = input.isRequired && !value;
    if (input.type === 'select') {
        el = (
            <Select
                value={value as string}
                placeholder={input.placeholder}
                options={input.values}
                name={input.name}
                onChange={input.onChange}
            />
        );
    } else if (input.type === 'date') {
        el = (
            <DatePicker
                value={value as string}
                name={input.name}
                onChange={input.onChange}
                usePopperContainer
            />
        );
    } else if (input.type === 'textarea') {
        el = (
            <TextArea
                value={value as string}
                name={input.name}
                onChange={input.onChange}
            />
        );
    } else if (isNumberType) {
        el = (
            <NumberInput
                name={input.name}
                value={value as number}
                onChange={input.onChange}
                min={
                    (input.withValidation && input.validation?.min) || undefined
                }
                max={
                    (input.withValidation && input.validation?.max) || undefined
                }
                className={styles.specialInput}
                readOnly={!input.onChange}
                onFocus={input.onFocus}
                onBlur={input.onBlur}
                isDecimalNumber={input.isDecimalNumber}
                hideArrows
            />
        );

        el = (
            <Tooltip label={validation} hideTooltip={!validation}>
                {el}
            </Tooltip>
        );
    } else {
        el = (
            <input
                value={value as string}
                onChange={input.onChange}
                checked={input.type === 'checkbox' ? Boolean(value) : false}
                name={input.name}
                type={input.type}
                readOnly={!input.onChange}
                onFocus={input.onFocus}
                onBlur={input.onBlur}
                placeholder={input.placeholder}
                className={classNames(styles.specialInput, {
                    [styles.fieldRequired]: isRequired || !!validation,
                })}
            />
        );
    }

    if (input.isRounded) {
        return (
            <div
                className={classNames(styles.tableRoundInput, {
                    [styles.fieldRequired]: isRequired || !!validation,
                })}
            >
                {el}
            </div>
        );
    }

    return el;
};

export default ColumnContentInput;
