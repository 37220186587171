import React, { ReactElement, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import Filters, { getInitFilterData } from './filters/Filters';
import SortableTable from './sortable/SortableTable';
import Row, { Tr } from './Row';
import { Td } from './Column';
import TableHeader from './TableHeader';
import Loading from 'components/loading/Loading';
import H3 from 'components/typography/H3';
import { plurals, useTranslation } from 'utils/localization';
import {
    ColumnColor,
    ColumnContextBtnInterface,
    ColumnHeadChooseInterface,
    ColumnHeadInterface,
    ColumnHeadSortType,
    ColumnInputInterface,
    ColumnSize,
    ColumnValueInterface,
    ColumnValueType,
    ColumnValueWeight,
    DetailLinkPathThType,
    FiltersInterface,
    InlineEditType,
    LinkDataInterface,
    StateInterface,
} from 'components/interfaces/GeneralInterface';
import Paginator from 'components/paginator/Paginator';
import CardBlock from 'components/blocks/CardBlock';
import CardIcon, { CardIconType } from 'components/card/CardIcon';
import Button from 'components/forms/Button';
import {
    AgentReference,
    ApplicationEntityReferenceApiInterface,
    ClubCompetitionReferenceApiInterface,
    ClubReferenceApiInterface,
    CompetitionReferenceApiInterface,
    DisciplineEnum,
    EntityReferenceApiEnum,
    EntityReferenceApiType,
    ExportErrorApiInterface,
    FieldTypeApiEnum,
    LegalPersonReferenceApiInterface,
    MatchReferenceApiInterface,
    OrganisationUnitReferenceApiInterface,
    PaginatorInterface,
    PersonEntityReferenceApiInterface,
    ProceedingEntityReferenceApiInterface,
    ProceedingTypeApiEnum,
    SubsidyReference,
    UsersGroupReference,
} from 'submodules/api_middleware';
import H2 from 'components/typography/H2';
import { getRoute } from 'constants/routes';
import { isBrowser } from 'utils';
import { getLocationSearchQuery } from 'submodules/api_middleware/src/client';
import ExportConfirmModal from 'components/pages/coach/list/ExportConfirmModal';
import { ConfirmModalInterface, exportTable } from 'utils/helpers/exportTable';
import styles from './Table.module.scss';

export const createDetailLinkTh = (
    detailLinkPath: DetailLinkPathThType
): ColumnHeadInterface => ({
    label: '',
    size: ColumnSize.SFIX,
    detailLinkPath,
    isCentered: true,
});

export const DETAIL_TH: ColumnHeadInterface = {
    label: '',
    size: ColumnSize.SFIX,
    isCentered: true,
};

export const createBooleanTh = (
    t: TFunction,
    label?: string,
    size: ColumnSize = ColumnSize.M
): ColumnHeadInterface => ({
    label: label ? t(label) : '',
    size,
    isCentered: true,
});

export const CONTEXT_MENU_TH: ColumnHeadInterface = {
    label: '',
    size: ColumnSize.SFIX,
    isCentered: true,
};

export const createTh = (
    t: TFunction,
    label?: string,
    size: ColumnSize = ColumnSize.M,
    // TODO: sjednotit a jiz nepouzivat - pouzivat v TD
    contextOptions?: ColumnContextBtnInterface | ColumnContextBtnInterface[],
    isCentered?: boolean,
    icon?: React.FC<React.SVGProps<SVGSVGElement>>,
    onChoose?: ColumnHeadChooseInterface,
    isRowSortable?: boolean,
    sortBy?: ColumnHeadSortType,
    tableName?: string
): ColumnHeadInterface => ({
    label: label ? t(label) : '',
    size,
    contextOptions,
    isRowSortable,
    onChoose,
    isCentered,
    icon,
    sortBy,
    tableName,
});

export const createTd = (
    value: ColumnValueType,
    valueWeight: ColumnValueWeight = ColumnValueWeight.NORMAL,
    color?: ColumnColor,
    input?: ColumnInputInterface,
    inlineEdit?: InlineEditType,
    tooltip?: string
): ColumnValueInterface => ({
    value,
    valueWeight,
    color,
    input,
    inlineEdit,
    tooltip,
});

export const createGroupByTd = (
    value: string | number,
    valueWeight: ColumnValueWeight = ColumnValueWeight.NORMAL
): ColumnValueInterface => ({
    type: FieldTypeApiEnum.GROUP_BY,
    value,
    valueWeight,
});

const getLinkUrlByType = (
    type: EntityReferenceApiEnum,
    reference: EntityReferenceApiType,
    discipline?: DisciplineEnum
): string => {
    switch (type) {
        case EntityReferenceApiEnum.person:
            return getRoute(
                '/members/{0}',
                [(reference as PersonEntityReferenceApiInterface).person_id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        case EntityReferenceApiEnum.referee:
            return getRoute(
                '/referees/{0}',
                [(reference as PersonEntityReferenceApiInterface).person_id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        case EntityReferenceApiEnum.delegate:
            return getRoute(
                '/delegates/{0}',
                [(reference as PersonEntityReferenceApiInterface).person_id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        case EntityReferenceApiEnum.player:
            return getRoute(
                '/players/{0}',
                [(reference as PersonEntityReferenceApiInterface).person_id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        case EntityReferenceApiEnum.club:
            return getRoute(
                '/clubs/{0}',
                [(reference as ClubReferenceApiInterface).id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        case EntityReferenceApiEnum.club_competition: {
            const clubCompetition =
                reference as ClubCompetitionReferenceApiInterface;
            return getRoute(
                '/clubs/{0}/competitions/{1}/team/{2}/stats',
                [
                    clubCompetition.club_id,
                    clubCompetition.competition_id,
                    clubCompetition.team_id,
                ],
                undefined,
                undefined,
                undefined,
                discipline
            );
        }
        case EntityReferenceApiEnum.competition:
            return getRoute(
                '/leagues/{0}',
                [(reference as CompetitionReferenceApiInterface).id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        case EntityReferenceApiEnum.match:
            return getRoute(
                '/matches/{0}',
                [(reference as MatchReferenceApiInterface).id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        case EntityReferenceApiEnum.organisation_unit:
            return getRoute(
                '/organisation-units/{0}',
                [(reference as OrganisationUnitReferenceApiInterface).id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        case EntityReferenceApiEnum.legal_person:
            return getRoute(
                '/legal-persons/{0}',
                [(reference as LegalPersonReferenceApiInterface).id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        case EntityReferenceApiEnum.agent:
            return getRoute(
                '/agents/{0}',
                [(reference as AgentReference).id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        case EntityReferenceApiEnum.proceeding: {
            const proceeding =
                reference as ProceedingEntityReferenceApiInterface;
            if (proceeding.type === ProceedingTypeApiEnum.stk)
                return getRoute('/proceedings-stk/{0}', [proceeding.id]);
            return getRoute(
                '/proceedings-dk/{0}',
                [proceeding.id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        }
        case EntityReferenceApiEnum.application: {
            return getRoute(
                '/applications/{0}',
                [(reference as ApplicationEntityReferenceApiInterface).id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        }
        case EntityReferenceApiEnum.subsidy: {
            return getRoute(
                '/subsidies/{0}',
                [(reference as SubsidyReference).id],
                undefined,
                undefined,
                undefined,
                discipline
            );
        }
    }
};

const getOneLineAdditionalLabelInBrackets = (
    t: TFunction,
    type: EntityReferenceApiEnum,
    reference: EntityReferenceApiType
): string => {
    switch (type) {
        case EntityReferenceApiEnum.person:
        case EntityReferenceApiEnum.player:
        case EntityReferenceApiEnum.club:
        case EntityReferenceApiEnum.legal_person:
        case EntityReferenceApiEnum.referee:
        case EntityReferenceApiEnum.delegate:
            return (reference as ClubReferenceApiInterface).internal_id;
        case EntityReferenceApiEnum.competition:
            return (reference as CompetitionReferenceApiInterface).identifier;
        case EntityReferenceApiEnum.agent:
            return (reference as AgentReference).identifier;
        case EntityReferenceApiEnum.match:
            return t('Match.match');
        case EntityReferenceApiEnum.organisation_unit:
            return t('Decision.orgUnit');
        case EntityReferenceApiEnum.proceeding:
            return t('pages.proceedings');
        case EntityReferenceApiEnum.application: {
            const ref = reference as ApplicationEntityReferenceApiInterface;
            return ref.current_state_label
                ? ref.current_state_label
                : t('Applications.state.' + ref.current_state);
        }
        default:
            return undefined;
    }
};

const getLinkLabel = (
    t: TFunction,
    type: EntityReferenceApiEnum,
    reference: EntityReferenceApiType
): string => {
    switch (type) {
        case EntityReferenceApiEnum.person:
        case EntityReferenceApiEnum.referee:
        case EntityReferenceApiEnum.delegate:
        case EntityReferenceApiEnum.player:
        case EntityReferenceApiEnum.competition:
        case EntityReferenceApiEnum.organisation_unit:
        case EntityReferenceApiEnum.legal_person:
        case EntityReferenceApiEnum.club_competition:
            return (
                reference as
                    | PersonEntityReferenceApiInterface
                    | OrganisationUnitReferenceApiInterface
                    | CompetitionReferenceApiInterface
            ).name;
        case EntityReferenceApiEnum.club: {
            const club = reference as ClubReferenceApiInterface;
            return club.small_name || club.name;
        }
        case EntityReferenceApiEnum.match: {
            const match = reference as MatchReferenceApiInterface;
            return match.identifier || match.name;
        }
        case EntityReferenceApiEnum.proceeding: {
            const proceeding =
                reference as ProceedingEntityReferenceApiInterface;
            return `${t(`Proceedings.types.${proceeding.type}`)} ${
                proceeding.identifier
            }`;
        }
        case EntityReferenceApiEnum.agent: {
            const agent = reference as AgentReference;
            return agent.legal_person
                ? agent.legal_person.name
                : agent.person.name;
        }
        case EntityReferenceApiEnum.application:
            return t('general.openApplication');
        case EntityReferenceApiEnum.subsidy:
            return (reference as SubsidyReference).name;
        case EntityReferenceApiEnum.users_groups:
            return t(`RoleGroup.${(reference as UsersGroupReference).key}`);
    }
};

interface LinkIconInterface {
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    withSelectIcon?: boolean;
    className: string;
}

const isReferenceValid = (
    type: EntityReferenceApiEnum,
    reference: EntityReferenceApiType
) => {
    if (!reference) return false;
    let ref;

    switch (type) {
        case EntityReferenceApiEnum.person:
        case EntityReferenceApiEnum.referee:
        case EntityReferenceApiEnum.delegate:
        case EntityReferenceApiEnum.player:
            ref = reference as PersonEntityReferenceApiInterface;
            return !!(ref.person_id && ref.name);
        case EntityReferenceApiEnum.competition:
        case EntityReferenceApiEnum.organisation_unit:
        case EntityReferenceApiEnum.legal_person:
            ref = reference as
                | CompetitionReferenceApiInterface
                | OrganisationUnitReferenceApiInterface;
            return !!(ref.id && ref.name);
        case EntityReferenceApiEnum.club:
            ref = reference as ClubReferenceApiInterface;
            return !!(ref.id && (ref.name || ref.small_name));
        case EntityReferenceApiEnum.match:
            ref = reference as MatchReferenceApiInterface;
            return !!(ref.id && (ref.identifier || ref.name));
        case EntityReferenceApiEnum.agent:
            ref = reference as AgentReference;
            return !!(
                ref.id &&
                ((ref.legal_person && ref.legal_person.name) ||
                    (ref.person && ref.person.name))
            );
        case EntityReferenceApiEnum.application:
            return !!(reference as ApplicationEntityReferenceApiInterface).id;
        case EntityReferenceApiEnum.subsidy:
            ref = reference as SubsidyReference;
            return !!(ref.id && ref.name);
        case EntityReferenceApiEnum.club_competition:
            ref = reference as ClubCompetitionReferenceApiInterface;
            return !!(
                ref.club_id &&
                ref.competition_id &&
                ref.team_id &&
                ref.name
            );
        case EntityReferenceApiEnum.proceeding:
            ref = reference as ProceedingEntityReferenceApiInterface;
            return !!(ref.id && ref.type);
        case EntityReferenceApiEnum.users_groups:
            return !!(reference as UsersGroupReference).key;
        default:
            return false;
    }
};

export const createLinkByType = (
    t: TFunction,
    reference: EntityReferenceApiType,
    type: EntityReferenceApiEnum,
    nonePlaceholder = '-',
    twoRows?: boolean,
    noAdditionalData?: boolean,
    linkIcon?: LinkIconInterface,
    discipline?: DisciplineEnum,
    noLinkOnlyString?: boolean
): LinkDataInterface | string => {
    if (!isReferenceValid(type, reference)) return nonePlaceholder;
    const url = getLinkUrlByType(type, reference, discipline);
    const linkLabel = getLinkLabel(t, type, reference);
    if (!url) return linkLabel || nonePlaceholder;

    return {
        linkLabel: linkLabel || ' ',
        url,
        oneLineAdditionalLabelInBrackets:
            !noAdditionalData &&
            getOneLineAdditionalLabelInBrackets(t, type, reference),
        twoRows: twoRows,
        noLinkOnlyString: noLinkOnlyString,
        ...(linkIcon
            ? {
                  withSelectIcon: linkIcon.withSelectIcon,
                  icon: linkIcon.icon,
                  className: linkIcon.className,
              }
            : {}),
    };
};

export const createLinkByTypeJustUrl = (
    reference: EntityReferenceApiType,
    type: EntityReferenceApiEnum
): string => {
    return getLinkUrlByType(type, reference);
};

const getRandomActionKey = (key: string): string => {
    return key + '-' + Math.floor(Math.random() * 1000);
};

export interface ShowMoreUrlInterface {
    href: string;
    text?: string;
    always?: boolean;
}

export interface ShowAllInterface {
    text: string;
    onClick?: () => void;
    href?: string;
}

const getExportAction = async (
    action: CardIconType,
    setShowExportTableConfirm: (data: ConfirmModalInterface) => void
) => {
    setShowExportTableConfirm({
        type: 'loading',
    });
    const response = await exportTable({
        ...action.exportTableData,
    });

    if (response) {
        setShowExportTableConfirm({
            response,
            type: (response as ExportErrorApiInterface).errorKey
                ? 'error'
                : 'success',
        });
    }
};

const getGroupedRecords = (
    records: ColumnValueInterface[][]
): Record<string | number, ColumnValueInterface[][]> => {
    if (
        records.some((record) =>
            record.some((rec) => rec.type === FieldTypeApiEnum.GROUP_BY)
        )
    ) {
        const temp = [...records];
        const result = temp.reduce(function (r, record) {
            const newRec = [...record];
            const groupIndex = newRec.findIndex(
                (rec) => rec.type === FieldTypeApiEnum.GROUP_BY
            );
            const value = newRec[parseInt(`${groupIndex}`)].value as
                | string
                | number;

            newRec.splice(groupIndex, 1);
            // eslint-disable-next-line security/detect-object-injection
            r[value] = [...(r[value] || []), newRec];
            return r;
        }, {} as Record<string | number, ColumnValueInterface[][]>);
        return result;
    }

    return null;
};

const createTableRow = (
    t: TFunction,
    index: number,
    record: ColumnValueInterface[],
    columns: ColumnHeadInterface[],
    highlightRow: number,
    isSlim: boolean
) => (
    <Row
        key={'row-' + index}
        t={t}
        index={index}
        row={record}
        columns={columns}
        highlightRow={highlightRow}
        isSlim={isSlim}
    />
);

const createTableGroupedRow = (
    t: TFunction,
    index: number,
    groupRecords: ColumnValueInterface[][],
    columns: ColumnHeadInterface[],
    highlightRow: number,
    isSlim: boolean,
    title: ColumnValueType
) => {
    const grouped = [
        <Tr
            key={'group-' + index}
            index={index}
            highlightRow={highlightRow}
            isSlim={isSlim}
        >
            <Td
                reactKey={'groupTd-' + index}
                col={{
                    value: title,
                    valueWeight: ColumnValueWeight.BOLD,
                    color: undefined,
                }}
                t={t}
                colHead={{
                    label: '',

                    size: ColumnSize.UNSET,
                }}
                colSpan={groupRecords[0].length}
            >
                <span>{title}</span>
            </Td>
        </Tr>,
    ];
    return grouped.concat(
        groupRecords.map((rec, i) =>
            createTableRow(t, i, rec, columns, highlightRow, isSlim)
        )
    );
};

interface TableActionButtonInterface {
    onAction?: () => void;
    onHide?: () => void;
    onActionLabelKey?: string;
    onHideLabelKey?: string;
    onActionDisabled?: boolean;
}

interface Props {
    columns: ColumnHeadInterface[];
    data: ColumnValueInterface[][];
    filterConfig?: FiltersInterface;
    className?: string;
    showMoreUrl?: ShowMoreUrlInterface;
    actions?: CardIconType[];
    noEntriesSubText?: string;
    mainCardTitle?: string | ReactElement;
    cardTitle?: string | ReactElement;
    paginator?: PaginatorInterface;
    noLeftPadding?: boolean;
    showRowsCount?: boolean;
    showAll?: ShowAllInterface;
    searchQueryKey?: string;
    noDataTitle?: string;
    highlightRow?: number;
    onSortEndAction?: (oldIndex: number, newIndex: number) => void;
    isModalView?: boolean;
    slimRows?: boolean;
    noFullWidth?: boolean;
    smallerTitle?: boolean;
    onPageChangeCb?: (page: number) => void;
    actionButtons?: TableActionButtonInterface;
}

const Table = ({
    columns,
    data: records = [],
    className,
    mainCardTitle,
    cardTitle,
    filterConfig,
    actions,
    noEntriesSubText,
    paginator,
    noLeftPadding,
    showAll,
    showRowsCount,
    searchQueryKey,
    showMoreUrl,
    noDataTitle,
    highlightRow,
    onSortEndAction,
    isModalView,
    slimRows,
    noFullWidth,
    smallerTitle,
    onPageChangeCb,
    actionButtons,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showExportTableConfirm, setShowExportTableConfirm] =
        useState<ConfirmModalInterface>();
    const [activeFilters, setActiveFilters] = useState<StateInterface>(
        getInitFilterData(
            filterConfig?.advanced,
            filterConfig?.fixed?.onSearch
                ? {}
                : isBrowser()
                ? getLocationSearchQuery()
                : {},
            true
        )
    );

    const [groupedRecords, setGoupedRecords] = useState<
        Record<string | number, ColumnValueInterface[][]>
    >(getGroupedRecords(records));

    const tableRef = useRef<HTMLDivElement>(null);

    if (noDataTitle === undefined) {
        noDataTitle = t('general.noData') as string;
    }

    useEffect(() => {
        loading && setLoading(false);
        setGoupedRecords(getGroupedRecords(records));
    }, [records]);

    if (columns.length > 0) {
        if (
            records.filter(
                (cols) =>
                    cols.filter((col) => col.type !== FieldTypeApiEnum.GROUP_BY)
                        .length !== columns.length
            ).length > 0
        )
            throw Error(
                'Header columns (columns) and every row with data (data) must have the same number of fields (td).'
            );
    }
    let localCardTitle: string | ReactElement = cardTitle;

    if (showRowsCount && paginator && paginator.total) {
        localCardTitle = (
            <div className={styles.foundRows}>
                {t('Members.foundNumber1')}
                <b>{paginator.total}</b>
                &nbsp;
                {plurals(t, 'Members.foundNumber2', paginator.total)}
            </div>
        );
    }

    const isTableSortable = columns.some((column) => column.isRowSortable);

    return (
        <>
            <div className={styles.wrapper}>
                <div ref={tableRef} className={styles.scrollMarginTop}>
                    {mainCardTitle && (
                        <H2 className={styles.mainTitle}>{mainCardTitle}</H2>
                    )}
                    <Filters
                        filters={filterConfig}
                        filterValues={activeFilters}
                        setFilterValuesInner={setActiveFilters}
                        setLoadingAction={setLoading}
                        searchQueryKey={searchQueryKey}
                        isModalView={isModalView}
                    >
                        <div className={styles.cardWrapper}>
                            <div className={classNames(styles.tableHeader)}>
                                <H2
                                    className={styles.title}
                                    smaller={smallerTitle}
                                >
                                    {localCardTitle}
                                </H2>
                                {actions && (
                                    <div className={styles.actions}>
                                        {actions.map((action, index) => (
                                            <CardIcon
                                                action={{
                                                    ...action,
                                                    onClick:
                                                        action.type !==
                                                        'export_table'
                                                            ? action.onClick
                                                            : async () =>
                                                                  await getExportAction(
                                                                      action,
                                                                      setShowExportTableConfirm
                                                                  ),
                                                }}
                                                key={
                                                    index +
                                                    '-' +
                                                    getRandomActionKey(
                                                        action.type
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>

                            <CardBlock
                                className={classNames(styles.root, className, {
                                    [styles.withTitle]:
                                        !!localCardTitle || !!actions,
                                    [styles.modalView]: isModalView,
                                    [styles.smallerPadding]:
                                        !localCardTitle && !actions,
                                })}
                            >
                                {records.length === 0 ? (
                                    <div className={styles.noData}>
                                        {loading ? (
                                            <div
                                                className={classNames(
                                                    styles.loading,
                                                    styles.loadingSmall
                                                )}
                                            >
                                                <Loading
                                                    loadingText={t(
                                                        'general.loadingData'
                                                    )}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <H3>{noDataTitle}</H3>
                                                <div>{noEntriesSubText}</div>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div className={styles.tableWrapper}>
                                        {isTableSortable ? (
                                            <SortableTable
                                                records={records}
                                                columns={columns}
                                                noLeftPadding={noLeftPadding}
                                                highlightRow={highlightRow}
                                                loading={loading}
                                                onSortEndAction={
                                                    onSortEndAction
                                                }
                                                slimRows={slimRows}
                                                onSearch={
                                                    filterConfig?.fixed
                                                        ?.onSearch
                                                }
                                                activeFilters={activeFilters}
                                                setLoadingAction={setLoading}
                                            />
                                        ) : (
                                            <table
                                                className={classNames(
                                                    styles.table,
                                                    {
                                                        [styles.isLoading]:
                                                            loading,
                                                        [styles.noFullWidth]:
                                                            noFullWidth,
                                                    }
                                                )}
                                            >
                                                <TableHeader
                                                    columns={columns}
                                                    noLeftPadding={
                                                        noLeftPadding
                                                    }
                                                    onSearch={
                                                        filterConfig?.fixed
                                                            ?.onSearch
                                                    }
                                                    activeFilters={
                                                        activeFilters
                                                    }
                                                    setLoadingAction={
                                                        setLoading
                                                    }
                                                />
                                                <tbody
                                                    className={
                                                        noLeftPadding &&
                                                        styles.noLeftPadding
                                                    }
                                                >
                                                    {(!!groupedRecords &&
                                                        Object.entries(
                                                            groupedRecords
                                                        ).map(
                                                            (
                                                                [title, record],
                                                                groupIndex
                                                            ) =>
                                                                createTableGroupedRow(
                                                                    t,
                                                                    groupIndex,
                                                                    record,
                                                                    columns,
                                                                    highlightRow,
                                                                    slimRows,
                                                                    title
                                                                )
                                                        )) ||
                                                        records.map(
                                                            (record, i) =>
                                                                createTableRow(
                                                                    t,
                                                                    i,
                                                                    record,
                                                                    columns,
                                                                    highlightRow,
                                                                    slimRows
                                                                )
                                                        )}
                                                </tbody>
                                                {getInnerTableEnd(t, loading)}
                                            </table>
                                        )}
                                    </div>
                                )}
                            </CardBlock>
                        </div>
                    </Filters>
                    {!showMoreUrl && !!paginator && paginator.last_page > 1 && (
                        <Paginator
                            config={paginator}
                            onSearch={filterConfig?.fixed?.onSearch}
                            onPageChangeCb={onPageChangeCb}
                            activeFilters={activeFilters}
                            setLoadingAction={setLoading}
                            tableRef={tableRef}
                        />
                    )}
                    {!!showAll && (
                        <div className={styles.buttonWrap}>
                            <Button
                                onClick={showAll.onClick}
                                href={showAll.href}
                                isTransparent
                                className={styles.button}
                            >
                                {showAll.text}
                            </Button>
                        </div>
                    )}

                    {!!showMoreUrl &&
                        (showMoreUrl.always ||
                            (paginator &&
                                paginator.total > records.length)) && (
                            <div className={styles.showMore}>
                                <Button
                                    isTransparent
                                    href={showMoreUrl.href}
                                    className={styles.showMore}
                                >
                                    {
                                        t(
                                            showMoreUrl.text ||
                                                'general.showMore'
                                        ) as string
                                    }
                                </Button>
                            </div>
                        )}
                    {actionButtons && (
                        <div className={styles.actionButtonsWrapper}>
                            {!!actionButtons.onHide && (
                                <Button
                                    isTransparent
                                    onClick={actionButtons.onHide}
                                    resolveImmediately
                                >
                                    {
                                        t(
                                            actionButtons.onHideLabelKey ||
                                                'general.cancel'
                                        ) as string
                                    }
                                </Button>
                            )}
                            {!!actionButtons.onAction && (
                                <Button
                                    onClick={actionButtons.onAction}
                                    isDisabled={
                                        actionButtons.onActionDisabled ||
                                        loading
                                    }
                                >
                                    {
                                        t(
                                            actionButtons.onActionLabelKey ||
                                                'general.save'
                                        ) as string
                                    }
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {showExportTableConfirm && (
                <ExportConfirmModal
                    onHide={() => {
                        setShowExportTableConfirm(null);
                    }}
                    type={showExportTableConfirm.type}
                    response={showExportTableConfirm.response}
                />
            )}
        </>
    );
};

export const getInnerTableEnd = (
    t: TFunction,
    loading: boolean
): ReactElement => {
    if (loading) {
        return (
            <tbody>
                <tr>
                    <td>
                        <div className={styles.loading}>
                            <Loading loadingText={t('general.loadingData')} />
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }
};

export default Table;
