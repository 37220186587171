import React, { VFC } from 'react';
import { LinkInterface, Option } from 'components/interfaces/GeneralInterface';
import SubSubMenu from 'components/pages/base/menu/SubSubMenu';
import SubSubMenuFilter from 'components/filters/SubSubMenuFilter';

interface Props {
    links?: LinkInterface[];
    options?: Option[];
    value?: string | number;
    onChange?: (name: string, value: string | number) => void;
    name?: string;
    disabled?: boolean;
}

const SubSubMenuWrapper: VFC<Props> = ({
    links,
    options,
    value,
    onChange,
    name,
    disabled,
}) => {
    return (
        <>
            {links ? (
                <SubSubMenu links={links} />
            ) : (
                <SubSubMenuFilter
                    name={name || ''}
                    value={value}
                    options={options || []}
                    onChange={(name, value) =>
                        onChange ? onChange(name, value) : null
                    }
                    disabled={disabled}
                />
            )}
        </>
    );
};

export default SubSubMenuWrapper;
