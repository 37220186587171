import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { ColumnProps, getClassNameByValueWeight } from './Column';
import InlineEditValue from './inline/InlineEditValue';
import ColumnContentInput from './ColumnContentInput';
import {
    ColumnColor,
    ColumnContextTdInterface,
    ColumnContextWithLabelInterface,
    ColumnContextWithIconInterface,
    ColumnFileInterface,
    ColumnIconInterface,
    ColumnValueLinkInterface,
    ColumnValueType,
    DetailLinkPathTdType,
    InlineEditType,
    LinkDataInterface,
    StateInterface,
    ColumnBooleanInterface,
} from 'components/interfaces/GeneralInterface';
import Icon from 'components/icon/Icon';
import Detail from 'components/icon/icons/detail.svg';
import CheckCircle from 'components/icon/icons/check_circle.svg';
import CrossCircle from 'components/icon/icons/cross_circle.svg';
import Edit from 'components/icon/icons/edit.svg';
import AngleDown from 'components/icon/icons/angle_down.svg';
import ContextMenu, {
    ContextMenuOption,
} from 'components/contextMenu/ContextMenu';
import A from 'components/layouts/A';
import IconByName from 'components/icon/IconByName';
import Tooltip from 'components/layouts/Tooltip';
import stylesIcon from 'components/icon/Icon.module.scss';
import Loading from 'components/loading/Loading';
import Download from 'components/icon/icons/download.svg';
import styles from './Table.module.scss';

const EMPTY_PLACEHOLDER = '-';

const getDetailLink = (href: string, onClick?: () => void) => (
    <Icon
        icon={Detail}
        className={styles.iconDetail}
        noStrip
        href={href}
        onClick={onClick}
    />
);

const ColumnContent = (props: ColumnProps): ReactElement => {
    const {
        t,
        col: { value, color, inlineEdit, tooltip },
        colHead: { detailLinkPath, onChoose, contextOptions, isCentered },
        input,
        reactKey,
    } = props;

    if (detailLinkPath) {
        const link = detailLinkPath(value);

        // When the link is undefined, we don't even want to display the icon with the magnifying glass
        if (link) {
            const isString = typeof link === 'string';

            return getDetailLink(
                isString ? (link as string) : undefined,
                !isString ? (link as () => void) : undefined
            );
        }
    }

    if ((typeof value === 'boolean' && !input) || isBooleanValueObject(value)) {
        const isObject = isBooleanValueObject(value);

        return (
            <div
                className={classNames(styles.iconWrapper, {
                    [styles.iconCenter]: isCentered,
                })}
            >
                <div className={styles.iconWrapper}>
                    <Icon
                        icon={
                            (
                                isObject
                                    ? (value as ColumnBooleanInterface)
                                          .boolValue
                                    : value
                            )
                                ? CheckCircle
                                : CrossCircle
                        }
                        className={classNames(styles.icon, {
                            [styles.iconOrange]: color === ColumnColor.ORANGE,
                            [styles.iconGray]: color === ColumnColor.GRAY,
                        })}
                        noStrip
                    />
                </div>
                <div className={styles.booleanInnerContent}>
                    {isObject && (
                        <ColumnContent
                            {...props}
                            col={(value as ColumnBooleanInterface).value}
                        />
                    )}
                </div>
            </div>
        );
    }

    if (input) {
        return <ColumnContentInput input={input} t={t} value={value} />;
    }

    if (onChoose) {
        let disabled = false;
        let label: string;
        let color: ColumnColor;
        if (onChoose.loading) {
            return (
                <div className={styles.onChooseLoading}>
                    <Loading noMarginBottom />
                </div>
            );
        }

        if (onChoose.disabled) {
            disabled =
                typeof onChoose.disabled === 'function'
                    ? onChoose.disabled(value)
                    : onChoose.disabled;
        }

        if (onChoose.label) {
            label =
                typeof onChoose.label === 'function'
                    ? onChoose.label(value)
                    : onChoose.label;
        }

        if (onChoose.color) {
            color =
                typeof onChoose.color === 'function'
                    ? onChoose.color(value)
                    : onChoose.color;
        }

        return (
            <div
                className={styles.onChooseBtn}
                onClick={!disabled ? () => onChoose.onClick(value) : undefined}
            >
                <div
                    className={classNames({
                        [styles.disabled]: disabled,
                        [styles[`colColor-${color}`]]: !!color,
                    })}
                >
                    {onChoose.label ? label : (t('general.choose') as string)}
                    {!onChoose.noIcon && (
                        <Icon
                            icon={AngleDown}
                            className={disabled && styles.iconDisabled}
                        />
                    )}
                </div>
            </div>
        );
    }

    if (isLabeledContextMenu(value)) {
        if (Array.isArray(value)) {
            return (
                <>
                    {value.map((item, index) =>
                        isLabeledContextMenuItem(item as ColumnValueType)
                            ? getLabeledContextMenu(
                                  item as ColumnContextWithLabelInterface,
                                  color,
                                  index
                              )
                            : getValue(item, undefined, color, index)
                    )}
                </>
            );
        }
        return getLabeledContextMenu(
            value as ColumnContextWithLabelInterface,
            color,
            (value as ColumnContextWithLabelInterface).label
        );
    }

    if (isContextMenuWithIcon(value)) {
        if (Array.isArray(value)) {
            return (
                <>
                    {value.map((item, index) =>
                        isContextMenuWithIconItem(item as ColumnValueType)
                            ? getIconContextMenu(
                                  item as ColumnContextWithIconInterface
                              )
                            : getValue(item, undefined, color, index)
                    )}
                </>
            );
        }

        return getIconContextMenu(value as ColumnContextWithIconInterface);
    }

    if (isContextMenu(value)) {
        return getContextMenu(
            value as ColumnContextTdInterface | ColumnContextTdInterface[]
        );
    }

    if (contextOptions) {
        if (Array.isArray(contextOptions)) {
            return (
                <ContextMenu
                    options={contextOptions.map(
                        ({ label, onClick, getUrl }): ContextMenuOption => ({
                            text: label,
                            onClick: onClick
                                ? () => {
                                      onClick(value);
                                  }
                                : undefined,
                            url: getUrl ? getUrl(value) : undefined,
                        })
                    )}
                    toTop
                />
            );
        } else {
            return contextOptions.getUrl ? (
                <A
                    className={styles.link}
                    href={contextOptions.getUrl(value)}
                    onClick={() => {
                        if (contextOptions.onClick) {
                            contextOptions.onClick(value);
                        }
                    }}
                >
                    {contextOptions.label}
                </A>
            ) : (
                <span className={styles.link} onClick={contextOptions.onClick}>
                    {contextOptions.label}
                </span>
            );
        }
    }

    if (Array.isArray(value)) {
        const valueArray: (
            | number
            | string
            | ColumnValueLinkInterface
            | ColumnIconInterface
            | ColumnContextTdInterface
            | ColumnContextWithLabelInterface
            | ColumnContextWithIconInterface
            | ColumnBooleanInterface
            | ReactElement
            | (LinkDataInterface | string)[]
        )[] = value;

        return (
            <>
                {valueArray.map(
                    (
                        v:
                            | number
                            | string
                            | ColumnValueLinkInterface
                            | ColumnContextTdInterface
                            | ColumnContextWithLabelInterface
                            | ColumnContextWithIconInterface
                            | ColumnBooleanInterface
                            | ColumnIconInterface
                            | ReactElement
                            | (LinkDataInterface | string)[],
                        indexKey: number
                    ) => {
                        return getValue(
                            v,
                            undefined,
                            color,
                            reactKey + '-|-' + indexKey
                        );
                    }
                )}
                {valueArray.length === 0 && EMPTY_PLACEHOLDER}
            </>
        );
    }

    return getValue(value, tooltip, color, 'simpleKey', inlineEdit);
};

const isContextMenuWithIcon = (value: ColumnValueType): boolean =>
    (value !== null && isContextMenuWithIconItem(value)) ||
    (Array.isArray(value) &&
        value.some((item) =>
            isContextMenuWithIconItem(item as ColumnValueType)
        ));

const isContextMenuWithIconItem = (value: ColumnValueType): boolean =>
    value !== null &&
    typeof value === 'object' &&
    'menu' in value &&
    'icon' in value &&
    'showOnKeys' in value;

const isBooleanValueObject = (value: ColumnValueType): boolean =>
    (value !== null && isBooleanValueObjectItem(value)) ||
    (Array.isArray(value) &&
        value.some((item) =>
            isBooleanValueObjectItem(item as ColumnValueType)
        ));

const isBooleanValueObjectItem = (value: ColumnValueType): boolean =>
    value !== null && typeof value === 'object' && 'boolValue' in value;

const isLabeledContextMenu = (value: ColumnValueType): boolean =>
    (value !== null && isLabeledContextMenuItem(value)) ||
    (Array.isArray(value) &&
        value.some((item) =>
            isLabeledContextMenuItem(item as ColumnValueType)
        ));

const isLabeledContextMenuItem = (value: ColumnValueType): boolean =>
    value !== null &&
    typeof value === 'object' &&
    'menu' in value &&
    'label' in value;

const isContextMenu = (value: ColumnValueType): boolean =>
    value !== null &&
    ((typeof value === 'object' && 'contextLabel' in value) ||
        (Array.isArray(value) &&
            value[0] !== null &&
            typeof value[0] === 'object' &&
            'contextLabel' in value[0]));

const hasValueOrZero = (
    value: number | string | ColumnIconInterface | ColumnValueLinkInterface
): boolean => !!value || value === 0 || value === '0';

const getLinkColumnWithUrl = (
    link: LinkDataInterface,
    blankPage = false
): ReactElement => {
    if (link.twoRows) {
        return (
            <>
                {link.oneLineAdditionalLabelInBrackets ? (
                    <div>{link.oneLineAdditionalLabelInBrackets}</div>
                ) : null}
                <div>
                    {link.noLinkOnlyString ? (
                        <span>{link.linkLabel}</span>
                    ) : (
                        <A href={link.url} blankPage={blankPage}>
                            {link.linkLabel}
                        </A>
                    )}
                </div>
            </>
        );
    }
    return link.oneLineAdditionalLabelInBrackets ? (
        <span>
            {(link.noLinkOnlyString && <span>{link.linkLabel}</span>) || (
                <A href={link.url} blankPage={blankPage}>
                    {link.linkLabel}
                </A>
            )}{' '}
            ({link.oneLineAdditionalLabelInBrackets})
        </span>
    ) : (
        (link.noLinkOnlyString && <span>{link.linkLabel}</span>) || (
            <A href={link.url} blankPage={blankPage}>
                {link.linkLabel}
            </A>
        )
    );
};

export const getLinkColumnData = (
    value: LinkDataInterface | string
): ReactElement | string =>
    typeof value === 'string'
        ? value
        : value.url
        ? getLinkColumnWithUrl(value, value.blankPage)
        : value.linkLabel;

const getValue = (
    value:
        | number
        | string
        | boolean
        | ColumnIconInterface
        | ColumnValueLinkInterface
        | ColumnContextTdInterface
        | ColumnContextWithLabelInterface
        | (LinkDataInterface | string)[]
        | DetailLinkPathTdType
        | ReactElement
        | StateInterface
        | ColumnFileInterface,
    tooltip: string,
    color: ColumnColor,
    reactKey: number | string,
    inlineEdit?: InlineEditType
): ReactElement => {
    if (React.isValidElement(value)) {
        return <div key={reactKey}>{value}</div>;
    } else if (typeof value === 'function') {
        const link = value();
        const isString = typeof link === 'string';

        return getDetailLink(
            isString ? (link as string) : undefined,
            !isString ? (link as () => void) : undefined
        );
    }
    if (Array.isArray(value)) {
        return (
            <div key={reactKey}>
                {(value as LinkDataInterface[]).map((linkDataOrString, idx) => (
                    <div key={reactKey + '-' + idx} className={styles.inline}>
                        {typeof linkDataOrString === 'string'
                            ? linkDataOrString
                            : getValue(
                                  linkDataOrString,
                                  undefined,
                                  color,
                                  undefined,
                                  inlineEdit
                              )}
                        {value.length - 1 > idx && <>,&nbsp;</>}
                    </div>
                ))}
            </div>
        );
    } else if (
        value !== null &&
        typeof value === 'object' &&
        'linkLabel' in value
    ) {
        const linkValue = value as ColumnValueLinkInterface;
        return (
            <div
                key={reactKey}
                onClick={linkValue?.onClick}
                className={classNames({
                    [styles.cursorPointer]: !!linkValue?.onClick,
                })}
            >
                <div>
                    {hasValueOrZero(linkValue) ? (
                        <>
                            {getLinkColumnData(value as LinkDataInterface)}
                            {linkValue.icon && (
                                <Icon
                                    icon={linkValue.icon}
                                    noStrip
                                    className={classNames(
                                        styles.stringOnClickIcon,
                                        value.className
                                    )}
                                />
                            )}
                        </>
                    ) : (
                        EMPTY_PLACEHOLDER
                    )}
                    {linkValue.withSelectIcon && (
                        <Icon
                            icon={AngleDown}
                            className={classNames(
                                styles.angleRight,
                                value.className
                            )}
                        />
                    )}
                </div>
            </div>
        );
    } else if (
        typeof value === 'object' &&
        value !== null &&
        'fileName' in value
    ) {
        const file = value as ColumnFileInterface;
        return (
            <div>
                <Tooltip label={file.tooltip}>
                    <A href={file.url} useDownload blankPage>
                        <>
                            <Icon
                                icon={Download}
                                noStrip
                                style={{
                                    width: '1rem',
                                    height: '1rem',
                                    marginRight: '1rem',
                                }}
                            />
                            <span style={{ fontWeight: 400 }}>
                                {file.fileName}
                            </span>
                        </>
                    </A>
                </Tooltip>
            </div>
        );
    } else if (typeof value === 'object' && value !== null) {
        const valueIcon: ColumnIconInterface = value as ColumnIconInterface;
        const element = valueIcon.icon ? (
            <Icon
                icon={valueIcon.icon}
                className={classNames(valueIcon.className, {
                    [stylesIcon['color-' + color]]: !!color,
                })}
                onClick={valueIcon.onClick}
                href={valueIcon.link}
                alt={valueIcon.alt}
                noStrip
            />
        ) : (
            <IconByName
                key={reactKey}
                iconName={valueIcon.name}
                onClick={valueIcon.onClick}
                color={valueIcon.color || color}
                className={classNames(
                    styles.icon,
                    {
                        cursorPointer: valueIcon.onClick ? true : false,
                    },
                    getClassNameByValueWeight(valueIcon.valueWeight, 'add'),
                    valueIcon.className
                )}
                href={valueIcon.link}
                alt={valueIcon.alt}
            />
        );

        if (valueIcon.tooltip) {
            return (
                <Tooltip
                    label={valueIcon.tooltip}
                    interactive={true}
                    containerClass={valueIcon.tooltipClassName}
                >
                    {element}
                </Tooltip>
            );
        }

        return element;
    }

    const valueSimple: string | number = value as string | number;
    let inlineEditingAction = null;
    if (inlineEdit) {
        const [inlineEditing, setInlineEditing] = useState<boolean>(false);
        inlineEditingAction = () => {
            inlineEdit.onLoad && inlineEdit.onLoad();
            setInlineEditing(true);
        };

        if (inlineEditing) {
            return (
                <InlineEditValue
                    {...inlineEdit}
                    cancelAction={() => setInlineEditing(false)}
                />
            );
        }
    }

    const simpleElement = (
        <div
            key={reactKey}
            onClick={inlineEditingAction}
            className={classNames({
                [styles.inlineEdit]: !!inlineEdit,
            })}
        >
            <span>{valueSimple}</span>
            {!!inlineEdit && (
                <Icon icon={Edit} className={styles.inlineEditIcon} />
            )}
        </div>
    );

    if (tooltip) {
        return <Tooltip label={tooltip}>{simpleElement}</Tooltip>;
    }

    return simpleElement;
};

const getContextMenu = (
    contextOptions: ColumnContextTdInterface | ColumnContextTdInterface[]
) => {
    if (Array.isArray(contextOptions)) {
        return (
            <ContextMenu
                options={contextOptions.map(
                    ({ contextLabel, onClick, getUrl }): ContextMenuOption => ({
                        text: contextLabel,
                        onClick: onClick
                            ? () => {
                                  onClick();
                              }
                            : undefined,
                        url: getUrl ? getUrl() : undefined,
                    })
                )}
                toTop
            />
        );
    } else {
        return contextOptions.getUrl ? (
            <A
                className={classNames(styles['link'], {
                    [styles.noIcon]: contextOptions.noIcon,
                })}
                href={contextOptions.getUrl()}
                onClick={() => {
                    if (contextOptions.onClick) {
                        contextOptions.onClick();
                    }
                }}
            >
                {contextOptions.contextLabel}
            </A>
        ) : (
            <span
                className={classNames(styles['link'], {
                    [styles.noIcon]: contextOptions.noIcon,
                })}
                onClick={contextOptions.onClick}
            >
                {contextOptions.contextLabel}
            </span>
        );
    }
};

const getLabeledContextMenu = (
    value: ColumnContextWithLabelInterface,
    color: ColumnColor,
    key: number | string
): JSX.Element => (
    <ContextMenu
        options={value.menu?.map(
            ({ contextLabel, onClick, getUrl }): ContextMenuOption => ({
                text: contextLabel,
                onClick: onClick
                    ? () => {
                          onClick();
                      }
                    : undefined,
                url: getUrl ? getUrl() : undefined,
            })
        )}
        toTop
        key={key}
        className={styles.contextMenu}
    >
        <div
            className={classNames(
                styles[`colColor-${color}`],
                styles.contextLabel
            )}
        >
            {value.label}
        </div>
    </ContextMenu>
);

const getIconContextMenu = (
    value: ColumnContextWithIconInterface
): JSX.Element => {
    if (
        value.showOnKeys?.length > 0 &&
        value.menu?.some((menu) => value.showOnKeys.includes(menu.key)) &&
        value.icon
    ) {
        const icon = (
            <Icon icon={value.icon} className={value.iconClassName} noStrip />
        );

        return (
            <div className={styles.contextWithIcon}>
                {(value.iconTooltip && (
                    <Tooltip label={value.iconTooltip}>{icon}</Tooltip>
                )) ||
                    icon}
                {getContextMenu(value.menu)}
            </div>
        );
    }

    return getContextMenu(value.menu);
};

export default ColumnContent;
