import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { getLocationSearchQuery } from 'submodules/api_middleware/src/client';
import { PaginatorInterface } from 'submodules/api_middleware';
import { StateInterface } from 'components/interfaces/GeneralInterface';
import styles from './Paginator.module.scss';

interface Props {
    config: PaginatorInterface;
    className?: string;
    activeFilters?: StateInterface;
    onSearch?: (s: NextApiRequestQuery) => void;
    setLoadingAction?: Dispatch<SetStateAction<boolean>>;
    tableRef: React.MutableRefObject<HTMLDivElement>;
    onPageChangeCb?: (page: number) => void;
}

const Paginator = ({
    config: { last_page, current_page },
    className,
    setLoadingAction,
    onSearch,
    onPageChangeCb,
    activeFilters,
    tableRef,
}: Props): ReactElement => {
    const Router = useRouter();
    const isActive = (page: number): boolean => page === current_page;

    const handleClickPage = async (page: number) => {
        tableRef && tableRef.current.scrollIntoView({ behavior: 'smooth' });
        setLoadingAction?.(true);
        if (onSearch) {
            onSearch({ ...activeFilters, page: page + '' });
        } else if (onPageChangeCb) {
            onPageChangeCb(page);
        } else {
            const query = getLocationSearchQuery();
            await Router.push(
                {
                    pathname: Router.asPath.split('?')[0],
                    query: { ...query, page },
                },
                undefined,
                { scroll: false }
            );
        }
    };

    const handlePrev = () => {
        if (current_page > 1) {
            handleClickPage(current_page - 1);
        }
    };

    const handleNext = () => {
        if (current_page < last_page) {
            handleClickPage(current_page + 1);
        }
    };
    const renderPages = () => {
        const pagesComponents = [];
        for (let i = 1; i <= last_page; i++) {
            if (
                i === 1 ||
                i === last_page ||
                (i > current_page - 3 && i < current_page + 3) ||
                (current_page === 1 && i === 4) ||
                (current_page === 1 && i === 5) ||
                (current_page === 2 && i === 5) ||
                (current_page === last_page && i === last_page - 3) ||
                (current_page === last_page && i === last_page - 4) ||
                (current_page === last_page && i === last_page - 5) ||
                (current_page === last_page - 1 && i === last_page - 5)
            ) {
                pagesComponents.push(
                    <span
                        className={classNames(styles.page, {
                            [styles.active]: isActive(i),
                        })}
                        title={'' + i}
                        onClick={() => handleClickPage(i)}
                        key={i}
                    >
                        {i}
                    </span>
                );
            } else if (
                (i === 2 && current_page > 4) ||
                (i === last_page - 1 && current_page < last_page - 3)
            ) {
                pagesComponents.push(<span key={i}>...</span>);
            }
        }
        return pagesComponents;
    };

    return (
        <div className={classNames(styles.root, className)}>
            {current_page !== 1 && (
                <span
                    className={classNames(styles.icon, styles.iconPrev)}
                    onClick={handlePrev}
                />
            )}
            {renderPages().map((Page) => Page)}
            {current_page !== last_page && (
                <span
                    className={classNames(styles.icon, styles.iconNext)}
                    onClick={handleNext}
                />
            )}
        </div>
    );
};

export default Paginator;
