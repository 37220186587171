import { AxiosResponse } from 'axios';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { FormResponseData } from './user.service';
import {
    PlayerRegistrationsDeclarationPaymentInterface,
    PlayerRegistrationTerminateApiInterface,
    PlayerUpdateDeclarationPaymentInterface,
    PlayerUpdateDeclarationPaymentStateInterface,
} from 'submodules/api_middleware';
import fetcher, {
    fetcherFull,
    MethodEnum,
} from 'utils/helpers/browser/newFetcher';

export const getPlayerList = async (
    query: NextApiRequestQuery
): Promise<AxiosResponse> => {
    return await fetcherFull('/api/player/list', MethodEnum.GET, {
        ...query,
        id: undefined,
    });
};

export const updatePlayerRegistrationDeclaration = async (
    playerId: number,
    registrationId: number,
    declarationId: number,
    data: PlayerUpdateDeclarationPaymentInterface
): Promise<FormResponseData> => {
    return fetcherFull(
        `/api/player/${playerId}/registrations/${registrationId}/declaration/${declarationId}/update`,
        MethodEnum.PUT,
        undefined,
        {
            data,
        }
    );
};

export const updatePlayerRegistrationDeclarationState = async (
    playerId: number,
    registrationId: number,
    declarationId: number,
    data: PlayerUpdateDeclarationPaymentStateInterface
): Promise<FormResponseData> => {
    return fetcherFull(
        `/api/player/${playerId}/registrations/${registrationId}/declaration/${declarationId}/state`,
        MethodEnum.POST,
        undefined,
        {
            data,
        }
    );
};

export const markPlayerRegistrationDeclarationPaymentAsPaid = async (
    playerId: number,
    registrationId: number,
    declarationId: number,
    paymentId: number
): Promise<AxiosResponse<PlayerRegistrationsDeclarationPaymentInterface>> => {
    return fetcher(
        `/api/player/${playerId}/registrations/${registrationId}/declaration/${declarationId}/payment/${paymentId}/paid`,
        MethodEnum.POST
    );
};

export const playerRegistrationTerminate = async (
    playerId: number,
    data: PlayerRegistrationTerminateApiInterface
): Promise<FormResponseData> => {
    return fetcher(
        `/api/player/${playerId}/registrations/terminate`,
        MethodEnum.DELETE,
        null,
        { data }
    );
};
