import React, { ReactElement } from 'react';
import styles from './MultiSelect.module.scss';

export default function DownIcon(): ReactElement {
    return (
        <>
            <svg viewBox="0 0 20 20" className={styles['msl-arrow-icn']}>
                <line
                    stroke="currentColor"
                    strokeLinecap="round"
                    x1="10"
                    y1="14"
                    x2="4"
                    y2="8"
                />
                <line
                    stroke="currentColor"
                    strokeLinecap="round"
                    x1="16"
                    y1="8"
                    x2="10"
                    y2="14"
                />
            </svg>
        </>
    );
}
