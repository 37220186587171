import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'utils/localization';
import {
    InlineEditType,
    InlineEditTypeEnum,
} from 'components/interfaces/GeneralInterface';
import CancelIcon from 'components/icon/icons/cancel.svg';
import SaveIcon from 'components/icon/icons/save.svg';
import Icon from 'components/icon/Icon';
import DatePicker from 'components/forms/datepicker/DatePicker';
import Tooltip from 'components/layouts/Tooltip';
import ConfirmModal from 'components/modal/ConfirmModal';
import Modal from 'components/modal/Modal';
import EditForm from 'components/editForm/EditForm';
import NumberInput from 'components/forms/NumberInput';
import {
    isNumberField,
    validateNumberMinMax,
} from 'utils/helpers/validateNumber';
import styles from './InlineEditValue.module.scss';

interface Props {
    cancelAction: () => void;
}

const clearValue = (value: string | number) =>
    (!value || value === '-') && value !== 0 ? null : value;

const InlineEditValue = ({
    cancelAction,
    type,
    common,
    confirmModal: confirmModalData,
    editModal,
    isDecimalNumber = false,
}: Props & InlineEditType): ReactElement => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(clearValue(common?.editValue));
    const [confirmModal, showConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>(null);
    const [showModal, setShowModal] = useState<boolean>(true);

    const onSaveAction = async () => {
        if (!error) {
            setLoading(true);
            const save = await common.onSubmit(inputValue);
            if (!save?.error) {
                const result = await common.onSuccess(inputValue, save);
                if (typeof result === 'boolean') {
                    setLoading(!result);
                } else {
                    setLoading(false);
                }
                cancelAction();
            } else {
                setLoading(false);
                cancelAction();
            }
        }
    };

    const saveValue = (val: string | number) => {
        const validation = () => {
            if (common.validation?.action) {
                if (common.validation.action(val)) {
                    setError(null);
                } else {
                    setError(t(common.validation.errorKey(val)));
                }
            } else setError(null);
        };

        if (type === InlineEditTypeEnum.NUMBER) {
            const numberError = isNumberField(
                t,
                val,
                isDecimalNumber ? 'decimal' : 'integer'
            );

            if (
                !numberError &&
                common.validation &&
                (common.validation.min ||
                    common.validation.min === 0 ||
                    common.validation.max ||
                    common.validation.max === 0)
            ) {
                const checkMinMaxError = validateNumberMinMax(
                    t,
                    Number(val),
                    common.validation.min as number,
                    common.validation.max as number
                );
                if (!checkMinMaxError) {
                    validation();
                } else {
                    setError(checkMinMaxError);
                }
            } else {
                setError(numberError);
            }
        } else {
            validation();
        }

        setInputValue(clearValue(val));
    };

    let element;
    switch (type) {
        case InlineEditTypeEnum.NUMBER:
            element = (
                <NumberInput
                    value={inputValue as number}
                    onChange={(value) => saveValue(value)}
                    min={common.validation?.min as number}
                    max={common.validation?.max as number}
                    isDecimalNumber={isDecimalNumber}
                    className={styles.input}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (confirmModalData) showConfirmModal(true);
                            else onSaveAction();
                        } else if (e.key === 'Escape') {
                            cancelAction();
                        }
                    }}
                />
            );
            break;
        case InlineEditTypeEnum.DATE:
            element = (
                <DatePicker
                    value={inputValue as string | Date}
                    onChange={saveValue}
                    usePopperContainer
                    min={common.validation?.min as Date}
                    max={common.validation?.max as Date}
                />
            );
            break;
        case InlineEditTypeEnum.TIME:
            {
                element = (
                    <DatePicker
                        value={inputValue as string | Date}
                        onChange={saveValue}
                        usePopperContainer
                        showOnlyTime
                        min={common.validation?.min as Date}
                        max={common.validation?.max as Date}
                    />
                );
            }
            break;
        case InlineEditTypeEnum.DATETIME:
            {
                element = (
                    <DatePicker
                        value={inputValue as string | Date}
                        onChange={saveValue}
                        usePopperContainer
                        showTime
                        min={common.validation?.min as Date}
                        max={common.validation?.max as Date}
                    />
                );
            }
            break;

        case InlineEditTypeEnum.MODAL: {
            element = (
                <Modal
                    show={showModal}
                    showCross
                    onHide={() => {
                        setShowModal(false);
                        cancelAction();
                    }}
                >
                    <EditForm
                        fields={editModal.fields}
                        withCancelChangesBtn
                        cancel={() => {
                            cancelAction();
                            setShowModal(false);
                        }}
                        onSuccess={(values, files, response) => {
                            cancelAction();
                            return editModal.onSuccess(values, files, response);
                        }}
                        submit={editModal.onSubmit}
                        centerButtons
                        getOnFieldChange={editModal.getOnFieldChange}
                    />
                </Modal>
            );
        }
    }

    return (
        <>
            <div className={styles.root}>
                {element}
                <div className={styles.wrapper}>
                    <div className={styles.actions}>
                        <div className={styles.tooltipWrapper}>
                            <Tooltip label={error} type={'danger'}>
                                <Icon
                                    noStrip
                                    icon={SaveIcon}
                                    className={classNames(styles.success, {
                                        [styles.save_disabled]: !!error,
                                    })}
                                    onClick={() => {
                                        if (confirmModalData)
                                            showConfirmModal(true);
                                        else onSaveAction();
                                    }}
                                />
                            </Tooltip>
                        </div>
                        <Icon
                            noStrip
                            icon={CancelIcon}
                            className={styles.cancel}
                            onClick={cancelAction}
                        />
                    </div>
                </div>
            </div>
            {!!confirmModalData && confirmModal && (
                <ConfirmModal
                    title={confirmModalData.confirmTitle}
                    text={confirmModalData.confirmSubText}
                    textToLeft={confirmModalData.confirmSubTextToLeft}
                    show={true}
                    onHide={() => showConfirmModal(false)}
                    onConfirm={onSaveAction}
                    isLoading={loading}
                />
            )}
        </>
    );
};

export default InlineEditValue;
