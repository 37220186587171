import React, { ReactElement } from 'react';
import classNames from 'classnames';
import CardIcon, { CardIconType } from 'components/card/CardIcon';
import H2 from 'components/typography/H2';
import Icon from 'components/icon/Icon';
import Plus from 'components/icon/icons/plus.svg';
import { useTranslation } from 'utils/localization';
import styles from './CardBlock.module.scss';

interface Props {
    title?: string | ReactElement;
    className?: string;
    /**
     * Children will be wrapped in <div> for content padding. Wrapping
     * is needed for background blur.
     */
    children: React.ReactChild;
    action?: CardIconType;
    actionInUnsetCardText?: string;
    withPadding?: boolean;
    emptyTitle?: boolean;
    noPadding?: boolean;
    lightBcg?: boolean;
    transparentBcg?: boolean;
    blueBcg?: boolean;
    redBcg?: boolean;
    archivedBcg?: boolean;
    darkBcg?: boolean;
    greenBcg?: boolean;
    orangeBcg?: boolean;
    redDarkBcg?: boolean;
    greyBcg?: boolean;
}

const CardBlock = ({
    action,
    title,
    className,
    children,
    actionInUnsetCardText,
    withPadding,
    emptyTitle,
    noPadding,
    lightBcg,
    transparentBcg,
    blueBcg,
    redBcg,
    archivedBcg,
    darkBcg,
    greenBcg,
    orangeBcg,
    redDarkBcg,
    greyBcg,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const unset = !children;

    const unsetAction = !!actionInUnsetCardText && action && unset;
    return (
        <div
            className={classNames(styles.root, className, {
                [styles.withTitle]: !!title || emptyTitle,
                [styles.withAction]: !!action,
                [styles.withUnsetAction]: !!unsetAction,
                [styles.withPadding]: withPadding,
                [styles.lightBcg]: lightBcg,
                [styles.transparentBcg]: transparentBcg,
                [styles.blueBcg]: blueBcg,
                [styles.redBcg]: redBcg,
                [styles.redDarkBcg]: redDarkBcg,
                [styles.archivedBcg]: archivedBcg,
                [styles.darkBcg]: darkBcg,
                [styles.greenBcg]: greenBcg,
                [styles.greyBcg]: greyBcg,
                [styles.orangeBcg]: orangeBcg,
            })}
        >
            <div className={classNames({ [styles.noPadding]: noPadding })}>
                <div
                    className={classNames(styles.blockHeader, {
                        [styles.onlyActions]: !title && action,
                    })}
                >
                    <H2 className={styles.heading}>{title}</H2>
                    {action && !unset && !actionInUnsetCardText && (
                        <CardIcon
                            className={styles.resetPosition}
                            action={action}
                        />
                    )}
                </div>

                {unsetAction ? (
                    <>
                        <div className={styles.actionHeader}>
                            {t('general.empty')}
                        </div>
                        <div
                            className={styles.cardAction}
                            onClick={action.onClick}
                        >
                            <div className={styles.iconPlusWrap}>
                                <Icon icon={Plus} />
                            </div>
                            <span>{actionInUnsetCardText}</span>
                        </div>
                    </>
                ) : (
                    children
                )}
            </div>
        </div>
    );
};

export default CardBlock;
