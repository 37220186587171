import { ChangeEvent, FocusEvent, ReactElement } from 'react';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { MultipleSelectTableInterface } from './MultipleSelectTableInterface';
import {
    EditFormOnFieldChangeVoidType,
    OnSubmitData,
    OnSuccessData,
    OnSuccessType,
} from './EditFormInterface';
import {
    ContactApiInterface,
    FieldTypeApiEnum,
    FileTypeApiEnum,
} from 'submodules/api_middleware';
import { TableEntitiesDataInterface } from 'components/forms/TableEntities';
import { InfoType } from 'components/tables/filters/SpecialInput';
import { FormResponseData } from 'services/user.service';

declare global {
    interface Window {
        pageRefresh: any;
        pageRedirect: any;
        prefillRegistration: () => void;
        prefillTeams: () => void;
        pxToRem: (px: number) => string;
        FreshworksWidget: (val: string) => void;
        fwSettings: any;
    }
}

export enum MenuApiEnum {
    'user-set-password' = 'user-set-password',
    'decisions-search' = 'decisions-search',
    'regulations-search' = 'regulations-search',
    'fifa-compare-players' = 'fifa-compare-players',
    'fifa-compare-clubs' = 'fifa-compare-clubs',
    'coach-search' = 'coach-search',
    'delegation' = 'delegation',
    'proceedings-stk-search' = 'proceedings-stk-search',
    'proceedings-dk-search' = 'proceedings-dk-search',
    'member-search' = 'member-search',
    'player-search' = 'player-search',
    'referee-search' = 'referee-search',
    'delegate-search' = 'delegate-search',
    'club-search' = 'club-search',
    'club-union-search' = 'club-union-search',
    'matches-search' = 'matches-search',
    'competitions-search' = 'competitions-search',
    'applications-search' = 'applications-search',
    'messages-list' = 'messages-list',
    'competition-rewards-search' = 'competition-rewards-search',
    'organisation-units-search' = 'organisation-units-search',
    'user-search' = 'user-search',
    'legal-persons-search' = 'legal-persons-search',
    'memberships-search' = 'memberships-search',
    'bulk-memberships-search' = 'bulk-memberships-search',
    'accounts-transactions-search' = 'accounts-transactions-search',
    'subsidies-search' = 'subsidies-search',
    'nominations-search' = 'nominations-search',
    'player-contracts-search' = 'player-contracts-search',
    'accounts-issued-invoices-search' = 'accounts-issued-invoices-search',
    'accounts-rewards-invoices-search' = 'accounts-rewards-invoices-search',
    'accounts-grants-documents-search' = 'accounts-grants-documents-search',
    'accounts-subsidies-documents-search' = 'accounts-subsidies-documents-search',
    'manage-groups-users-search' = 'manage-groups-users-search',
    'manage-transactions-templates-search' = 'manage-transactions-templates-search',
    'search-audit-log' = 'search-audit-log',
    'agents-search' = 'agents-search',
    'my-proceedings' = 'my-proceedings',
    'my-subsidies-view' = 'my-subsidies-view',
    'subsidies-legal-persons-search' = 'subsidies-legal-persons-search',
    'navigation-is1-registration' = 'navigation-is1-registration',
    'navigation-is1-link' = 'navigation-is1-link',
    'navigation-documents' = 'navigation-documents',
    'navigation-anonymous-payment' = 'navigation-anonymous-payment',
    'register-by-club-admin' = 'register-by-club-admin',
}

export enum EnvelopeSizeEnum {
    long = 'long',
    small = 'small',
    c5 = 'c5',
}

export interface ColumnHeadChooseInterface {
    onClick: (id: ColumnValueType) => void;
    label?: ((id: ColumnValueType) => string) | string;
    noIcon?: boolean;
    loading?: boolean;
    disabled?: ((id: ColumnValueType) => boolean) | boolean;
    color?: ((id: ColumnValueType) => ColumnColor) | ColumnColor;
}

export type DetailLinkPathThType = (
    id: ColumnValueType
) => string | (() => void) | undefined;
export type DetailLinkPathTdType = () => string | (() => void);

export interface ColumnHeadSortInterface {
    labelKey: string;
    key: string;
}

export type ColumnHeadSortType = string | ColumnHeadSortInterface[];

export interface ColumnHeadInterface {
    label: string;
    size: ColumnSize;
    contextOptions?: ColumnContextBtnInterface | ColumnContextBtnInterface[];
    detailLinkPath?: DetailLinkPathThType;
    onChoose?: ColumnHeadChooseInterface;
    isRowSortable?: boolean;
    sortBy?: ColumnHeadSortType;
    isCentered?: boolean;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    tableName?: string;
}

export interface PageTopActionsConfigInterface {
    main?: string;
    other: string[];
}

export interface ColumnIconInterface {
    name: string;
    link?: string;
    alt?: string;
    onClick?: () => void;
    valueWeight?: ColumnValueWeight;
    color?: ColumnColor;
    className?: string;
    tooltip?: string | ReactElement;
    tooltipClassName?: string;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

export enum BasicLanguageEnum {
    'CZE' = 'CZE',
    'ENG' = 'ENG',
}

export enum BasicCountryEnum {
    'CZ' = 'CZ',
}

export interface ColumnValueLinkInterface {
    linkLabel: string;
    oneLineAdditionalLabelInBrackets?: string;
    twoRows?: boolean;
    noLinkOnlyString?: boolean;
    onClick?: () => void;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    withSelectIcon?: boolean;
    className?: string;
}

export interface LinkDataInterface extends ColumnValueLinkInterface {
    url?: string;
    blankPage?: boolean;
}

export interface ColumnContextBtnInterface {
    label: string;
    onClick?: (id: any) => void;
    getUrl?: (id: ColumnValueType) => string;
}

export interface ColumnContextTdInterface {
    contextLabel: string;
    onClick?: () => void;
    getUrl?: () => string;
    noIcon?: boolean;
    key?: string;
}

export interface ColumnContextWithLabelInterface {
    label: string;
    menu: ColumnContextTdInterface[];
}

export interface ColumnContextWithIconInterface {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    iconClassName: string;
    showOnKeys: string[];
    menu: ColumnContextTdInterface[];
    iconTooltip?: string | ReactElement;
}

export interface FieldIconInterface {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    className?: string;
}

export interface ColumnBooleanInterface {
    boolValue: boolean;
    value: ColumnValueInterface;
}

export type ColumnValueType =
    | boolean
    | number
    | string
    | ColumnValueLinkInterface
    | ColumnContextTdInterface
    | ColumnContextWithLabelInterface
    | ColumnContextWithIconInterface
    | ColumnBooleanInterface
    | LinkDataInterface
    | DetailLinkPathTdType
    | ReactElement
    | StateInterface
    | (
          | ColumnContextTdInterface
          | ColumnContextWithLabelInterface
          | ColumnContextWithIconInterface
          | ColumnBooleanInterface
          | ColumnIconInterface
          | ColumnValueLinkInterface
          | string
          | number
          | LinkDataInterface
          | ReactElement
          | (LinkDataInterface | string)[]
      )[];

export enum ColumnColor {
    'GREEN' = 'GREEN',
    'ORANGE' = 'ORANGE',
    'GRAY' = 'GRAY',
    'BLUE' = 'BLUE',
    'ORANGE_SECOND' = 'ORANGE_SECOND',
    'RED' = 'RED',
    'RED_SECOND' = 'RED_SECOND',
    'GREEN_SECOND' = 'GREEN_SECOND',
    'RED_BLUE' = 'RED_BLUE',
}

export enum InlineEditTypeEnum {
    TEXT = 'text',
    NUMBER = 'number',
    DATE = 'date',
    TIME = 'time',
    DATETIME = 'datetime',
    MODAL = 'modal',
}

export type InlineEditValidationType = Partial<
    | {
          action: (value: string | number) => boolean;
          errorKey: (value: string | number) => string;
          min: never;
          max: never;
      }
    | {
          action: (value: string | number) => boolean;
          errorKey: (value: string | number) => string;
          min?: string | number | Date;
          max?: string | number | Date;
      }
    | {
          action: never;
          errorKey: never;
          min?: string | number | Date;
          max?: string | number | Date;
      }
>;

export interface InlineEditConfirmModalInterface {
    confirmTitle?: string;
    confirmSubText?: string;
    confirmSubTextToLeft?: boolean;
}

export interface InlineEditEditFormModalInterface {
    fields: GroupEditFieldInterface[];
    onSubmit: OnSubmitData;
    onSuccess: OnSuccessData;
    getOnFieldChange?: EditFormOnFieldChangeVoidType;
}

export interface InlineEditCommonInterface {
    onSubmit: (value: string | number) => Promise<FormResponseData>;
    onSuccess: (
        value: string | number,
        response: FormResponseData
    ) => OnSuccessType;
    editValue: string | number;
    validation?: InlineEditValidationType;
}

export type InlineEditType = Partial<
    | {
          type: InlineEditTypeEnum.MODAL;
          isDecimalNumber: never;
          onLoad: () => void;
          editModal: InlineEditEditFormModalInterface;
          common: never;
          confirmModal: never;
      }
    | {
          type:
              | InlineEditTypeEnum.DATE
              | InlineEditTypeEnum.DATETIME
              | InlineEditTypeEnum.TEXT
              | InlineEditTypeEnum.TIME;
          isDecimalNumber: never;
          onLoad: () => void;
          common: InlineEditCommonInterface;
          confirmModal?: InlineEditConfirmModalInterface;
          editModal: never;
      }
    | {
          type: InlineEditTypeEnum.NUMBER;
          isDecimalNumber?: boolean;
          onLoad: () => void;
          common: InlineEditCommonInterface;
          confirmModal?: InlineEditConfirmModalInterface;
          editModal: never;
      }
>;

export interface ColumnFileInterface {
    fileName: string;
    url: string;
    tooltip?: string;
}

export interface ColumnValueInterface {
    value: ColumnValueType;
    valueWeight: ColumnValueWeight;
    centerText?: boolean;
    color?: ColumnColor;
    input?: ColumnInputInterface;
    inlineEdit?: InlineEditType;
    tooltip?: string;
    type?: FieldTypeApiEnum;
}

export type ColumnInputOnChangeType =
    | ChangeEvent<HTMLInputElement>
    | ChangeEvent<HTMLSelectElement>
    | ChangeEvent<HTMLTextAreaElement>
    | string
    | number;

export interface ColumnInputValidationInterface {
    min?: number;
    max?: number;
}

export interface ColumnInputInterface {
    type: 'text' | 'number' | 'checkbox' | 'select' | 'date' | 'textarea';
    name: string;
    values?: Option[];
    placeholder?: string;
    isRounded?: boolean;
    isRequired?: boolean;
    onChange?: (e: ColumnInputOnChangeType) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
    isDecimalNumber?: boolean;
    validation?: ColumnInputValidationInterface;
    withValidation?: boolean;
}

export enum ColumnValueWeight {
    'NORMAL' = 'NORMAL',
    'BOLD' = 'BOLD',
    'BOLD_FIRST' = 'BOLD_FIRST',
    'LIGHT_FIRST' = 'LIGHT_FIRST',
    'BOLD_LAST' = 'BOLD_LAST',
    'GREEN' = 'GREEN',
    'RED_FIRST' = 'RED_FIRST',
    'GREEN_FIRST' = 'GREEN_FIRST',
    'GREEN_LAST' = 'GREEN_LAST',
    'DARK_CIRCLE' = 'DARK_CIRCLE',
    'GREEN_CIRCLE' = 'GREEN_CIRCLE',
    'YELLOW_CIRCLE' = 'YELLOW_CIRCLE',
    'RED_CIRCLE' = 'RED_CIRCLE',
    'SECOND_MORE_ADDITIONAL' = 'SECOND_MORE_ADDITIONAL',
    'GRAY' = 'GRAY',
    'GRAY_FIRST' = 'GRAY_FIRST',
    'GRAY_LAST' = 'GRAY_LAST',
}

export type FieldValueType =
    | boolean
    | number
    | number[]
    | string
    | string[]
    | LinkDataInterface
    | FieldIconInterface
    | (string | LinkDataInterface | number | ReactElement)[]
    | FieldInterface[]
    | ReactElement
    | ReactElement[];

export interface FieldInterface {
    value: FieldValueType;
    label: string;
    size: FieldSize;
    valueSize: 'M-' | 'M' | 'L';
    type: 'email' | 'text';
    classNameInner: string;
    classNameBox: string;
    color?: ColumnColor;
    link?: string;
    center?: boolean;
    withBorderBottomLine?: boolean;
    labelTooltip?: string;
    valueTooltip?: string;
}

export enum FieldSize {
    'UNSET' = 'UNSET',
    'P5' = 'P5',
    'P7' = 'P7',
    'P10' = 'P10',
    'P10MW' = 'P10-mw',
    'P14' = 'P14',
    'P15' = 'P15',
    'P20' = 'P20',
    'P25' = 'P25',
    'P30' = 'P30',
    'P35' = 'P35',
    'P33' = 'P33',
    'P40' = 'P40',
    'P50' = 'P50',
    'P60' = 'P60',
    'P70' = 'P70',
    'P100' = 'P100',
}

export enum ColumnSize {
    'S' = 'S',
    'M' = 'M',
    'L' = 'L',
    'SML' = 'SML',
    'XSFIX' = 'XS-fixed',
    'SFIX' = 'S-fixed',
    'SMFIX' = 'SM-fixed',
    'MFIX' = 'M-fixed',
    'SMLFIX' = 'SML-fixed',
    'MLFIX' = 'ML-fixed',
    'LFIX' = 'L-fixed',
    'XLFIX' = 'XL-fixed',
    'UNSET' = 'UNSET',
}

export enum FilterSize {
    'S10' = 'S10',
    'S15' = 'S15',
    'S18' = 'S18',
    'S19' = 'S19',
    'S20' = 'S20',
    'S25' = 'S25',
    'S30' = 'S30',
    'S36' = 'S36',
    'S38' = 'S38',
    'S40' = 'S40',
    'S50' = 'S50',
    'THREE_QUARTERS' = 'THREE_QUARTERS',
    'FOURTH' = 'FOURTH',
    'THIRD' = 'THIRD',
    'HALF' = 'HALF',
    'FULL' = 'FULL',
    'FILL' = 'FILL',
    'BY_VALUE' = 'BY_VALUE',
}

export enum TextAreaHeight {
    'H10' = 'H10',
    'H20' = 'H20',
    'H30' = 'H30',
}

export enum LabelSize {
    'S' = 'S',
    'M' = 'M',
    'ML' = 'ML',
    'L' = 'L',
    'XL' = 'XL',
    'TOP' = 'TOP',
}

export interface LinkInterface<T extends string | string[] = any> {
    idx: T;
    text: string;
    link: string;
    blankPage?: boolean;
}

export interface LinkGeneralInterface<T extends string | string[] = any>
    extends LinkInterface<T> {
    items?: LinkInterface<T>[];
}

export enum MATCH_STATE {
    ACTIVE_PLANNED = 0,
    ACTIVE = 1,
    PLANNED = 2,
    FINISHED = 3,
}

export interface TableSelectBaseInterface {
    columns: FieldTableSelectColumnInterface[];
    values?: number[];
    labelKeyPrefix: string;
    searchPlaceholder?: string;
}

export interface TableSelectInterface extends TableSelectBaseInterface {
    onSearch?: (
        value: NextApiRequestQuery,
        state: StateInterface
    ) => Promise<any>;
    initValuesCb?: (
        value: string | string[],
        state: StateInterface
    ) => Promise<any>;
}

export interface TableSelectFilterInterface extends TableSelectBaseInterface {
    onSearch?: (value: NextApiRequestQuery) => Promise<any>;
    initValuesCb?: (value: string | string[]) => Promise<any>;
}

export interface FieldTableSelectColumnInterface {
    value: string | ((obj: any) => ColumnValueType);
    title?: string;
    columnSize?: ColumnSize;
    columnValueVeight?: ColumnValueWeight;
    isContextMenuIdentifier?: boolean;
}

export interface FieldTableEntitiesInterface {
    title: string;
    modalTitle: string;
    columns: ColumnHeadInterface[];
    modalFields: GroupEditFieldInterface[];
    initData?: TableEntitiesDataInterface[];
}

export type FilterFieldMinFuncType = (state: StateInterface) => number | Date;
export type FilterFieldMaxFuncType = (state: StateInterface) => number | Date;

/**
 * Declarative configuration on a how to display and edit a single editable field.
 */
export interface FilterFieldInterface<
    MultipleSelectTableType extends Record<string, unknown> = any
> {
    /**
     * The type of displayed field. The type determines what properties of the
     * field are used and how.
     */
    type: FieldTypeApiEnum;
    /**
     * Name of the field for identification. The name is used as a key to the
     * key-value dictionary of the form that contains all form values
     * ({@link ValuesStringInterface}).
     */
    name: string;
    /**
     * Translation key that labels the component.
     */
    label?: string;
    placeholder?: string;
    initValue?: string;
    values?: Option[];
    disabledIfSuccess?: (state: StateInterface) => boolean;
    disabledValues?: string[] | ((state: StateInterface) => string[]);
    /**
     * A size of field editor, determined by the CSS class created by combining
     * prefix <em>size-</em and name of filter size, e.g. <em>size-S18</em> from
     * <em>Filter.module.scss</em>.
     *
     * The value is ignored for {@link FieldTypeApiEnum.FILE}, where the field
     * always uses {@link FilterSize.FULL}.
     */
    size?: FilterSize;
    textAreaHeight?: TextAreaHeight;
    /**
     * Size of label text, determined by <em>'labelSize-' + labelSize</em> CSS
     * class from <em>Filter.module.scss</em>.
     */
    labelSize?: LabelSize;
    min?: FilterFieldMinFuncType | number | Date;
    max?: FilterFieldMaxFuncType | number | Date;
    initValuesCb?: (value: string, state: StateInterface) => Promise<Option[]>;
    onSearch?: (
        value: NextApiRequestQuery,
        state: StateInterface
    ) => Promise<Option[]>;
    onSearchProperty?: string;
    noEmptyOption?: boolean;
    usePopperContainer?: boolean;
    firstEmptyOption?: Option;
    withAdditionalModal?: boolean;
    withoutSort?: boolean;
    tableSelect?: TableSelectInterface;
    tableEntities?: FieldTableEntitiesInterface;
    multipleSelectTable?: MultipleSelectTableInterface<MultipleSelectTableType>;
    reInitOnFields?: string[];
}

export interface StateInterface {
    [key: string]: any;
}

export type ValidateValueType = string | string[] | number | boolean | Date;

export type ValidateCbType = (
    value: ValidateValueType,
    state?: StateInterface
) => string;

export interface EditFieldValueFileInterface {
    id?: string;
    fileName: string;
    url: string;
    file?: File;
    additionalData?: StateInterface;
    isDeleted?: boolean;
}

export type ValueOnChangeRemoveType =
    | string
    | string[]
    | number
    | boolean
    | Date
    | File[];

export type EditFieldValueType =
    | string
    | number
    | boolean
    | Date
    | string[]
    | number[]
    | EditFieldValueFileInterface[]
    | ReactElement;

export interface LinkInBrackets {
    label: string;
    url: string;
}

export type EditFieldNoteType =
    | EditFieldNoteInterface
    | EditFieldNoteInterface[];

export interface EditFieldNoteInterface {
    type?: 'warning' | 'danger';
    content: string | ReactElement;
}

export type EditFieldInterfaceOnRemoveType = (
    value: ValueOnChangeRemoveType,
    state: StateInterface
) => void;

/**
 * A single editable field configuration for {@link EditForm}. The configuration
 * determines how is the field editor displayed, validations ect.
 */
export interface EditFieldInterface<
    MultipleSelectTableType extends Record<string, unknown> = any
> extends FilterFieldInterface<MultipleSelectTableType> {
    value?: EditFieldValueType;
    labelValue?: string;
    getLabel?: (state: StateInterface) => string;
    acceptedFileTypes?: FileTypeApiEnum[];
    linkInBrackets?: LinkInBrackets;
    required?: boolean;
    hidden?: boolean;
    requiredIfSuccess?: (state: StateInterface) => boolean;
    displayIfSuccess?: (state: any) => boolean;
    keepSpaceOnHide?: (state: any) => boolean;
    validate?: ValidateCbType;
    attributes?: { [key: string]: string };
    triggerAllValidations?: boolean;
    hideArrows?: boolean;
    onChange?: (
        value: ValueOnChangeRemoveType,
        state: StateInterface
    ) => StateInterface;
    onButtonClick?: (state: StateInterface) => StateInterface | void;
    buttonText?: string;
    disabled?: boolean;
    noEmptyOption?: boolean;
    removable?: boolean;
    onRemove?: EditFieldInterfaceOnRemoveType;
    checkboxLabel?: string;
    onePerRow?: boolean;
    note?: EditFieldNoteType;
    tooltip?: string;
    highlight?: boolean;
    alwaysRerenderOptions?: boolean;
    autoFocus?: boolean;
    autocomplete?: string;
    reInitOnFields?: string[];
    reInitWithValueOnFields?: string[];
    className?: string;
    isDecimalNumber?: boolean;
    infoType?: InfoType;
    minLength?: number;
    maxLength?: number;
}

/**
 * A basic interface for a group of input fields. It represents a logical group
 * of fields (e.g. permanent address and fields street, town, zip code). The
 * data are used as a declarative configuration for the {@link EditForm}
 * component.
 */
export interface GroupEditFieldInterface {
    /**
     * Localization key for this groups' title.
     */
    title?: string | ReactElement;
    displaySectionIfSuccess?: (state: StateInterface) => boolean;
    /**
     * A collection of fields in the group.
     */
    fields: EditFieldInterface[];
    globalValidation?: (state: StateInterface) => string;
}

export interface FilterMainFieldInterface {
    placeholder: string;
    name?: string;
}

export interface FiltersFixedInterface {
    showAdvancedBtn?: boolean;
    mainField?: FilterMainFieldInterface;
    onSearch?: (s: NextApiRequestQuery) => void;
    contextMenuElement?: ReactElement;
    advancedFiltersWrapClass?: string;
    initQuery?: NextApiRequestQuery;
}

export interface FiltersInterface {
    advanced?: FilterFieldInterface[];
    fixed?: FiltersFixedInterface;
    showSearchBtn?: boolean;
    hideClearBtn?: boolean;
    onChangeFilters?: (state: StateInterface) => void;
}

export interface ConfigBlockInterface {
    enabled: boolean;
    editable?: boolean;
    columns?: {
        [key: string]: boolean;
    };
}

export interface ConfigBlockNoEditInterface {
    enabled: boolean;
}

export interface Option {
    value: string | number;
    label: string;
}

export interface DiacriticOption extends Option {
    valueNoDiacritics: string;
    labelNoDiacritics: string;
}

export interface CountryInterface {
    code: string;
    name: string;
}

export interface OrganisationUnitInterface {
    id: string;
    name: string;
}

export interface CompetitionYearInterface {
    id: string;
    year: number;
    season: string;
    is_active: boolean;
}

export interface CompetitionInterface {
    id: string;
    name: string;
    identifier?: string;
}

export interface ValuesInterface {
    [key: string]: string | number;
}

/**
 * A collection of key-value for a form. Although the declared type of value
 * for a field name is a string, it's a lie, e.g. the type for field of type
 * {@link FieldTypeApiEnum.CHECKBOX} is boolean.
 */
export interface ValuesStringInterface {
    [key: string]: string;
}

export interface ValuesStringsInterface {
    [key: string]: string | string[];
}

export interface Contacts {
    phone: ContactApiInterface[];
    email: ContactApiInterface[];
    czechDataBox?: ContactApiInterface[];
}

export interface NameInterface {
    first_name: string | null;
    last_name: string;
}
