import { css } from 'styled-components';

const colors = {
    background: '#eaf0f7',
    backgroundAlpha30: '#eaf0f74d',
    backgroundAlpha90: '#eaf0f7e6',
    disabled: '#6c6e70',
    border: '#d1e0f2',
    borderAlpha30: 'rgba(209, 224, 242, 0.24)',
    borderLine: '#626c8b47',
    main: '#263360',
    mainAlpha55: '#26336055',
    menuBackdrop: '#263360f7',
    secondary: '#3d60d3',
    secondaryHover: '#516fd4',
    warning: '#d93636',
    darkHighlight: '#9d2d2d',
    lightBlue: '#7ca8d5',
    textSecondary: '#626c8b',
    gray: '#636d8b',
    backgroundHighlightAlpha30: '#bad0ea4d',
    success: '#40c435',
    orange: '#f1b137',
    orangeDark: '#b97b07',
    checkboxBorder: '#9da3b5',
    icon: '#b6bbcc',
    lightHover: '#eaf1f8',
    backgroundGreen: '#d1f2de',
    backgroundOrange: '#eee4c6',
    backgroundBlue: '#88a9f0',
    backgroundGray: '#cecece',
    inputDisabled: '#e7e7e7',
    backgroundRed: '#f2d1d1',
    green: '#3be07b',
    greenDark: '#1f7e44',
    archive: '#36383e',

    white: '#fff',
    black: '#000',
    red: '#f1383d',
    yellow: '#fede3a',

    backgroundToastBlue: '#5bc0de',
    backgroundToastRed: '#d9534f',
    backgroundToastGreen: '#5cb85c',
    backgroundToastOrange: '#f0ad4e',
};

const fontWeights = {
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
};

const fontSize = {
    title1: '3rem',
    title2: '1.875rem',
    title3: '1.5rem',
    biggestText: '1.437rem',
    biggerText: '1.25rem',
    text: '1rem',
    smallText: '0.875rem',
    smallestText: '0.8rem',
    button: '1rem',
};

const shadow = {
    card: '0 60px 60px #00145829',
    button: '0 5px 20px #00145834',
    image: '10px 10px 20px #00000029',
    contextMenu: '0 10px 30px #00145833',
    input: '0 15px 30px #0014580d',
    table: '0 30px 30px #0014580d',
    equal: '0 0 30px #0014580d',

    contextMenuIE: '0 10px 30px rgba(0, 20, 88, 0.2)',
    inputIE: '0 15px 30px rgba(0, 20, 88, 0.05)',
};

const breakpoints: { [key: string]: string } = {
    xxs: '500px',
    xs: '730px',
    s: '810px',
    sm: '920px',
    m: '1063px',
    md: '1290px',
    lg: '1620px',
    xl: '1920px',
    xxl: '2400px',
    xxxl: '3180px',
};

const max = Object.keys(breakpoints).reduce((accumulator, label: string) => {
    accumulator[label] = (...args: any[]) => css`
        @media (max-width: ${breakpoints[label]}) {
            ${
                //@ts-ignore
                css(...args)
            };
        }
    `;
    return accumulator;
}, {} as { [key: string]: any });

const ieOnly = (...args: any[]): any => css`
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        ${
            //@ts-ignore
            css(...args)
        }
    }
`;

export { colors, fontWeights, fontSize, breakpoints, shadow, max, ieOnly };
