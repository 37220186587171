import getCroppedImg from './cropImage';

export const showCroppedImage = async (
    img: string,
    croppedAreaPixels: { width: number; height: number; x: number; y: number },
    setPhoto: (croppedImage: string) => void
): Promise<void> => {
    try {
        const croppedImage = await getCroppedImg(img, croppedAreaPixels);
        setPhoto(croppedImage);
    } catch (e) {
        console.error(e);
    }
};
