import React, { ReactElement, useEffect, useRef } from 'react';
import { TFunction } from 'next-i18next';
import Modal from 'components/modal/Modal';
import { useTranslation } from 'utils/localization';
import {
    ExportEmailApiInterface,
    ExportUrlApiInterface,
    ExportApiType,
    ExportErrorApiInterface,
} from 'submodules/api_middleware';
import Loading from 'components/loading/Loading';
import Button from 'components/forms/Button';
import styles from './ExportConfirmModal.module.scss';

interface Props {
    type: 'loading' | 'success' | 'error';
    onHide: () => void;
    response: ExportApiType;
}

const getUrlBlock = (
    t: TFunction,
    forwardRef: React.MutableRefObject<any>,
    url: string
): ReactElement => {
    return (
        <div className={styles.wrapper}>
            <div>{t('CoachPage.export.urlConfirmMessage')}</div>
            <Button href={url} useDownload forwardRef={forwardRef}>
                {t('CoachPage.export.urlLink') as string}
            </Button>
        </div>
    );
};

const getEmailBlock = (t: TFunction, email: string): ReactElement => {
    return (
        <div className={styles.wrapper}>
            {t('CoachPage.export.emailConfirmMessage', { email })}
        </div>
    );
};

const ExportConfirmModal = ({
    onHide,
    response,
    type,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const link = useRef(null);

    useEffect(() => {
        if (link?.current) {
            link.current.click();
        }
    }, [link, type]);

    return (
        <Modal
            show={true}
            onHide={onHide}
            showCross={type !== 'loading'}
            title={'export.title'}
            centerTitle
            isNotClosable={type === 'loading'}
        >
            {type === 'error' ? (
                <div className={styles.wrapper}>
                    {t(
                        `Toast.${
                            (response as ExportErrorApiInterface).errorKey
                        }`
                    )}
                </div>
            ) : type === 'loading' ? (
                <div className={styles.loading}>
                    <Loading loadingText={t('export.loading')} noMarginBottom />
                </div>
            ) : (response as ExportUrlApiInterface).url ? (
                getUrlBlock(t, link, (response as ExportUrlApiInterface).url)
            ) : (
                getEmailBlock(t, (response as ExportEmailApiInterface).email)
            )}
        </Modal>
    );
};

export default ExportConfirmModal;
