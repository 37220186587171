import classNames from 'classnames';
import React, { ReactChild, ReactElement } from 'react';
import {
    EditFieldValueFileInterface,
    FilterSize,
    LabelSize,
    LinkInBrackets,
} from 'components/interfaces/GeneralInterface';
import Cancel from 'components/icon/icons/cancel.svg';
import Info from 'components/icon/icons/info.svg';
import Icon from 'components/icon/Icon';
import { FieldTypeApiEnum } from 'submodules/api_middleware';
import Tooltip from 'components/layouts/Tooltip';
import styles from './Filter.module.scss';

interface Props {
    size?: FilterSize;
    labelSize?: LabelSize;
    label?: string;
    tooltip?: string;
    children: ReactChild;
    type: FieldTypeApiEnum;
    name?: string;
    checkbox?: boolean;
    onRemove?: () => void;
    isModalView?: boolean;
    hideContent?: boolean;
    highlight?: boolean;
    isEditForm?: boolean;
    showRequiredIndication?: boolean;
    isRequiredField?: boolean;
    disabled?: boolean;
    onCheckBoxAction?: (
        name: string,
        value: boolean | string | string[] | number | Date
    ) => void;
    filterValue?:
        | boolean
        | string
        | number
        | Date
        | string[]
        | EditFieldValueFileInterface[];
    linkInBrackets?: LinkInBrackets;
}

const Filter = ({
    size = FilterSize.S15,
    label,
    labelSize = LabelSize.M,
    tooltip,
    children,
    name,
    type,
    checkbox,
    onRemove,
    isModalView,
    hideContent,
    highlight,
    isEditForm,
    disabled,
    onCheckBoxAction,
    filterValue,
    linkInBrackets,
    showRequiredIndication,
    isRequiredField,
}: Props): ReactElement => (
    <div
        className={classNames(styles.filter, styles['size-' + size], {
            [styles.checkboxWrapper]: type === FieldTypeApiEnum.CHECKBOX,
            [styles.columnOrder]: labelSize === LabelSize.TOP,
        })}
    >
        {!hideContent && (
            <>
                {(tooltip || label || onRemove) && (
                    <div
                        className={classNames(
                            styles.labelWrapper,
                            styles.filterLabel,
                            styles['labelSize-' + labelSize],
                            {
                                [styles.highlight]: highlight,
                                [styles.isEditForm]: isEditForm,
                            }
                        )}
                        accessKey="labelWrapper"
                    >
                        {tooltip && (
                            <div className={styles.labelTooltip}>
                                <Tooltip
                                    label={tooltip}
                                    direction="right"
                                    align="left"
                                >
                                    <Icon
                                        icon={Info}
                                        noStrip
                                        className={styles.tooltipIcon}
                                    />
                                </Tooltip>
                            </div>
                        )}
                        {label && (
                            <label
                                htmlFor={name}
                                onClick={
                                    !disabled &&
                                    onCheckBoxAction &&
                                    labelSize !== LabelSize.TOP
                                        ? () =>
                                              onCheckBoxAction(
                                                  name,
                                                  !filterValue
                                              )
                                        : undefined
                                }
                                className={classNames({
                                    [styles.pointer]:
                                        !disabled &&
                                        !!onCheckBoxAction &&
                                        labelSize !== LabelSize.TOP,
                                })}
                            >
                                {label}
                                {showRequiredIndication &&
                                    isRequiredField &&
                                    ` *`}
                                {!!linkInBrackets && (
                                    <>
                                        {' '}
                                        (
                                        <a
                                            href={linkInBrackets.url}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {linkInBrackets.label}
                                        </a>
                                        )
                                    </>
                                )}
                            </label>
                        )}
                        {onRemove && (
                            <Icon
                                icon={Cancel}
                                noStrip
                                onClick={onRemove}
                                className={styles.removeIcon}
                            />
                        )}
                    </div>
                )}
                <div
                    className={classNames(styles.contentWrapper, styles.input, {
                        [styles.checkbox]: checkbox,
                        [styles.widthFull]: labelSize === LabelSize.TOP,
                        [styles.modalView]: !!isModalView,
                    })}
                >
                    {children}
                </div>
            </>
        )}
    </div>
);

export default Filter;
