import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import MultiSelect from './MultipleSelect/MultiSelect';
import { Option } from 'components/interfaces/GeneralInterface';
import { useTranslation } from 'utils/localization';

interface Props {
    options: Option[];
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    value: string | string[];
    bigger?: boolean;
    allText?: string;
    name: string;
    disabled?: boolean;
    multiple?: boolean;
    placeholder?: string;
    withoutSort?: boolean;
}

const prepareValues = (values: string | (string | number)[]) =>
    Array.isArray(values) ? values.join(',') : values;

const MultipleSelect = ({
    options,
    value,
    onChange,
    name,
    placeholder,
    allText,
    disabled,
    bigger,
    withoutSort,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const [values, setValues] = useState<string>(prepareValues(value));
    const [menuOpened, setMenuOpened] = useState<boolean>(false);

    useEffect(() => {
        if (Array.isArray(value)) setValues(prepareValues(value));
    }, [value]);

    const prepareValueToSend = (values: string | Option[]) =>
        values
            ? typeof values === 'string'
                ? value
                : values.length > 0
                ? values.map((v) => v.value)
                : undefined
            : undefined;

    const handleChange = (values: string | Option[]) => {
        const innerValue = prepareValueToSend(values);

        if (!menuOpened) {
            callOnChange(innerValue);
        }

        setValues(prepareValues(innerValue));
    };

    const handleChangeOnEnter = (values: string | Option[]) => {
        const innerValue = prepareValueToSend(values);
        callOnChange(innerValue);
    };

    const menuClosed = () => {
        if (
            (!prepareValues(value) && !values) ||
            prepareValues(value) === values
        )
            setMenuOpened(false);
        else {
            callOnChange(values ? values.split(',') : undefined);
            setMenuOpened(false);
        }
    };

    const menuOpen = () => {
        setMenuOpened(true);
    };

    const callOnChange = (values: string | (string | number)[]) => {
        onChange({
            // @ts-ignore-next-line
            target: { name, value: values },
        });
    };

    const innerPlaceHolder = !values
        ? allText
            ? allText
            : placeholder
        : t('filters.choose');

    return (
        <MultiSelect
            name={name}
            options={options}
            placeholder={innerPlaceHolder}
            selectedValue={values}
            disabled={disabled}
            onChange={handleChange}
            onEnter={handleChangeOnEnter}
            onMenuClose={menuClosed}
            onMenuOpen={menuOpen}
            emptyDataLabel={t('general.noData')}
            chipAlternateText={t('multipleSelect.chipAlternateText')}
            hideSelected
            noTab
            jsonValue
            bigger={bigger}
            withoutSort={withoutSort}
        />
    );
};

export default MultipleSelect;
