import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { logErrorMessage } from 'utils/ErrorLogger';
import { PunishmentTypeEnum, RoleEnum } from 'types/types';
import Icon from 'components/icon/Icon';
import Referee from 'components/icon/icons/referee.svg';
import Delegate from 'components/icon/icons/delegate.svg';
import Coach from 'components/icon/icons/coach.svg';
import Player from 'components/icon/icons/player.svg';
import Plus from 'components/icon/icons/plus.svg';
import Red from 'components/icon/icons/red.svg';
import Yellow from 'components/icon/icons/yellow.svg';
import {
    PersonRoleNoDisciplineEnum,
    RoleApiEnum,
} from 'submodules/api_middleware';
import styles from './Icon.module.scss';

interface Props {
    iconName: string;
    className?: string;
    color?: string;
    href?: string;
    alt?: string;
    onClick?: () => void;
}

const IconByName = ({
    iconName,
    className,
    color,
    href,
    alt,
    onClick,
}: Props): ReactElement => {
    let icon: React.FC<React.SVGProps<SVGSVGElement>>;
    switch (iconName) {
        case RoleEnum.referee:
        case RoleApiEnum['referee-football']:
        case RoleApiEnum['referee-futsal']:
            icon = Referee;
            break;
        case RoleEnum.coach:
        case RoleApiEnum['coach-football']:
        case RoleApiEnum['coach-futsal']:
            icon = Coach;
            break;
        case RoleEnum.player:
        case RoleApiEnum['player-football']:
        case RoleApiEnum['player-futsal']:
            icon = Player;
            break;
        case RoleEnum.delegate:
        case RoleApiEnum['delegate-football']:
        case RoleApiEnum['delegate-futsal']:
            icon = Delegate;
            break;
        case PersonRoleNoDisciplineEnum['agent']:
            icon = Delegate;
            break;
        case RoleApiEnum['paramedic-football']:
        case RoleApiEnum['paramedic-futsal']:
            icon = Delegate;
            break;
        case RoleApiEnum['physician-football']:
        case RoleApiEnum['physician-futsal']:
            icon = Delegate;
            break;
        case RoleApiEnum['masseur-football']:
        case RoleApiEnum['masseur-futsal']:
            icon = Delegate;
            break;
        case RoleApiEnum['technical_delegate-football']:
        case RoleApiEnum['technical_delegate-futsal']:
            icon = Delegate;
            break;
        case 'plus':
            icon = Plus;
            break;
        case PunishmentTypeEnum.RED:
            icon = Red;
            break;
        case PunishmentTypeEnum.YELLOW:
            icon = Yellow;
            break;
        default:
            logErrorMessage(
                'Table.getIconByName - iconName not matched: ' + iconName
            );
    }

    return (
        <Icon
            icon={icon}
            className={classNames(className, {
                [styles['color-' + color]]: !!color,
            })}
            onClick={onClick}
            href={href}
            noStrip
            alt={alt}
        />
    );
};

export default IconByName;
