import React, { ReactElement } from 'react';
import { ColumnProps, Td } from '../Column';
import SortableColumnContent from './SortableColumnContent';

const SortableColumn = (props: ColumnProps): ReactElement => (
    <Td {...props}>
        <SortableColumnContent {...props} />
    </Td>
);

export default SortableColumn;
