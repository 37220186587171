import React from 'react';
import classNames from 'classnames';
import { TFunction } from 'next-i18next';
import styles from '../EditForm.module.scss';
import {
    EditFieldInterface,
    EditFieldNoteType,
    EditFieldValueFileInterface,
    FilterFieldMaxFuncType,
    FilterFieldMinFuncType,
    GroupEditFieldInterface,
    StateInterface,
    ValueOnChangeRemoveType,
} from 'components/interfaces/GeneralInterface';
import { FieldTypeApiEnum } from 'submodules/api_middleware';
import {
    isNumberField,
    validateNumberMinMax,
} from 'utils/helpers/validateNumber';
import {
    FileStateInterface,
    ValidateCbObjectInterface,
} from 'components/interfaces/EditFormInterface';

export const getFilesChanged = (
    allFiles: FileStateInterface,
    initialValuesFiles: FileStateInterface
): FileStateInterface => {
    const filesChanged: FileStateInterface = {};
    for (const fieldName of Object.keys(allFiles)) {
        const file: EditFieldValueFileInterface[] = allFiles[`${fieldName}`];
        const initFile: EditFieldValueFileInterface[] =
            initialValuesFiles[`${fieldName}`];

        if (
            !initFile ||
            (initFile && file.some((f) => !!f.file || f.isDeleted))
        ) {
            filesChanged[`${fieldName}`] = file;
        }
    }

    return filesChanged;
};

export const getFieldValuesChanged = (
    fieldValues: StateInterface,
    initialValues: StateInterface
) => {
    const fieldValuesChanged: StateInterface = {};
    const anyFieldValuesChanged =
        Object.keys(fieldValues).filter((key) => {
            const isChanged = fieldValues[`${key}`] !== initialValues[`${key}`];
            if (isChanged) {
                fieldValuesChanged[`${key}`] = fieldValues[`${key}`];
            }
            return isChanged;
        }).length > 0;
    return {
        fieldValuesChanged,
        anyFieldValuesChanged,
    };
};

export const filterFields =
    (removed: string[], filterValues: StateInterface) =>
    ({ displayIfSuccess, keepSpaceOnHide, name }: EditFieldInterface) =>
        !removed.includes(name) &&
        (!displayIfSuccess ||
            displayIfSuccess(filterValues) ||
            (keepSpaceOnHide && keepSpaceOnHide(filterValues)));

export const getFieldNote = (notes: EditFieldNoteType, name: string) => {
    if (!notes) {
        return null;
    }

    if (Array.isArray(notes)) {
        return notes.map((note, key) => (
            <div
                key={`note-${key}-${name}`}
                className={classNames(styles.note, {
                    [styles.dangerNote]: note.type === 'danger',
                })}
            >
                {note.content}
            </div>
        ));
    }

    return (
        <div
            className={classNames(styles.note, {
                [styles.dangerNote]: notes.type === 'danger',
            })}
        >
            {notes.content}
        </div>
    );
};

export const setFieldValidations = (
    t: TFunction,
    field: EditFieldInterface,
    validateCbFields: ValidateCbObjectInterface,
    isDisabled: boolean
): void => {
    if (isDisabled || field.disabled) {
        return null;
    }

    if (field.type === FieldTypeApiEnum.NUMBER) {
        validateCbFields[field.name] = (value, state) => {
            return (
                isNumberField(
                    t,
                    value as string | number,
                    field.isDecimalNumber ? 'decimal' : 'integer'
                ) ||
                validateNumberMinMax(
                    t,
                    Number(value),
                    typeof (field.min as FilterFieldMinFuncType) === 'function'
                        ? (field.min as (state: StateInterface) => number)(
                              state
                          )
                        : (field.min as number),
                    typeof (field.max as FilterFieldMaxFuncType) === 'function'
                        ? (field.max as (state: StateInterface) => number)(
                              state
                          )
                        : (field.max as number)
                ) ||
                (field.validate && field.validate(value, state)) ||
                undefined
            );
        };
    } else {
        if (field.validate) {
            validateCbFields[field.name] = field.validate;
        }
    }
};

export const removeHiddenAndDisabledFields = (
    values: StateInterface,
    fields: GroupEditFieldInterface[],
    inActiveFields: string[]
): StateInterface => {
    const newFields = { ...values };
    fields.forEach((groupField) => {
        groupField.fields.forEach(({ name, displayIfSuccess }) => {
            if (
                (displayIfSuccess && !displayIfSuccess(values)) ||
                inActiveFields?.includes(name)
            ) {
                delete newFields[`${name}`];
            }
        });
    });

    return newFields;
};

export const removeField = (
    name: string,
    onRemove: (value: ValueOnChangeRemoveType, state: StateInterface) => void,
    filterValues: StateInterface,
    setFilterValues: (obj: StateInterface) => void,
    removed: string[],
    setRemoved: (removed: string[]) => void
) => {
    const values = { ...filterValues };
    delete values[`${name}`];
    setFilterValues(values);

    if (onRemove) {
        return onRemove(filterValues[`${name}`], filterValues);
    }
    setRemoved([...removed, name]);
};

export const revalidateAllFormFields = (
    localFieldValues: StateInterface,
    validateCbFields: ValidateCbObjectInterface,
    setFilterErrorsInner: (value: React.SetStateAction<StateInterface>) => void
) => {
    const revalidatedErrors: StateInterface = {};
    Object.keys(validateCbFields).forEach((key) => {
        const value = localFieldValues[`${key}`];
        const validateCb = validateCbFields[`${key}`];
        if (!!value || value === 0)
            revalidatedErrors[`${key}`] = validateCb(value, localFieldValues);
    });
    setFilterErrorsInner(revalidatedErrors);
};

export const isFormValueType = (type: string): boolean =>
    type !== FieldTypeApiEnum.EMPTY &&
    type !== FieldTypeApiEnum.BREAK &&
    type !== FieldTypeApiEnum.BUTTON &&
    type !== FieldTypeApiEnum.ADDBUTTON &&
    type !== FieldTypeApiEnum.REACT_DOM;
