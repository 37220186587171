export interface SortedByNameInterface {
    sortedType: string;
    index: number;
}

export const sortedByName = (
    sortBy: string,
    sortedBy: string[]
): SortedByNameInterface => {
    const sortedIndex = sortedBy.findIndex(
        (by) => by.substring(0, by.indexOf(':')) === sortBy
    );
    if (sortedIndex !== -1) {
        const sorted = sortedBy[parseInt(`${sortedIndex}`)].split(':');

        return {
            sortedType: sorted[1],
            index: sortedIndex + 1,
        };
    }
};
