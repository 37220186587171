import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { TFunction } from 'next-i18next';
import Column from './Column';
import {
    ColumnHeadInterface,
    ColumnValueInterface,
} from 'components/interfaces/GeneralInterface';
import styles from './Table.module.scss';

export interface RowProps {
    t: TFunction;
    index: number;
    columns: ColumnHeadInterface[];
    row: ColumnValueInterface[];
    highlightRow: number;
    isSlim: boolean;
}

interface TrProps {
    index: number;
    highlightRow?: number;
    isSlim: boolean;
    children: ReactElement;
}

export const Tr = ({
    index,
    highlightRow,
    isSlim,
    children,
}: TrProps): ReactElement => (
    <tr
        key={index}
        className={classNames(styles.bodyRow, {
            [styles.highlight]: highlightRow && highlightRow === index + 1,
            [styles.row_slim]: isSlim,
        })}
    >
        {children}
    </tr>
);

const Row = ({
    t,
    index,
    row,
    columns,
    highlightRow,
    isSlim,
}: RowProps): ReactElement => {
    return (
        <Tr index={index} highlightRow={highlightRow} isSlim={isSlim}>
            <>
                {row.map((col, rowIndex) => (
                    <Column
                        key={rowIndex}
                        t={t}
                        col={col}
                        colHead={columns[parseInt(`${rowIndex}`)]}
                        reactKey={index + '|' + rowIndex}
                        input={col.input}
                    />
                ))}
            </>
        </Tr>
    );
};

export default Row;
