import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import classNames from 'classnames';
import SortableHeader from './sortable/SortableHeader';
import Icon from 'components/icon/Icon';
import {
    ColumnHeadInterface,
    StateInterface,
} from 'components/interfaces/GeneralInterface';
import styles from './Table.module.scss';

interface Props {
    columns: ColumnHeadInterface[];
    noLeftPadding: boolean;
    activeFilters: StateInterface;
    onSearch: (s: NextApiRequestQuery) => void;
    setLoadingAction: Dispatch<SetStateAction<boolean>>;
}

const TableHeader = ({
    columns,
    noLeftPadding,
    setLoadingAction,
    activeFilters,
    onSearch,
}: Props): ReactElement => {
    const [sortedBy, setSortedBy] = useState<string[]>([]);

    return (
        <thead>
            <tr className={styles.headRow}>
                {columns.map(({ label, isCentered, icon, sortBy }, i) => (
                    <th
                        className={classNames(styles.headCol, {
                            [styles.centerText]: isCentered,
                            [styles.noLeftPadding]: noLeftPadding,
                        })}
                        key={i}
                    >
                        {(sortBy && (
                            <SortableHeader
                                label={label}
                                isCentered={isCentered}
                                sortedBy={sortedBy}
                                setSortedBy={setSortedBy}
                                sortBy={sortBy}
                                setLoadingAction={setLoadingAction}
                                activeFilters={activeFilters}
                                onSearch={onSearch}
                                icon={
                                    icon && (
                                        <Icon
                                            icon={icon}
                                            noStrip
                                            className={styles.iconHead}
                                        />
                                    )
                                }
                            />
                        )) || (
                            <>
                                {label}
                                {icon && (
                                    <Icon
                                        icon={icon}
                                        noStrip
                                        className={styles.iconHead}
                                    />
                                )}
                            </>
                        )}
                    </th>
                ))}
            </tr>
        </thead>
    );
};

export default TableHeader;
