import React, { useState, useEffect, useRef, ReactElement } from 'react';
import classNames from 'classnames';
import Chip from './Chip';
import CloseIcon from './CloseIcon';
import DownIcon from './DownIcon';
import Options from './Options';
import useComponentVisible from './useComponentVisible';
import { Option } from 'components/interfaces/GeneralInterface';
import styles from './MultiSelect.module.scss';

export interface MultipleOptionInterface extends Option {
    title?: string;
    type?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    childs?: MultipleOptionInterface[];
    emptyDataLabel?: string;
    classes?: string;
}

const sortOptions = (
    values: MultipleOptionInterface[],
    withoutSort: boolean
): MultipleOptionInterface[] =>
    withoutSort
        ? values
        : values.sort((a, b) =>
              a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          );

interface Props {
    name: string;
    options: MultipleOptionInterface[];
    selectedValue: string | string[] | MultipleOptionInterface[];
    onChange: (value: string | Option[]) => void;
    onEnter?: (value: string | Option[]) => void;
    clearable?: boolean;
    downArrow?: boolean;
    singleSelect?: boolean;
    jsonValue?: boolean;
    disableChip?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
    limit?: number;
    emptyDataLabel?: string;
    placeholder?: string;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    customValue?: boolean;
    chipAlternateText?: string;
    closeOnSelect?: boolean;
    className?: string;
    attributes?: { [key: string]: string };
    largeData?: boolean;
    hideSelected?: boolean;
    downArrowIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
    closeIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
    noTab?: boolean;
    bigger?: boolean;
    withoutSort?: boolean;
}

const MultiSelect = ({
    options: userOptions,
    downArrowIcon,
    closeIcon,
    clearable = true,
    downArrow = true,
    onChange,
    onEnter,
    singleSelect = false,
    jsonValue = false,
    selectedValue,
    className,
    placeholder,
    disableChip = false,
    name,
    style,
    attributes,
    largeData,
    disabled = false,
    limit = null,
    emptyDataLabel,
    customValue = false,
    onMenuOpen,
    onMenuClose,
    chipAlternateText,
    closeOnSelect = true,
    hideSelected = false,
    noTab = false,
    bigger,
    withoutSort,
}: Props): ReactElement => {
    const [inputValue, setInputValue] = useState<string>('');
    const [isOptionAction, setOptionAction] = useState<boolean>(false);
    const [value, setValue] = useState<MultipleOptionInterface[]>([]);
    const [options, setOptions] = useState(
        sortOptions(userOptions, withoutSort) || []
    );
    const [visibleOptions, setVisibleOptions] = useState(
        sortOptions(userOptions, withoutSort) || []
    );
    const [search, setSearch] = useState<MultipleOptionInterface[]>(null);
    const inputFld = useRef(null);
    const optionsRef = useRef(null);

    const clearInputValue = () => {
        setSearch(null);
        // clear search input
        if (inputFld.current) {
            inputFld.current.innerHTML = '';
        }
    };

    const {
        ref: componentRef,
        isComponentVisible: menuOpen,
        setIsComponentVisible: setMenuOpen,
    } = useComponentVisible({
        initialIsVisible: false,
        onClickOutside: onMenuClose,
        clearInputValue,
        optionsRef,
        noTab,
    });

    const calculatedWidth = `calc(100% - ${
        clearable && downArrow ? 60 : downArrow || clearable ? 40 : 5
    }px)`;

    const getValueObjFromOptios = (
        selectedValue: string | string[] | MultipleOptionInterface[],
        options: MultipleOptionInterface[]
    ) => {
        if (!selectedValue) return [];

        let defaultValArr = selectedValue;
        const extraValues: Option[] = [];
        const searchedOptions = [];

        if (typeof selectedValue === 'string') {
            defaultValArr = selectedValue.split(',');
        }

        const setExtraValue = (value: string | MultipleOptionInterface) => {
            if (typeof value === 'string') {
                extraValues.push({ label: value, value });
            } else if (typeof value === 'object') {
                value = value as Option;
                const newValueObj: Option = { ...value };
                if ('label' in value && typeof value.label === 'string') {
                    newValueObj.label = value.label;
                } else if ('title' in value) {
                    newValueObj.label = value.title;
                }
                extraValues.push(newValueObj);
            }
        };

        for (let i = 0; i < defaultValArr.length; i++) {
            if (typeof defaultValArr[parseInt(`${i}`)] === 'string') {
                const optObject = searchOptions(
                    defaultValArr[parseInt(`${i}`)] as string,
                    options,
                    true,
                    'value'
                );
                if (optObject.length > 0) {
                    searchedOptions.push(optObject[0]);
                } else if (customValue) {
                    setExtraValue(defaultValArr[parseInt(`${i}`)]);
                }
            } else if (typeof defaultValArr[parseInt(`${i}`)] === 'object') {
                const optObject = searchOptions(
                    (defaultValArr[parseInt(`${i}`)] as MultipleOptionInterface)
                        .value,
                    options,
                    true,
                    'value'
                );
                if (optObject.length > 0) {
                    searchedOptions.push(optObject[0]);
                } else if (customValue) {
                    setExtraValue(
                        defaultValArr[
                            parseInt(`${i}`)
                        ] as MultipleOptionInterface
                    );
                }
            }
        }

        let customValuesGroup: MultipleOptionInterface[] = [];
        if (extraValues.length) {
            customValuesGroup = createCustomValueAndOption(extraValues);
        }
        setOptions([...options, ...customValuesGroup]);
        if (hideSelected) {
            sortOptions([...options, ...customValuesGroup], withoutSort);
        }
        return [...searchedOptions, ...extraValues];
    };

    useEffect(() => {
        setOptions(sortOptions(userOptions, withoutSort));
        setVisibleOptionsByValues(value);
    }, [userOptions]);

    useEffect(() => {
        let preDefinedValue = getValueObjFromOptios(selectedValue, options);

        if (singleSelect && preDefinedValue.length > 0) {
            preDefinedValue = [preDefinedValue[0]];
        }

        setValue(preDefinedValue);
        setVisibleOptionsByValues(preDefinedValue);
        // close on option select
        closeOnSelect && singleSelect && setMenuOpen(false);
    }, [selectedValue]);

    const setVisibleOptionsByValues = (val: MultipleOptionInterface[]) => {
        if (hideSelected) {
            if (val.length)
                setVisibleOptions(
                    sortOptions(
                        [
                            ...options.filter(
                                (o) => !val.some((v) => v.value === o.value)
                            ),
                        ],
                        withoutSort
                    )
                );
            else setVisibleOptions(sortOptions(options, withoutSort));
        }
    };

    const setNewValue = (val: MultipleOptionInterface[]) => {
        setValue(val);
        setVisibleOptionsByValues(val);
        onChange(prepareValueToSend(val));
    };

    const prepareValueToSend = (
        val: MultipleOptionInterface[]
    ): string | Option[] => {
        if (jsonValue) {
            return val;
        } else {
            let stringvalue = '';
            stringvalue += val.map((itm) => itm.value);
            return stringvalue;
        }
    };

    const checkValueExist = (
        value: MultipleOptionInterface,
        arr: MultipleOptionInterface[]
    ): boolean => {
        const bool = arr.some((itm) => itm.value === value.value);
        return bool;
    };

    const filterCreateOpt = ({
        label,
        value,
    }: MultipleOptionInterface): MultipleOptionInterface => {
        if (typeof label !== 'object' && label.match(/Create "|"+/g)) {
            label = label.replace(/Create "|"+/g, '');
        }
        return { label, value };
    };

    const addValue = (newValObj: MultipleOptionInterface) => {
        let tmp = [...value];
        if (singleSelect) {
            if (checkValueExist(newValObj, value)) {
                tmp = [];
            } else {
                tmp = [newValObj];
            }
        } else {
            if (!checkValueExist(newValObj, value)) {
                if (limit === null) {
                    tmp.push(newValObj);
                } else if (limit > value.length) {
                    tmp.push(newValObj);
                }
            } else {
                tmp = tmp.filter((itm) => itm.value !== newValObj.value);
            }
        }

        setNewValue(tmp);
        clearInputValue();
    };

    const deleteValue = (i: number) => {
        const tmp = [...value];
        tmp.splice(i, 1);
        setNewValue(tmp);
    };

    const clearValue = () => {
        setNewValue([]);
    };

    const showSearchOption = () => {
        if (!singleSelect && !disableChip) {
            return true;
        } else if (singleSelect && !value.length) {
            return true;
        } else if (!singleSelect && disableChip && !value.length) {
            return true;
        }
        return false;
    };

    const searchOptions = (
        str: string | number,
        options: MultipleOptionInterface[],
        exact?: boolean,
        type?: 'group' | 'value'
    ) => {
        const searchedOptions: MultipleOptionInterface[] = [];
        const searchedOptValues: MultipleOptionInterface[] = [];

        const searchInOptions = (opt: MultipleOptionInterface[]) => {
            for (let i = 0; i < opt.length; i++) {
                const opt_i = opt[parseInt(`${i}`)];
                if (opt_i?.type === 'group') {
                    searchInOptions(opt_i.childs);
                } else if (!exact) {
                    if (
                        typeof opt_i.label !== 'object' &&
                        // eslint-disable-next-line security/detect-non-literal-regexp
                        opt_i.label.match(new RegExp(`${str}`, 'gi'))
                    ) {
                        searchedOptions.push(opt_i);
                    } else if (
                        typeof opt_i.label === 'object' &&
                        // eslint-disable-next-line security/detect-non-literal-regexp
                        opt_i?.title?.match(new RegExp(`${str}`, 'gi'))
                    ) {
                        searchedOptions.push(opt_i);
                    } else if (
                        // eslint-disable-next-line security/detect-non-literal-regexp
                        (opt_i.value + '').match(new RegExp(`${str}`, 'gi'))
                    ) {
                        searchedOptValues.push(opt_i);
                    }
                } else if (exact) {
                    // @ts-ignore-next-line
                    if (opt_i[`${type}`] === str) {
                        searchedOptions.push(opt_i);
                    }
                }
                if (typeof opt_i.label === 'object' && !('title' in opt_i)) {
                    console.warn(
                        '[multiselect] you must provide a title property as typeof string, if you want to render jsx in option label'
                    );
                }
            }
        };

        searchInOptions(options);

        return [...searchedOptions, ...searchedOptValues];
    };

    const createCustomValueAndOption = (
        valueObj: MultipleOptionInterface | MultipleOptionInterface[]
    ): MultipleOptionInterface[] => {
        const customValuesGroup: MultipleOptionInterface[] = [];
        const customValuesIndx = options.findIndex(
            (opt) => opt?.type === 'group' && opt?.title === 'Custom Values'
        );

        if (customValuesIndx === -1) {
            customValuesGroup.push({
                label: '',
                value: '',
                title: 'Custom Values',
                type: 'group',
                childs: [...(valueObj as MultipleOptionInterface[])],
            });
        } else if (Array.isArray(valueObj)) {
            valueObj.map((value) =>
                options[parseInt(`${customValuesIndx}`)].childs.push(value)
            );
        } else if (typeof valueObj === 'object') {
            options[parseInt(`${customValuesIndx}`)].childs.push(valueObj);
        }
        setOptions([...options, ...customValuesGroup]);

        return customValuesGroup;
    };

    const handleSearchAndCustomValue = (
        e: React.KeyboardEvent<HTMLDivElement>
    ) => {
        const textValue = (e.target as HTMLElement).textContent
            .trim()
            .replace(/,+/g, '');

        if (textValue) {
            const newValue: MultipleOptionInterface = {
                label: `Create "${textValue}"`,
                value: textValue,
            };

            let searchedOptions: MultipleOptionInterface[] = [];

            if (hideSelected)
                searchedOptions = searchOptions(textValue, visibleOptions);
            else searchedOptions = searchOptions(textValue, options);

            if (searchedOptions.length) {
                if (customValue) {
                    const exactOptionValue = searchOptions(
                        textValue,
                        options,
                        true,
                        'value'
                    );

                    !exactOptionValue.length && searchedOptions.push(newValue);
                }
                setSearch(sortOptions(searchedOptions, withoutSort));
            } else {
                customValue ? setSearch([newValue]) : setSearch([]);
            }

            const filteredNewValue = filterCreateOpt(newValue);

            if (e.key === 'Enter' || e.key === ',') {
                if (
                    customValue &&
                    !searchedOptions.length &&
                    !checkValueExist(filteredNewValue, value)
                ) {
                    createCustomValueAndOption(filteredNewValue);

                    addValue(newValue);
                } else {
                    search.length > 0 && addValue(search[0]);
                }
            }
        } else {
            if (inputValue === '' && e.key === 'Backspace') {
                if (value.length > 0) {
                    deleteValue(-1);
                }
            }
            if (e.key === 'Enter') {
                if (onEnter && inputValue === '' && !isOptionAction)
                    onEnter(prepareValueToSend(value));
            }

            setSearch(null);
        }

        setOptionAction(false);
        setInputValue(textValue);
    };

    const nonClickableItem = (target: HTMLElement) => {
        if (
            target.hasAttribute('data-clickable') ||
            (target.parentNode as HTMLElement).hasAttribute('data-clickable') ||
            (target.parentNode.parentNode as HTMLElement).hasAttribute(
                'data-clickable'
            ) ||
            (
                target.parentNode.parentNode.parentNode as HTMLElement
            ).hasAttribute('data-clickable')
        ) {
            return false;
        }
        return true;
    };

    const checkIsDropdownHandle = (target: HTMLElement) => {
        if (
            target.hasAttribute('dropdown-handle') ||
            (target.parentNode as HTMLElement).hasAttribute(
                'dropdown-handle'
            ) ||
            (target.parentNode.parentNode as HTMLElement).hasAttribute(
                'dropdown-handle'
            )
        ) {
            return true;
        }
    };

    const focusSearchInput = () => {
        if (inputFld.current) {
            inputFld.current.focus();
        }
    };

    const openMenu = ({
        target,
    }:
        | React.MouseEvent<HTMLDivElement, MouseEvent>
        | React.KeyboardEvent<HTMLDivElement>) => {
        if (nonClickableItem(target as HTMLDivElement)) {
            if (checkIsDropdownHandle(target as HTMLDivElement)) {
                if (!menuOpen) {
                    setMenuOpen(true);
                    onMenuOpen();
                    focusSearchInput();
                } else {
                    setMenuOpen(false);
                    onMenuClose();
                }
            } else {
                setMenuOpen(true);
                onMenuOpen();
                focusSearchInput();
            }
        }
    };

    const showLabel = (optionObj: MultipleOptionInterface) => {
        if (typeof optionObj.label === 'object') {
            return optionObj?.title || optionObj.value;
        } else {
            return optionObj.label;
        }
    };

    const getActiveClass = () => {
        if (componentRef?.current) {
            const el = componentRef.current;
            const rect = el.getBoundingClientRect();
            if (window.innerHeight - (rect.top + el.clientHeight) < 200) {
                return styles['msl-active-up'];
            }
            return styles['msl-active'];
        }
    };

    return (
        <div
            data-div-input="true"
            ref={componentRef}
            {...attributes}
            onClick={openMenu}
            tabIndex={0}
            onKeyPress={openMenu}
            style={{ ...style }}
            className={classNames(
                styles['msl-wrp'],
                styles['msl-vars'],
                className,
                {
                    [styles['msl-disabled']]: disabled,
                    [styles['msl-open']]: menuOpen,
                    [styles.bigger]: bigger,
                }
            )}
        >
            <input
                name={name}
                type="hidden"
                value={value?.map((itm) => itm.value + '')}
            />
            <div
                data-msl
                className={classNames(styles.msl, {
                    [getActiveClass()]: menuOpen,
                    [styles.bigger]: bigger,
                })}
            >
                <div
                    data-msl
                    className={styles['msl-input-wrp']}
                    style={{ width: calculatedWidth }}
                >
                    {!singleSelect &&
                        !disableChip &&
                        value.map((val, i) => (
                            <Chip
                                key={`msl-chip-${i + 11}`}
                                value={val}
                                deleteAction={() => deleteValue(i)}
                            />
                        ))}
                    {!singleSelect && disableChip && value.length > 0 && (
                        <span className={styles['msl-single-value']} data-msl>
                            {value.length === 1
                                ? showLabel(value[0])
                                : `${value.length} ${chipAlternateText}`}
                        </span>
                    )}
                    {singleSelect && value.length === 1 && (
                        <span className={styles['msl-single-value']} data-msl>
                            {showLabel(value[0])}
                        </span>
                    )}
                    {showSearchOption() && (
                        <div
                            data-name={name}
                            data-msl
                            data-placeholder={placeholder}
                            className={styles['msl-input']}
                            contentEditable={!disabled}
                            onKeyUp={handleSearchAndCustomValue}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }
                            }}
                            ref={inputFld}
                        />
                    )}
                </div>
                {(clearable || downArrow) && (
                    <div
                        className={classNames(
                            styles['msl-actions'],
                            styles['msl-flx']
                        )}
                    >
                        {clearable && value.length > 0 && (
                            <div
                                role="button"
                                data-clickable="true"
                                aria-label="close-menu"
                                onClick={clearValue}
                                onKeyPress={clearValue}
                                tabIndex={noTab ? undefined : 0}
                                className={classNames(
                                    styles['msl-btn'],
                                    styles['msl-clear-btn'],
                                    styles['msl-flx']
                                )}
                            >
                                {closeIcon || <CloseIcon />}
                            </div>
                        )}
                        {downArrow && (
                            <div
                                role="button"
                                tabIndex={noTab ? undefined : 0}
                                dropdown-handle="true"
                                className={classNames(
                                    styles['msl-btn'],
                                    styles['msl-arrow-btn'],
                                    styles['msl-flx']
                                )}
                                style={{
                                    ...(menuOpen && {
                                        transform: 'rotate(180deg)',
                                    }),
                                }}
                            >
                                {downArrowIcon || <DownIcon />}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div
                className={classNames(styles['msl-options'], {
                    [styles.biggerDropdown]: bigger && menuOpen,
                })}
            >
                {!search && visibleOptions.length ? (
                    <Options
                        opts={visibleOptions}
                        {...{
                            singleSelect,
                            addValue,
                            checkValueExist,
                            value,
                            largeData,
                            menuOpen,
                            optionsRef,
                            emptyDataLabel,
                            noTab,
                            focusSearchInput,
                            setOptionAction,
                        }}
                    />
                ) : search && search.length ? (
                    <Options
                        opts={search}
                        {...{
                            singleSelect,
                            addValue,
                            checkValueExist,
                            value,
                            largeData,
                            menuOpen,
                            optionsRef,
                            emptyDataLabel,
                            noTab,
                            focusSearchInput,
                            setOptionAction,
                        }}
                    />
                ) : (
                    ((search && !search.length) ||
                        (visibleOptions && !visibleOptions.length)) && (
                        <option
                            className={classNames(
                                styles['msl-option'],
                                styles['msl-option-disable']
                            )}
                        >
                            {emptyDataLabel}
                        </option>
                    )
                )}
            </div>
        </div>
    );
};

export default MultiSelect;
