import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { MultipleOptionInterface } from './MultiSelect';
import styles from './MultiSelect.module.scss';

interface Props {
    opts: MultipleOptionInterface[];
    singleSelect: boolean;
    addValue: (newValObj: MultipleOptionInterface) => void;
    checkValueExist: (
        value: MultipleOptionInterface,
        arr: MultipleOptionInterface[]
    ) => boolean;
    value: MultipleOptionInterface[];
    largeData: boolean;
    menuOpen: boolean;
    optionsRef: React.MutableRefObject<any>;
    emptyDataLabel: string;
    noTab: boolean;
    focusSearchInput: () => void;
    setOptionAction: (isOptionAction: boolean) => void;
}

const Options = ({
    opts,
    singleSelect,
    addValue,
    checkValueExist,
    value,
    largeData,
    menuOpen,
    optionsRef,
    emptyDataLabel,
    noTab,
    focusSearchInput,
    setOptionAction,
}: Props): ReactElement => {
    if (largeData && !menuOpen) return null;

    const optsArr: ReactElement[] = [];
    const addInArr = (opts: MultipleOptionInterface[]) => {
        for (const [i, opt] of opts.entries()) {
            if (opt.type === 'group') {
                optsArr.push(
                    <div
                        key={opt.title + i}
                        data-msl
                        className={styles['msl-grp-title']}
                    >
                        {opt.title}
                    </div>
                );
                if (opt.childs.length > 0) {
                    addInArr(opt.childs);
                } else {
                    optsArr.push(
                        <option
                            className={
                                (styles['msl-option'],
                                styles['msl-option-disable'])
                            }
                        >
                            {opt.emptyDataLabel || emptyDataLabel}
                        </option>
                    );
                }
            } else {
                optsArr.push(
                    <div
                        data-child={true}
                        tabIndex={noTab ? -1 : 0}
                        key={opt.value + opt.label + i + 10}
                        {...(!singleSelect && { 'data-msl': true })}
                        style={{
                            ...(opt.style && opt.style),
                        }}
                        onClick={() => {
                            !opt.disabled && addValue(opt);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.stopPropagation();
                                e.preventDefault();
                                if (!opt.disabled) {
                                    setOptionAction(true);
                                    addValue(opt);
                                    focusSearchInput();
                                }
                            }
                        }}
                        title={
                            typeof opt.label === 'object'
                                ? opt?.title
                                : opt.label
                        }
                        className={classNames(
                            styles['msl-option'],
                            opt.classes,
                            {
                                [styles['msl-option-active']]: checkValueExist(
                                    opt,
                                    value
                                ),
                                [styles['msl-option-disable']]: opt.disabled,
                            }
                        )}
                        data-value={opt.value}
                    >
                        {opt.label}
                    </div>
                );
            }
        }
    };
    addInArr(opts);

    return (
        <div ref={optionsRef} data-wrapper={true}>
            {optsArr}
        </div>
    );
};

export default Options;
