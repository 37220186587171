import React, {
    useRef,
    useState,
    useEffect,
    ReactElement,
    ChangeEvent,
    KeyboardEvent,
    useMemo,
} from 'react';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import classNames from 'classnames';
import { replaceDiacritics } from 'utils/helpers/browser/string';
import { getDiacriticOptions } from 'utils/helpers/browser/autocomplete';
import {
    Option,
    DiacriticOption,
    StateInterface,
} from 'components/interfaces/GeneralInterface';
import { getAngleDownString } from 'components/icon/Base64BackgroundIcons';
import Icon from 'components/icon/Icon';
import CancelIcon from 'components/icon/icons/cancel.svg';
import { useTranslation } from 'utils/localization';
import { useDidUpdate } from 'utils/customHooks/useDidUpdate';
import { debounce } from 'utils/helpers/helpers';
import stylesInput from './Input.module.scss';
import styles from './Autocomplete.module.scss';

interface Props {
    name: string;
    options: Option[];
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    placeholder?: string;
    bigger?: boolean;
    value: string;
    onSearch?: (value: NextApiRequestQuery) => Promise<Option[]>;
    onSearchProperty?: string;
    disabled?: boolean;
    initValuesCb?: (value: string) => Promise<Option[]>;
    disabledValues?: string[];
    withBorder?: boolean;
    whiteBackground?: boolean;
    alwaysRerenderOptions?: boolean;
    errorMessage?: string;
    fieldNameChanged?: StateInterface;
    reInitOnFields?: string[];
    reInitWithValueOnFields?: string[];
    autoFocus?: boolean;
    isEditForm?: boolean;
}

interface AutocompleteInterface extends Props {
    sortedWithDiacriticOptions: DiacriticOption[];
    initDataDone: boolean;
}

interface AutocompleteOptionInterface {
    options: DiacriticOption[];
    diacriticOptions: DiacriticOption[];
}

const sortOptions = (values: DiacriticOption[]): DiacriticOption[] =>
    values.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );

const sortWithDiacriticOptions = (
    values: DiacriticOption[] | Option[]
): DiacriticOption[] =>
    getDiacriticOptions(values).sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );

const getSearchOptions = async (
    query: string,
    onSearch: (value: NextApiRequestQuery) => Promise<Option[]>
): Promise<DiacriticOption[]> =>
    sortWithDiacriticOptions(await onSearch({ query: query }));

const handleSearchChange = async (
    query: string,
    onSearch: (value: NextApiRequestQuery) => Promise<Option[]>,
    setTempOptions: (opts: DiacriticOption[]) => void,
    updateOptions: (opts: DiacriticOption[] | Option[]) => void,
    isClearSearch: boolean
) => {
    if (isClearSearch) {
        const opts = await getSearchOptions(query, onSearch);
        setTempOptions(opts);
        updateOptions(opts);
    } else {
        const debounced = debounce(350, async () => {
            setTempOptions(await getSearchOptions(query, onSearch));
        });
        debounced();
    }
};

const getInnerSearchOptions = (
    query: string,
    diacriticOptions: DiacriticOption[]
): DiacriticOption[] =>
    sortOptions(
        diacriticOptions?.filter(
            (option: DiacriticOption) =>
                option.labelNoDiacritics.includes(query) ||
                option.valueNoDiacritics.includes(query)
        )
    );

const handleInnerSearch = (
    query: string,
    diacriticOptions: DiacriticOption[],
    setTempOptions: (opts: DiacriticOption[]) => void,
    updateOptions: (opts: DiacriticOption[] | Option[]) => void,
    isClearSearch: boolean
) => {
    const queryNoDiacriticsLowerCase = replaceDiacritics(query).toLowerCase();
    if (isClearSearch) {
        const opts = getInnerSearchOptions(
            queryNoDiacriticsLowerCase,
            diacriticOptions
        );
        setTempOptions(opts);
        updateOptions(opts);
    } else {
        const debounced = debounce(350, async () => {
            setTempOptions(
                getInnerSearchOptions(
                    queryNoDiacriticsLowerCase,
                    diacriticOptions
                )
            );
        });
        debounced();
    }
};

const AutocompleteInner = ({
    name,
    sortedWithDiacriticOptions,
    placeholder,
    value,
    onChange,
    bigger,
    disabled,
    onSearch,
    disabledValues,
    onSearchProperty,
    withBorder,
    whiteBackground,
    errorMessage,
    initDataDone,
    autoFocus,
    fieldNameChanged,
    reInitWithValueOnFields,
}: AutocompleteInterface): ReactElement => {
    const { t } = useTranslation();
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const optionsRef = useRef(null);
    const [listOpen, setListOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<AutocompleteOptionInterface>({
        options: sortedWithDiacriticOptions,
        diacriticOptions: sortedWithDiacriticOptions,
    });
    const [tempOptions, setTempOptions] = useState<DiacriticOption[]>(
        sortedWithDiacriticOptions
    );

    useEffect(() => {
        if (listOpen) {
            document.addEventListener('click', handleClickOutside, true);
            return () => {
                document.removeEventListener('click', handleClickOutside, true);
            };
        }
    });

    useEffect(() => {
        if (autoFocus) {
            focusInput();
        }
    }, []);

    useEffect(() => {
        const isReInitAction =
            onSearch &&
            reInitWithValueOnFields?.includes(Object.keys(fieldNameChanged)[0]);

        if (isReInitAction) {
            initOptionsOnSearch(Object.values(fieldNameChanged)[0]);
        }
    }, [fieldNameChanged]);

    const updateOptions = (opts: DiacriticOption[] | Option[]) => {
        if (opts) {
            const sorted = sortWithDiacriticOptions(opts);
            setOptions({
                options: sorted,
                diacriticOptions: sorted,
            });
        }
    };

    const convertOnSearchProperty = (value: string): string =>
        value
            ? ['internal_id', 'id'].includes(value)
                ? value + '[]'
                : value
            : 'id[]';

    const initOptionsOnSearch = (value?: string) => {
        if (onSearch && value)
            if (
                !isNaN(Number(value)) &&
                !sortedWithDiacriticOptions?.some(
                    (o) => o.value + '' === value + ''
                )
            ) {
                onSearch({
                    [convertOnSearchProperty(onSearchProperty)]: value,
                }).then((data) => {
                    const newOptions = sortOptions(
                        sortedWithDiacriticOptions.concat(
                            sortWithDiacriticOptions(data)
                        )
                    );
                    updateOptions(newOptions);
                    setTempOptions(newOptions);
                });
            }
    };

    useDidUpdate(() => {
        if (initDataDone) {
            updateOptions(sortedWithDiacriticOptions);
            setTempOptions(sortedWithDiacriticOptions);
        }
    }, [initDataDone, sortedWithDiacriticOptions]);

    useEffect(() => {
        if (initDataDone) initOptionsOnSearch(value);
    }, [initDataDone]);

    const handleClickOutside = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            if (inputRef.current) {
                inputRef.current.innerHTML = getValueName();
            }

            document.removeEventListener('click', handleClickOutside, true);
            setListOpen(false);
        }
    };

    const handleChange = (val: string | number, isClear?: boolean) => {
        if (val) setListOpen(false);
        focusInput();
        if (val !== value) {
            onChange({
                target: { name, value: val },
            } as ChangeEvent<HTMLSelectElement>);
        }

        if (inputRef.current) {
            inputRef.current.innerHTML = getValueName();
        }
        if (!isClear) {
            updateOptions(tempOptions);
        }
    };

    const handleClearSearch = () => {
        handleChange(null, true);
        handleSearchValue('', true);
    };

    const getValueName = () => {
        const valueStr = value?.toString() || value;
        if (valueStr) {
            const diacriticOption = options.diacriticOptions.find(
                ({ value }) => value.toString() === valueStr
            );

            return diacriticOption ? diacriticOption.label : '';
        }

        return '';
    };

    const nonClickableItem = (target: HTMLElement) => {
        if (
            target.hasAttribute('data-non-clickable') ||
            (target.parentNode as HTMLElement).hasAttribute(
                'data-non-clickable'
            ) ||
            (target.parentNode.parentNode as HTMLElement).hasAttribute(
                'data-non-clickable'
            ) ||
            (
                target.parentNode.parentNode.parentNode as HTMLElement
            ).hasAttribute('data-non-clickable')
        ) {
            return true;
        }
        return false;
    };

    const renderCancelIcon = (): ReactElement => {
        return (
            <span
                className={classNames(styles.iconWrap, {
                    [styles.iconBiggerWrap]: bigger,
                })}
                onClick={disabled ? undefined : handleClearSearch}
                data-non-clickable={'true'}
                tabIndex={0}
                onKeyUp={(e) => {
                    if (disabled) return;
                    if (
                        e.key === 'Enter' ||
                        e.key === 'Spacebar' ||
                        e.key === ' '
                    ) {
                        handleClearSearch();
                        focusInput();
                    }
                }}
            >
                <Icon
                    icon={CancelIcon}
                    className={classNames(styles.cancelIcon, {
                        [styles.pointer]: !disabled,
                    })}
                />
            </span>
        );
    };

    const doNothing = (event: KeyboardEvent) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        switch (e.key) {
            case 'Escape':
            case 'Tab':
                {
                    if (inputRef.current) {
                        inputRef.current.innerHTML = getValueName();
                    }
                    setListOpen(false);
                }
                break;
            case 'Enter':
                {
                    const active = document.activeElement;
                    if (active.hasAttribute('data-child')) {
                        doNothing(e);
                        handleChange(e.currentTarget.getAttribute('d-value'));
                    }
                }
                break;
            case 'ArrowUp':
                {
                    doNothing(e);
                    const active = document.activeElement;
                    const isChild = active.hasAttribute('data-child');
                    if (isChild && active.previousSibling) {
                        const htmlEl = active.previousSibling as HTMLElement;
                        htmlEl.focus();
                    } else {
                        if (optionsRef?.current)
                            optionsRef.current.lastChild.focus();
                    }
                }
                break;
            case 'ArrowDown':
                {
                    doNothing(e);
                    const active = document.activeElement;
                    const isChild = active.hasAttribute('data-child');
                    if (isChild && active.nextSibling) {
                        const htmlEl = active.nextSibling as HTMLElement;
                        htmlEl.focus();
                    } else {
                        if (optionsRef?.current)
                            optionsRef.current.firstChild.focus();
                    }
                }
                break;
        }
    };

    const onBlur = () => {
        if (inputRef) inputRef.current.scrollLeft = 0;
    };

    const openMenu = ({
        target,
    }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (disabled || nonClickableItem(target as HTMLDivElement) || listOpen)
            return;

        setTempOptions(options.options);

        setListOpen(true);
        focusInput();
    };

    const focusInput = () => {
        if (inputRef) {
            inputRef.current.focus();
        }
    };

    const handleSearchValue = async (
        e: React.KeyboardEvent<HTMLDivElement> | string,
        isClear?: boolean
    ) => {
        if (typeof e === 'object' && 'key' in e && e.key === 'Enter') return;

        const textValue =
            typeof e === 'string'
                ? e
                : typeof e === 'undefined'
                ? ''
                : (e.target as HTMLElement).textContent.trim();

        if (onSearch) {
            handleSearchChange(
                textValue,
                onSearch,
                setTempOptions,
                updateOptions,
                isClear
            );
        } else {
            handleInnerSearch(
                textValue,
                sortedWithDiacriticOptions,
                setTempOptions,
                updateOptions,
                isClear
            );
        }

        if (!listOpen && textValue) {
            setListOpen(true);
        }
    };

    return (
        <>
            <div
                className={classNames(styles.root)}
                ref={wrapperRef}
                onClick={openMenu}
            >
                <input name={name} type="hidden" value={value || ''} />
                <div
                    data-name={name}
                    data-div-input="true"
                    className={classNames(
                        styles.inputWrapper,
                        stylesInput.input,
                        {
                            [styles.disabled]: disabled,
                            [stylesInput.bigger]: bigger,
                            [stylesInput.whiteBackground]: !!whiteBackground,
                            [stylesInput.withBorder]: !!withBorder,
                            [styles.open]: listOpen,
                            [styles.valueChanged]:
                                getValueName() !== inputRef?.current?.innerHTML,
                        }
                    )}
                    style={
                        (!value && {
                            backgroundImage: `url("${getAngleDownString()}")`,
                            backgroundPositionY: 'center',
                        }) ||
                        undefined
                    }
                >
                    <div
                        ref={inputRef}
                        contentEditable={!disabled}
                        suppressContentEditableWarning={true}
                        data-placeholder={placeholder}
                        className={styles.input}
                        onBlur={onBlur}
                        onPaste={(el) => {
                            const pasteData =
                                el.clipboardData.getData('text/plain');
                            if (!pasteData) {
                                el.stopPropagation();
                                el.preventDefault();
                            } else {
                                el.preventDefault();
                                document.execCommand(
                                    'insertHTML',
                                    false,
                                    pasteData
                                );
                            }
                        }}
                        onKeyUp={handleSearchValue}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                doNothing(e);
                            } else {
                                onKeyDown(e);
                            }
                        }}
                    >
                        {getValueName()}
                    </div>
                </div>

                {!!value && renderCancelIcon()}
                {listOpen && (
                    <div
                        className={classNames(styles.dropdown, {
                            [styles.biggerDropdown]: bigger,
                        })}
                    >
                        <div ref={optionsRef} className={styles.options}>
                            {tempOptions?.length > 0 ? (
                                tempOptions.map((option) => (
                                    <div
                                        tabIndex={-1}
                                        className={classNames(styles.option, {
                                            [styles.active]:
                                                value === option.value &&
                                                !isOptionDisabled(
                                                    disabledValues,
                                                    option
                                                ),
                                            [styles.optionDisabled]:
                                                isOptionDisabled(
                                                    disabledValues,
                                                    option
                                                ),
                                        })}
                                        onKeyDown={onKeyDown}
                                        onClick={() =>
                                            handleChange(option.value)
                                        }
                                        data-non-clickable="true"
                                        data-child="true"
                                        d-value={option.value}
                                        key={option.value}
                                    >
                                        {option.label}
                                    </div>
                                ))
                            ) : (
                                <div className={styles.noResult}>
                                    {t('general.noResults')}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {!!errorMessage && (
                <div className={styles.error}>{errorMessage}</div>
            )}
        </>
    );
};

const isOptionDisabled = (disabledValues: string[], option: Option): boolean =>
    disabledValues?.some((disValue) => disValue + '' === option.value + '');

/**
 * onChange doesn't call api
 * 1) Values
 * 2) initValues
 *
 * onChange - calls api
 * 3) Values & onSearch
 * * 3.1) Dynamic Values & onSearch
 * 4) initValues & onSearch
 */
const Autocomplete = (props: Props): ReactElement => {
    const [options, setOptions] = useState<Option[]>(props.options);
    const [initDataDone, setInitDataDone] = useState<boolean>(
        !props.initValuesCb
    );

    const preSelectValueByOptions = (data: Option[]) => {
        if (data && data.length === 1 && !props.value) {
            props.onChange({
                target: { name: props.name, value: data[0].value },
            } as ChangeEvent<HTMLSelectElement>);
        }
    };

    const fetchValuesCb = (value?: string) => {
        const isReInitAction =
            props.initValuesCb &&
            props.reInitOnFields?.includes(
                Object.keys(props.fieldNameChanged)[0]
            );

        if (props.initValuesCb && (!initDataDone || isReInitAction))
            props.initValuesCb(value).then((data) => {
                setOptions(data);
                setInitDataDone(true);
                props.isEditForm && preSelectValueByOptions(data);

                if (
                    value &&
                    isReInitAction &&
                    !data.some((d) => d.value + '' === value + '')
                ) {
                    props.onChange({
                        target: { name: props.name, value: undefined },
                    } as ChangeEvent<HTMLSelectElement>);
                }
            });
    };

    useEffect(() => fetchValuesCb(props.value), [props.fieldNameChanged]);
    useEffect(() => {
        if (
            !props.initValuesCb &&
            (!options || !options.length || props.alwaysRerenderOptions)
        ) {
            setOptions(props.options);
        }
    }, [props.options]);

    useEffect(() => {
        props.isEditForm && preSelectValueByOptions(props.options);
    }, []);

    const sortedWithDiacriticOptions = useMemo(() => {
        return sortWithDiacriticOptions(options);
    }, [options]);

    return (
        <AutocompleteInner
            {...props}
            options={options}
            initDataDone={initDataDone}
            sortedWithDiacriticOptions={sortedWithDiacriticOptions}
        />
    );
};

export default Autocomplete;
