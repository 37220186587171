import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import classNames from 'classnames';
import { Option } from 'components/interfaces/GeneralInterface';
import { getAngleDownString } from 'components/icon/Base64BackgroundIcons';
import { useTranslation } from 'utils/localization';
import styles from './Select.module.scss';

interface Props {
    options: Option[];
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    value: string | string[];
    bigger?: boolean;
    name: string;
    placeholder?: string;
    errorMessage?: string;
    disabled?: boolean;
    multiple?: boolean;
    noEmptyOption?: boolean;
    autoFocus?: boolean;
    isEditForm?: boolean;
    firstEmptyOption?: Option;
    initValuesCb?: (value: string) => Promise<Option[]>;
}

const getOptionsWithFirstOption = (
    t: TFunction,
    options: Option[] = [],
    firstEmptyOption?: Option,
    noEmptyOption?: boolean,
    allText?: string
): Option[] => {
    return !(firstEmptyOption || noEmptyOption)
        ? [
              {
                  label: allText ? allText : t('general.all'),
                  value: '',
              },
              ...options,
          ]
        : firstEmptyOption
        ? [
              {
                  label: allText ? allText : t('general.all'),
                  value: '',
              },
              ...options,
          ]
        : options;
};

const Select = ({
    options,
    value,
    onChange,
    name,
    errorMessage,
    bigger,
    disabled,
    multiple,
    noEmptyOption,
    firstEmptyOption,
    initValuesCb,
    autoFocus,
    isEditForm,
    placeholder,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const getOptions = (opt: Option[]) => {
        return initValuesCb
            ? []
            : getOptionsWithFirstOption(
                  t,
                  opt,
                  firstEmptyOption,
                  noEmptyOption,
                  placeholder
              );
    };

    const [optionsState, setOptions] = useState<Option[]>(getOptions(options));

    useEffect(() => {
        const preSelectValueByOptions = (data: Option[]) => {
            if (data && data.length === 1 && !value) {
                onChange({
                    target: { name, value: data[0].value },
                } as ChangeEvent<HTMLSelectElement>);
            }
        };

        isEditForm && preSelectValueByOptions(options);

        initValuesCb &&
            initValuesCb(value as string).then((data) => {
                isEditForm && preSelectValueByOptions(data);
                setOptions(
                    getOptionsWithFirstOption(
                        t,
                        data,
                        firstEmptyOption,
                        noEmptyOption,
                        placeholder
                    )
                );
            });
    }, []);

    useEffect(() => {
        setOptions(getOptions(options));
    }, [options]);

    const innerOptions = optionsState.map((option, index) => (
        <option key={`option-${index}-${option.value}`} value={option.value}>
            {option.label}
        </option>
    ));

    return (
        <>
            <div className={styles.wrapper}>
                <select
                    onChange={onChange}
                    className={classNames(styles.list, {
                        [styles.bluePlaceholder]: !!value,
                        [styles.bigger]: !!bigger,
                        [styles.multiple]: !!multiple,
                        [styles.disabled]: disabled,
                    })}
                    name={name}
                    value={typeof value === 'undefined' ? '' : value}
                    style={{
                        backgroundImage:
                            !multiple && `url("${getAngleDownString()}")`,
                    }}
                    disabled={disabled}
                    multiple={multiple}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                >
                    {innerOptions}
                </select>
            </div>
            {!!errorMessage && (
                <div className={styles.error}>{errorMessage}</div>
            )}
        </>
    );
};

export default Select;
