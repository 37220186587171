import React, { ReactElement } from 'react';
import { RowProps, Tr } from '../Row';
import SortableColumn from './SortableColumn';

const SortableRow = ({
    t,
    index,
    row,
    columns,
    highlightRow,
    isSlim,
}: RowProps): ReactElement => {
    return (
        <Tr index={index} highlightRow={highlightRow} isSlim={isSlim}>
            <>
                {row.map((col, rowIndex) => (
                    <SortableColumn
                        key={rowIndex}
                        t={t}
                        col={col}
                        colHead={columns[parseInt(`${rowIndex}`)]}
                        input={col.input}
                        reactKey={index + '|' + rowIndex}
                    />
                ))}
            </>
        </Tr>
    );
};

export default SortableRow;
