import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import stylesFilter from '../tables/filters/Filter.module.scss';
import FileAdditionalModal from './FileAdditionalModal';
import { useTranslation } from 'utils/localization';
import {
    EditFieldValueFileInterface,
    FilterSize,
    StateInterface,
} from 'components/interfaces/GeneralInterface';
import styles from './File.module.scss';

export interface FileInterface {
    id: string;
    fileName: string;
}

export interface FileChangeInterface {
    [key: string]: {
        newFiles: NewFileInterface[];
        deletedFiles: FileInterface[];
    };
}

export interface NewFileInterface {
    file: File;
    additionalData: StateInterface;
}

export interface FileRequestInterface {
    newFiles: File[];
    deletedFiles: FileInterface[];
}

interface Props {
    name: string;
    value: EditFieldValueFileInterface[];
    onChange: (name: string, value: EditFieldValueFileInterface[]) => void;
    className?: string;
    disabled?: boolean;
    placeholder?: string;
    acceptedTypes?: string[];
    maxFiles?: number;
    size?: FilterSize;
    withAdditionalModal?: boolean;
}

const transformNewFilesToValueFile = (
    id: string,
    setId: (id: string) => void,
    withAdditionalModal: boolean,
    files: FileWithPath[]
): EditFieldValueFileInterface[] => {
    let ids = id;
    const fieldFiles = files.map((file: FileWithPath) => {
        ids += '1';
        return {
            id: ids,
            file: file as File,
            fileName: file.name,
            additionalData: withAdditionalModal ? {} : undefined,
        } as EditFieldValueFileInterface;
    });

    setId(ids);

    return fieldFiles;
};

interface AdditionalDataInterface {
    data: EditFieldValueFileInterface[];
    action: 'add' | 'edit';
}

type ErrorCodeType =
    | 'file-too-large'
    | 'file-too-small'
    | 'too-many-files'
    | 'file-invalid-type';

const File = ({
    name,
    placeholder = 'File.placeholder',
    onChange,
    className,
    value = [],
    acceptedTypes = [],
    disabled,
    maxFiles = 1,
    size,
    withAdditionalModal,
}: Props): ReactElement => {
    const [tempId, setTempId] = useState('NEW');
    const [rejected, setRejected] = useState<
        {
            fileName: string;
            errorCode: ErrorCodeType;
        }[]
    >([]);
    const [additionalModal, setAdditionalModal] =
        useState<AdditionalDataInterface>();
    const { t } = useTranslation();
    const addFiles = (files: EditFieldValueFileInterface[]) => {
        onChange(name, [...value, ...files]);
    };
    const editFile = (file: EditFieldValueFileInterface) => {
        onChange(
            name,
            value.map((val) => {
                if (val.fileName === file.fileName) {
                    val.additionalData = file.additionalData;
                }
                return val;
            })
        );
    };

    const deleteFile = (id: string) =>
        onChange(
            name,
            value
                .map((file: EditFieldValueFileInterface) =>
                    id + '' === file.id + ''
                        ? { ...file, isDeleted: true }
                        : file
                )
                .filter(
                    (file: EditFieldValueFileInterface) =>
                        !file.isDeleted ||
                        (file.isDeleted &&
                            id + '' === file.id + '' &&
                            file.url) ||
                        file.isDeleted
                )
        );

    const maxLimitReached =
        maxFiles ===
        value.filter((file: EditFieldValueFileInterface) => !file.isDeleted)
            .length;

    disabled = disabled || maxLimitReached;

    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptedTypes.join(','),
        disabled: disabled,
        maxFiles: maxFiles,
        multiple: maxFiles !== 1,
        onDrop: () => setRejected([]),
        onDropAccepted: (files: FileWithPath[]) => {
            if (withAdditionalModal)
                setAdditionalModal({
                    action: 'add',
                    data: transformNewFilesToValueFile(
                        tempId,
                        setTempId,
                        withAdditionalModal,
                        files
                    ),
                });
            else
                addFiles(
                    transformNewFilesToValueFile(
                        tempId,
                        setTempId,
                        withAdditionalModal,
                        files
                    )
                );
        },
        maxSize: 20971520,
        onDropRejected: (fileRejection: FileRejection[]) => {
            if (
                fileRejection.some((r) =>
                    r.errors.some(
                        (err) =>
                            (err.code as ErrorCodeType) === 'too-many-files'
                    )
                )
            ) {
                setRejected([
                    {
                        fileName: '',
                        errorCode: 'too-many-files',
                    },
                ]);
            } else {
                const rejections = fileRejection.map((r) => ({
                    fileName: r.file.name,
                    errorCode: r.errors[0].code as ErrorCodeType,
                }));
                setRejected(rejections);
            }
        },
    });

    const addedFiles: ReactElement[] = [];
    const savedFiles: ReactElement[] = [];

    const createRow = (
        index: number,
        file: EditFieldValueFileInterface
    ): ReactElement => (
        <li key={'file-' + index}>
            <div className={styles.fileWrapper}>
                {file.url ? (
                    <a
                        href={file.url}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.fileName}
                    >
                        {file.fileName}
                    </a>
                ) : (
                    <span className={styles.fileName}>{file.fileName}</span>
                )}
                {!!file.additionalData && (
                    <span
                        className={styles.deleteLink}
                        onClick={() =>
                            setAdditionalModal({ action: 'edit', data: [file] })
                        }
                    >
                        {t('general.edit')}
                    </span>
                )}
                <span
                    className={styles.deleteLink}
                    onClick={() => deleteFile(file.id)}
                >
                    {t('general.delete')}
                </span>
            </div>
        </li>
    );

    value
        .filter((file) => !file.isDeleted)
        .forEach((file: EditFieldValueFileInterface, index: number) => {
            if (file.file) {
                addedFiles.push(createRow(index, file));
            } else if (file.file !== null && file.url) {
                //is from backend & not deleted
                savedFiles.push(createRow(index, file));
            }
        });

    return (
        <>
            {!!additionalModal && (
                <FileAdditionalModal
                    show={!!additionalModal}
                    onHide={() => setAdditionalModal(null)}
                    files={additionalModal.data}
                    action={additionalModal.action}
                    onAction={(action, files) => {
                        if (action === 'add') {
                            addFiles(files);
                        } else {
                            editFile(files[0]);
                        }
                    }}
                />
            )}
            <section className="container">
                {!disabled && (
                    <div
                        {...getRootProps()}
                        className={classNames(className, styles.dropzone, {
                            [stylesFilter['size-' + size]]: !!size,
                        })}
                    >
                        <input {...getInputProps()} />
                        <p>{t(placeholder)}</p>
                    </div>
                )}
                <aside>
                    <ul>{savedFiles}</ul>
                    <ul>{addedFiles}</ul>
                </aside>
                {rejected &&
                    rejected.map(({ fileName, errorCode }, idx) => (
                        <div key={idx} className={styles.error}>
                            {t(`File.${errorCode}`, { fileName })}
                        </div>
                    ))}
            </section>
        </>
    );
};

export default File;
