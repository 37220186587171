import React, { Dispatch, ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'utils/localization';
import MobileBreadcrumbs from 'components/editForm/MobileBreadcrumbs';
import styles from './EditFormBreadcrumbs.module.scss';

interface Props {
    step: number;
    setStep?: Dispatch<number>;
    breadcrumbs: string[];
    onStep?: (step: number) => void;
    allStepsClickable?: boolean;
}

const EditFormBreadcrumbs = ({
    step,
    setStep,
    breadcrumbs,
    onStep,
    allStepsClickable,
}: Props): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <div className={styles.mobileStepper}>
                <MobileBreadcrumbs step={step} stepCount={breadcrumbs.length} />
            </div>

            {breadcrumbs.map((name: string, key) => (
                <div
                    key={key}
                    className={classNames(styles.breadCrumb, {
                        [styles.activeBreadCrumb]: key === step,
                        [styles.clickableBreadCrumb]:
                            (!!setStep || !!onStep) &&
                            (key < step || allStepsClickable) &&
                            key !== step,
                    })}
                    onClick={
                        key < step || allStepsClickable
                            ? () => {
                                  onStep?.(key);
                                  setStep?.(key);
                              }
                            : undefined
                    }
                >
                    {t(name)}
                </div>
            ))}
        </div>
    );
};

export default EditFormBreadcrumbs;
