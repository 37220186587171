export const connectStrings = (
    connector: string,
    ...strings: string[]
): string => {
    return strings.filter((string) => !!string).join(connector) || '-';
};

export const replaceDiacritics = (text: string): string => {
    const diacritics: { [key: string]: any } = {
        a: 'ÀÁÂÃÄÅàáâãäåĀāąĄ',
        c: 'ÇçćĆčČ',
        d: 'đĐďĎ',
        e: 'ÈÉÊËèéêëěĚĒēęĘ',
        i: 'ÌÍÎÏìíîïĪī',
        l: 'łŁ',
        n: 'ÑñňŇńŃ',
        o: 'ÒÓÔÕÕÖØòóôõöøŌō',
        r: 'řŘ',
        s: 'ŠšśŚ',
        t: 'ťŤ',
        u: 'ÙÚÛÜùúûüůŮŪū',
        y: 'ŸÿýÝ',
        z: 'ŽžżŻźŹ',
    };

    return text
        .toString()
        .split('')
        .map(
            (l) =>
                Object.keys(diacritics).find((k) =>
                    diacritics[k].includes(l)
                ) || l
        )
        .join('');
};
