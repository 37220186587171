import React, { ChangeEvent, ReactElement } from 'react';
import classNames from 'classnames';
import styles from './Checkbox.module.scss';

interface Props {
    name: string;
    className?: string;
    checkboxLabel?: string;
    value: boolean;
    disabled?: boolean;
    bigger?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({
    name,
    className,
    value: checked,
    onChange,
    disabled,
    bigger,
    checkboxLabel,
}: Props): ReactElement => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
    };

    return (
        <div
            data-div-input="true"
            className={classNames(
                styles.root,
                {
                    [styles.disabled]: disabled,
                    [styles.bigger]: bigger,
                },
                className
            )}
        >
            <label className={styles.switch}>
                <input
                    type="checkbox"
                    value={checked ? 'true' : 'false'}
                    disabled={disabled}
                    defaultChecked={!!checked}
                    onClick={() => {
                        handleChange(
                            // @ts-ignore-next-line
                            { target: { name, value: !checked } }
                        );
                    }}
                />
                <span className={classNames(styles.slider, styles.round)} />
            </label>
            <label
                className={classNames(styles.checkboxLabel, {
                    [styles.disabled]: disabled,
                })}
                htmlFor={name}
                onClick={() => {
                    handleChange(
                        // @ts-ignore-next-line
                        { target: { name, value: !checked } }
                    );
                }}
            >
                {checkboxLabel}
            </label>
        </div>
    );
};

export default Checkbox;
