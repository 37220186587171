import React, { Dispatch, ReactElement, SetStateAction, useRef } from 'react';
import classNames from 'classnames';
import { TFunction } from 'next-i18next';
import {
    SortableContainer,
    SortableElement,
    SortEnd,
} from 'react-sortable-hoc';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { getInnerTableEnd } from '../Table';
import stylesTable from '../Table.module.scss';
import TableHeader from '../TableHeader';
import SortableRow from './SortableRow';
import { useTranslation } from 'utils/localization';
import {
    ColumnHeadInterface,
    ColumnValueInterface,
    StateInterface,
} from 'components/interfaces/GeneralInterface';

interface SortableTableProps {
    t: TFunction;
    tableRef: React.MutableRefObject<any>;
    records: ColumnValueInterface[][];
    columns: ColumnHeadInterface[];
    noLeftPadding: boolean;
    highlightRow: number;
    loading: boolean;
    slimRows: boolean;
    activeFilters: StateInterface;
    onSearch: (s: NextApiRequestQuery) => void;
    setLoadingAction: Dispatch<SetStateAction<boolean>>;
}

interface SortableRowProps {
    t: TFunction;
    row: ColumnValueInterface[];
    sortIndex: number;
    columns: ColumnHeadInterface[];
    highlightRow: number;
    isSlim: boolean;
}

interface Props {
    records: ColumnValueInterface[][];
    columns: ColumnHeadInterface[];
    loading: boolean;
    noLeftPadding: boolean;
    slimRows: boolean;
    highlightRow: number;
    onSortEndAction: (oldIndex: number, newIndex: number) => void;
    activeFilters: StateInterface;
    onSearch: (s: NextApiRequestQuery) => void;
    setLoadingAction: Dispatch<SetStateAction<boolean>>;
}

const SortableTable = ({
    records,
    columns,
    loading,
    noLeftPadding,
    slimRows,
    highlightRow,
    onSortEndAction,
    setLoadingAction,
    activeFilters,
    onSearch,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const tableRef = useRef(null);

    const onSortEnd = (sort: SortEnd) => {
        onSortEndAction && onSortEndAction(sort.oldIndex, sort.newIndex);
    };

    const getHelperContainer = () => {
        return tableRef.current.querySelector('tbody');
    };

    const getContainer = () => {
        return tableRef.current;
    };

    return (
        <SortableTableContainer
            t={t}
            tableRef={tableRef}
            useDragHandle
            distance={7}
            lockToContainerEdges
            lockAxis={'y'}
            helperClass={stylesTable['table-row-dragged']}
            getContainer={getContainer}
            helperContainer={getHelperContainer}
            records={records}
            columns={columns}
            noLeftPadding={noLeftPadding}
            highlightRow={highlightRow}
            loading={loading}
            onSortEnd={onSortEnd}
            slimRows={slimRows}
            setLoadingAction={setLoadingAction}
            activeFilters={activeFilters}
            onSearch={onSearch}
        />
    );
};

const SortableTableElement = SortableElement(
    ({
        t,
        row,
        sortIndex,
        columns,
        highlightRow,
        isSlim,
    }: SortableRowProps) => (
        <SortableRow
            t={t}
            key={'row-' + sortIndex}
            index={sortIndex}
            row={row}
            columns={columns}
            highlightRow={highlightRow}
            isSlim={isSlim}
        />
    )
);

const SortableTableContainer = SortableContainer(
    ({
        t,
        tableRef,
        records,
        columns,
        noLeftPadding,
        highlightRow,
        loading,
        slimRows,
        setLoadingAction,
        activeFilters,
        onSearch,
    }: SortableTableProps) => {
        return (
            <table
                ref={tableRef}
                className={classNames(stylesTable.table, {
                    [stylesTable.isLoading]: loading,
                })}
            >
                <TableHeader
                    columns={columns}
                    noLeftPadding={noLeftPadding}
                    onSearch={onSearch}
                    activeFilters={activeFilters}
                    setLoadingAction={setLoadingAction}
                />
                <tbody className={noLeftPadding && stylesTable.noLeftPadding}>
                    {records.map((record, i) => (
                        <SortableTableElement
                            t={t}
                            key={'row-' + i}
                            row={record}
                            index={i}
                            sortIndex={i}
                            columns={columns}
                            highlightRow={highlightRow}
                            isSlim={slimRows}
                        />
                    ))}
                </tbody>
                {getInnerTableEnd(t, loading)}
            </table>
        );
    }
);

export default SortableTable;
