import React, { ReactElement } from 'react';
import { useTranslation } from 'utils/localization';
import styles from './PhotoHelp.module.scss';

interface Props {
    help?: string;
    helpKey?: string;
}

const PhotoHelp = ({ help, helpKey }: Props): ReactElement => {
    if (!help && !helpKey) {
        return null;
    }

    const { t } = useTranslation();

    return (
        <div>
            <div className={styles.formatTitle}>
                <span>{t('photoUploader.photoHelpTitle')}</span>
            </div>
            <div
                dangerouslySetInnerHTML={{ __html: help ? help : t(helpKey) }}
            />
        </div>
    );
};

export default PhotoHelp;
