import React, { ChangeEvent, ReactElement, useMemo, useState } from 'react';
import TableEntitiesModal from './TableEntitiesModal';
import { useTranslation } from 'utils/localization';
import Table, { createTd, createTh } from 'components/tables/Table';
import {
    ColumnColor,
    ColumnHeadInterface,
    ColumnSize,
    ColumnValueInterface,
    ColumnValueWeight,
    GroupEditFieldInterface,
    StateInterface,
} from 'components/interfaces/GeneralInterface';
import {
    FieldTypeApiEnum,
    ValuesStringInterface,
} from 'submodules/api_middleware';
import { FileStateInterface } from 'components/interfaces/EditFormInterface';

interface Props {
    name: string;
    title: string;
    modalTitle: string;
    columns: ColumnHeadInterface[];
    modalFields: GroupEditFieldInterface[];
    initData: TableEntitiesDataInterface[];
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export interface TableEntitiesDataInterface {
    values: StateInterface;
    files?: FileStateInterface;
}

const TableEntities = ({
    name,
    columns,
    modalFields,
    onChange,
    initData = [],
    title,
    modalTitle,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const [modal, setModal] = useState<string>(null);
    const [tempId, setTempId] = useState<string>('NEW');
    const [tableValues, setTableValues] =
        useState<TableEntitiesDataInterface[]>(initData);

    const columnsWithContext = columns.concat(
        createTh(
            t,
            undefined,
            ColumnSize.SFIX,
            [
                {
                    onClick: (id) => {
                        setModal(id + '');
                    },
                    label: t('general.edit'),
                },
                {
                    onClick: (id) => {
                        const data = tableValues.filter(
                            (val) => val.values.id + '' !== id + ''
                        );
                        setTableValues(data);
                        handleChange(data);
                    },
                    label: t('general.delete'),
                },
            ],
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            'id'
        )
    );

    const handleChange = (data: TableEntitiesDataInterface[]) => {
        onChange({
            target: {
                name,
                //@ts-ignore-next-line
                value: data,
            },
        });
    };

    const tableData = useMemo<ColumnValueInterface[][]>(
        () =>
            tableValues.map((tValue) => {
                const tds: ColumnValueInterface[] = [];
                columnsWithContext.forEach((column) => {
                    const fileValues =
                        tValue.files && tValue.files[column.tableName];
                    if (fileValues) {
                        tds.push(
                            createTd(
                                fileValues
                                    .filter((file) => !file.isDeleted)
                                    .map((file) => ({
                                        linkLabel: file.fileName,
                                        url: file.url,
                                    })),
                                ColumnValueWeight.BOLD,
                                ColumnColor.BLUE
                            )
                        );
                    } else {
                        tds.push(createTd(tValue.values[column.tableName]));
                    }
                });

                return tds;
            }),
        [tableValues]
    );

    const onModalAction = (
        action: 'add' | 'edit',
        values: ValuesStringInterface,
        files: FileStateInterface
    ) => {
        if (action === 'add') {
            const data = tableValues.concat({
                values: { ...values, id: tempId },
                files,
            });
            setTempId(tempId + '1');
            setTableValues(data);
            handleChange(data);
        } else {
            const data = tableValues.map((tVal) => {
                if (tVal.values.id + '' === modal) {
                    tVal = {
                        values: { ...tVal.values, ...values },
                        files,
                    };
                }

                return tVal;
            });
            setTableValues([...data]);
            handleChange([...data]);
        }
    };

    const getFields = (
        modalFields: GroupEditFieldInterface[],
        id: string
    ): GroupEditFieldInterface[] => {
        const modified = [...modalFields];
        if (id === '-1') {
            modified.forEach((f) =>
                f.fields.forEach((field) => {
                    field.value = undefined;
                })
            );
        } else {
            const row = tableValues.find(
                (val) => val.values.id + '' === id + ''
            );
            modified.forEach((f) =>
                f.fields.forEach((field) => {
                    if (field.type === FieldTypeApiEnum.FILE) {
                        field.value = row.files && row.files[field.name];
                    } else field.value = row.values[field.name];
                })
            );
        }
        return modified;
    };

    return (
        <div>
            {!!modal && (
                <TableEntitiesModal
                    show={!!modal}
                    onHide={() => setModal(null)}
                    onAction={onModalAction}
                    fields={getFields(modalFields, modal)}
                    action={modal === '-1' ? 'add' : 'edit'}
                    modalTitle={modalTitle}
                />
            )}
            <Table
                cardTitle={t(title)}
                columns={columnsWithContext}
                data={tableData}
                actions={[{ type: 'add', onClick: () => setModal('-1') }]}
                isModalView
                smallerTitle
            />
        </div>
    );
};

export default TableEntities;
