import React, { VFC } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { Option } from 'components/interfaces/GeneralInterface';
import SubSubMenuFilterSC from 'components/subSubMenuWrapper/SubSubMenu.styled';

interface Props {
    options: Option[];
    value?: string | number;
    onChange: (name: string, value: string | number) => void;
    name: string;
    disabled?: boolean;
}

const SubSubMenuFilter: VFC<Props> = ({
    options,
    value,
    onChange,
    name,
    disabled = false,
}) => {
    const { t } = useTranslation();

    return (
        <SubSubMenuFilterSC
            className={classNames({
                disabled,
            })}
        >
            {options.map(({ label, value: val }, key) => (
                <div
                    key={`${key}-${label}`}
                    className={classNames('item', {
                        active: val === value,
                    })}
                    onClick={() => !disabled && onChange(name, val)}
                >
                    {t(label) as string}
                </div>
            ))}
        </SubSubMenuFilterSC>
    );
};

export default SubSubMenuFilter;
