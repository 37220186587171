import React, { MouseEvent, ReactElement, ChangeEvent } from 'react';
import classNames from 'classnames';
import Input from 'components/forms/Input';
import Icon from 'components/icon/Icon';
import CancelIcon from 'components/icon/icons/cancel.svg';
import styles from './Search.module.scss';

interface Props {
    placeholder: string;
    name: string;
    onChange: (name: string, value: string) => void;
    toggleAdvancedCb: () => void;
    onClear?: (e: MouseEvent<HTMLButtonElement>) => void;
    value: string;
    isModalView?: boolean;
}

const Search = ({
    placeholder,
    name,
    value,
    onChange,
    onClear,
    isModalView,
}: Props): ReactElement => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(name, e.target.value);
    };

    return (
        <div
            className={classNames(styles.root, {
                [styles.modalView]: isModalView,
            })}
        >
            <div className={styles.searchBar}>
                <div className={styles.inputWrap}>
                    <Input
                        name={name}
                        className={styles.input}
                        placeholder={placeholder}
                        value={value ? value : ''}
                        iconClass={styles.icon}
                        onChange={handleChange}
                        autocomplete="false"
                        withSearchButton
                    />
                    {!!value && (
                        <span className={styles.iconWrap} onClick={onClear}>
                            <Icon
                                icon={CancelIcon}
                                className={styles.cancelIcon}
                            />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Search;
