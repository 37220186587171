import React, { ReactElement, VFC } from 'react';
import classNames from 'classnames';
import { removeHiddenAndDisabledFields } from './utils/helpers';
import {
    GroupEditFieldInterface,
    StateInterface,
} from 'components/interfaces/GeneralInterface';
import { useTranslation } from 'utils/localization';
import { FieldTypeApiEnum } from 'submodules/api_middleware';
import {
    OnActionType,
    OnCancelType,
    OnGoBackType,
    OnSubmitData,
} from 'components/interfaces/EditFormInterface';
import Button from 'components/forms/Button';
import styles from './EditForm.module.scss';

interface Props {
    centerButtons: boolean;
    withCancelChangesBtn: boolean;
    isLoading: boolean;
    setLoading: (loading: boolean) => void;
    goBack: OnGoBackType;
    cancelButtonTextKey: string;
    buttonTextKey: string;
    submit: OnSubmitData;
    action: OnActionType;
    cancel: OnCancelType;
    fields: GroupEditFieldInterface[];
    inActiveFields: string[];
    filterValues: StateInterface;
    buttonsDisabled: boolean;
    actionButtonExists: boolean;
    requiredFieldsNotFilled: boolean;
    onSubmit: () => Promise<void>;
    unfilledRequiredFields: {
        name: string;
        label: string;
        type: FieldTypeApiEnum;
    }[];
    globalValidationFailed: string;
    error: string;
}

const EditFormActions: VFC<Props> = ({
    centerButtons,
    withCancelChangesBtn,
    isLoading,
    setLoading,
    cancelButtonTextKey,
    buttonTextKey,
    goBack,
    submit,
    action,
    cancel,
    fields,
    inActiveFields,
    filterValues,
    buttonsDisabled,
    requiredFieldsNotFilled,
    onSubmit,
    unfilledRequiredFields,
    globalValidationFailed,
    actionButtonExists,
    error,
}): ReactElement => {
    const { t } = useTranslation();

    const onCancel = async () => {
        setLoading(true);
        if (cancel) {
            return cancel(
                removeHiddenAndDisabledFields(
                    filterValues,
                    fields,
                    inActiveFields
                )
            );
        }
        setLoading(false);
    };

    const goBackLocal = () =>
        goBack(
            removeHiddenAndDisabledFields(filterValues, fields, inActiveFields)
        );

    return (
        <>
            <div
                className={classNames(styles.buttonWrapper, {
                    [styles.center]: !!centerButtons,
                    [styles.hidden]: !(
                        !!goBack ||
                        !!withCancelChangesBtn ||
                        !!submit ||
                        !!action
                    ),
                })}
            >
                {!!goBack && (
                    <Button
                        isTransparent
                        resolveImmediately
                        onClick={goBackLocal}
                        isDisabled={isLoading}
                    >
                        {t('general.back').toString()}
                    </Button>
                )}
                {!!withCancelChangesBtn && (
                    <Button
                        isTransparent
                        onClick={onCancel}
                        isDisabled={isLoading}
                        resolveImmediately
                    >
                        {t(cancelButtonTextKey).toString()}
                    </Button>
                )}
                {actionButtonExists && (
                    <Button
                        isDisabled={buttonsDisabled}
                        isLoading={isLoading}
                        onClick={onSubmit}
                        disabledReason={
                            requiredFieldsNotFilled
                                ? `${t('general.missing')}: ${t(
                                      unfilledRequiredFields[0].label
                                  )}`
                                : globalValidationFailed
                                ? globalValidationFailed
                                : undefined
                        }
                    >
                        {t(buttonTextKey).toString()}
                    </Button>
                )}
            </div>
            <div
                className={classNames(styles.error, {
                    [styles.center]: !!centerButtons,
                })}
            >
                {error && error}
            </div>
        </>
    );
};

export default EditFormActions;
