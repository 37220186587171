import styled from 'styled-components';
import { colors, shadow, fontWeights } from 'constants/constants.styled';

export default styled.div`
    position: relative;
    display: flex;
    width: fit-content;
    background-color: ${colors.white};
    border-radius: 2rem;
    box-shadow: ${shadow.table};
    cursor: pointer;

    &.disabled {
        background-color: ${colors.inputDisabled};
        cursor: default;

        .item {
            background-color: ${colors.inputDisabled};

            &:hover:not(.active) {
                color: unset;
                background-color: unset;
            }

            &:hover:not(.active)::before {
                position: unset;
                top: 0;
                left: 0;
                width: 0;
                height: 0;
            }
        }
    }

    .item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 1rem 2rem;
        color: ${colors.main};
        font-weight: ${fontWeights.normal};

        &:first-child {
            border-top-left-radius: 2rem;
            border-bottom-left-radius: 2rem;
        }

        &:last-child {
            border-top-right-radius: 2rem;
            border-bottom-right-radius: 2rem;
        }

        &.active {
            color: ${colors.white};
            background-color: ${colors.secondary};
            border-radius: 2rem;
        }

        &:hover:not(.active) {
            color: ${colors.secondaryHover};
            &::before {
                position: absolute;
                top: 0;
                left: 2rem;
                width: calc(100% - 4rem);
                height: 2px;
                background-color: var(--colorSecondary);
                content: ' ';
            }
        }
    }
`;
