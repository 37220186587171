import React, { ReactElement, VFC } from 'react';
import classNames from 'classnames';
import EditFormField from './EditFormField';
import {
    EditFieldInterfaceOnRemoveType,
    GroupEditFieldInterface,
    StateInterface,
} from 'components/interfaces/GeneralInterface';
import { H3 } from 'components/typography';
import { useTranslation } from 'utils/localization';
import {
    EditFormOnFieldChangeType,
    FileStateInterface,
    OnButtonClickType,
    OnChangeFileType,
    RequiredFieldsType,
} from 'components/interfaces/EditFormInterface';
import styles from './EditForm.module.scss';

interface Props {
    groupFields: GroupEditFieldInterface[];
    initialValues: StateInterface;
    filterValues: StateInterface;
    fieldNameChanged: StateInterface;
    valuesReactDom: StateInterface;
    filterErrors: StateInterface;
    noSectionPadding: boolean;
    titleCenter: boolean;
    center: boolean;
    isLoading: boolean;
    fieldsWithBorder: boolean;
    removed: string[];
    getOnButtonClick: OnButtonClickType;
    requiredFields: RequiredFieldsType;
    remove: (name: string, onRemove: EditFieldInterfaceOnRemoveType) => void;
    files: FileStateInterface;
    getOnChange: EditFormOnFieldChangeType;
    setDisabledByImageUploader: React.Dispatch<React.SetStateAction<boolean>>;
    getOnChangeFile: OnChangeFileType;
    buttonsDisabled: boolean;
    actionButtonExists: boolean;
    onSubmit: () => Promise<void>;
}

const EditFormGroupFields: VFC<Props> = ({
    groupFields,
    filterValues,
    noSectionPadding,
    titleCenter,
    center,
    fieldNameChanged,
    initialValues,
    valuesReactDom,
    filterErrors,
    isLoading,
    fieldsWithBorder,
    remove,
    removed,
    files,
    requiredFields,
    getOnChange,
    setDisabledByImageUploader,
    getOnChangeFile,
    getOnButtonClick,
    buttonsDisabled,
    actionButtonExists,
    onSubmit,
}): ReactElement => {
    const { t } = useTranslation();
    const mappedGroupFields = groupFields.map(
        ({ title, displaySectionIfSuccess, fields }, key) => {
            if (
                displaySectionIfSuccess &&
                !displaySectionIfSuccess(filterValues)
            ) {
                return null;
            }

            return (
                <div
                    className={classNames({
                        [styles.section]: !noSectionPadding,
                    })}
                    key={key}
                >
                    {title && (
                        <H3
                            className={classNames(styles.sectionTitle, {
                                [styles.textCenter]: !!titleCenter,
                            })}
                        >
                            {typeof title === 'string'
                                ? (t(title) as string)
                                : title}
                        </H3>
                    )}
                    <div
                        className={classNames(styles.filters, {
                            [styles.center]: !!center,
                        })}
                    >
                        {fields.map((field, index) => (
                            <EditFormField
                                key={`root-${
                                    field.label ||
                                    field.labelValue ||
                                    (field.getLabel &&
                                        field.getLabel(filterValues))
                                }-${field.name}-${index}`}
                                field={field}
                                fieldNameChanged={fieldNameChanged}
                                initialValues={initialValues}
                                filterValues={filterValues}
                                valuesReactDom={valuesReactDom}
                                filterErrors={filterErrors}
                                isLoading={isLoading}
                                fieldsWithBorder={fieldsWithBorder}
                                removed={removed}
                                requiredFields={requiredFields}
                                remove={remove}
                                files={files}
                                getOnChange={getOnChange}
                                setDisabledByImageUploader={
                                    setDisabledByImageUploader
                                }
                                getOnChangeFile={getOnChangeFile}
                                getOnButtonClick={getOnButtonClick}
                                buttonsDisabled={buttonsDisabled}
                                actionButtonExists={actionButtonExists}
                                onSubmit={onSubmit}
                            />
                        ))}
                    </div>
                </div>
            );
        }
    );

    return <div>{mappedGroupFields}</div>;
};

export default EditFormGroupFields;
