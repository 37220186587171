import React, {
    ChangeEvent,
    CSSProperties,
    KeyboardEvent,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Datepicker, { registerLocale } from 'react-datepicker';
import { cs, de, enGB, es, fr } from 'date-fns/locale';
import Icon from 'components/icon/Icon';
import Calendar from 'components/icon/icons/calendar.svg';
import { useTranslation } from 'utils/localization';
import { DatePatternEnum, dateToIso } from 'utils/helpers/datetime';
import { GlobalContext } from 'pages/_app';
import stylesInner from './DatePickerInner.module.scss';
import styles from './DatePicker.module.scss';

interface Props {
    name?: string;
    value?: string | Date;
    placeholder?: string;
    className?: string;
    bigger?: boolean;
    onChange?: (date: string) => void;
    onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
    autocomplete?: string;
    whiteBackground?: boolean;
    withBorder?: boolean;
    withBottomBorder?: boolean;
    onClick?: () => void;
    highlighted?: boolean;
    min?: Date;
    max?: Date;
    errorMessage?: string;
    style?: CSSProperties;
    attributes?: { [key: string]: string };
    disabled?: boolean;
    usePopperContainer?: boolean;
    showTime?: boolean;
    showOnlyTime?: boolean;
}

interface PopperInterface {
    children: ReactElement;
}

registerLocale('cs', cs);
registerLocale('en', enGB);
registerLocale('es', es);
registerLocale('fr', fr);
registerLocale('de', de);

const getDatePickerValue = (
    value: string | Date,
    pattern: DatePatternEnum
): Date => {
    if (!value) {
        return undefined;
    }

    if (value instanceof Date) return value;

    if (pattern === DatePatternEnum.time) {
        if (/^\d.:\d./.test(value)) {
            const time = value.split(':');
            const date = new Date();
            date.setHours(Number(time[0]));
            date.setMinutes(Number(time[1]));
            return date;
        }
    }

    return new Date(value);
};

/**
 * Enumeration representing the state of datepicker input.
 */
enum DatePickerInputState {
    /**
     * Datepicker is readonly, not writable.
     */
    READONLY,
    /**
     * Date can be picked from datepicker but not written in.
     */
    PICK,
    /**
     * Date can be written in the date picker.
     */
    TYPE,
}

const DatePicker = ({
    name,
    placeholder,
    className,
    bigger,
    onChange,
    onKeyDown,
    value,
    disabled,
    whiteBackground,
    withBorder,
    onClick,
    onBlur,
    highlighted,
    min,
    max,
    style,
    errorMessage,
    withBottomBorder,
    attributes = {},
    usePopperContainer,
    showTime,
    showOnlyTime,
}: Props): ReactElement => {
    const { lang } = useContext(GlobalContext);
    const { t } = useTranslation();
    const [placeholderLocal, setPlaceholderLocal] = useState(placeholder);
    const [inputState, setInputState] = useState<DatePickerInputState>(
        DatePickerInputState.READONLY
    );
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const pattern = showOnlyTime
        ? DatePatternEnum.time
        : showTime
        ? DatePatternEnum.dateTime
        : DatePatternEnum.date;

    const datePickerValue = getDatePickerValue(value, pattern);

    useEffect(() => {
        if (!placeholder) {
            if (showOnlyTime) {
                setPlaceholderLocal(t('system.placeholderTime'));
            } else if (showTime) {
                setPlaceholderLocal(t('system.placeholderDateTime'));
            } else {
                setPlaceholderLocal(t('system.placeholderDate'));
            }
        }
    }, [placeholder]);

    const PopperContainer = ({ children }: PopperInterface) => {
        if (process.browser) {
            return ReactDOM.createPortal(
                children,
                document.getElementById('datepicker-popper')
            );
        } else {
            return null;
        }
    };

    return (
        <div className={classNames(styles.wrapper, stylesInner.wrapper)}>
            <div
                className={classNames(styles.root, className, {
                    [styles.withBottomBorder]: !!withBottomBorder,
                    [styles.highlighted]: highlighted,
                    [styles.whiteBackground]: whiteBackground,
                })}
                onClick={onClick}
                style={style}
            >
                <div className={styles.datepicker}>
                    <Datepicker
                        selected={datePickerValue}
                        onChange={(date: Date) => {
                            onChange(date ? dateToIso(date, pattern) : '');
                        }}
                        onSelect={() => {
                            setDatePickerOpen(false);
                            setInputState(DatePickerInputState.READONLY);
                        }}
                        onInputClick={() => {
                            switch (inputState) {
                                case DatePickerInputState.READONLY:
                                    setInputState(DatePickerInputState.PICK);
                                    setDatePickerOpen(true);
                                    break;
                                case DatePickerInputState.PICK:
                                    setInputState(DatePickerInputState.TYPE);
                                    setDatePickerOpen(false);
                                    break;
                            }
                        }}
                        onClickOutside={() => {
                            setDatePickerOpen(false);
                        }}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect={showOnlyTime || showTime}
                        showTimeSelectOnly={showOnlyTime}
                        timeIntervals={15}
                        timeCaption={t('general.time')}
                        dropdownMode="select"
                        timeFormat={t('system.pattern.time')}
                        dateFormat={t(`system.pattern.${pattern}`)}
                        locale={lang}
                        name={name}
                        open={datePickerOpen}
                        readOnly={inputState != DatePickerInputState.TYPE}
                        className={classNames(styles.input, {
                            [styles.bigger]: bigger,
                            [styles.noPadding]: whiteBackground,
                            [styles.withBorder]: withBorder,
                        })}
                        placeholderText={placeholderLocal}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        disabled={disabled}
                        minDate={min as Date}
                        maxDate={max as Date}
                        {...attributes}
                        popperContainer={
                            usePopperContainer
                                ? (PopperContainer as any)
                                : undefined
                        }
                        autoComplete={'off'}
                    />
                </div>
                <div
                    className={styles.calendarIcon}
                    onClick={() => {
                        setDatePickerOpen(true);
                        setInputState(DatePickerInputState.PICK);
                    }}
                >
                    <Icon icon={Calendar} noStrip />
                </div>
            </div>
            {!!errorMessage && (
                <div className={styles.error}>{errorMessage}</div>
            )}
        </div>
    );
};

export default DatePicker;
