import React, { ReactElement } from 'react';
import classNames from 'classnames';
import CloseIcon from './CloseIcon';
import { MultipleOptionInterface } from './MultiSelect';
import styles from './MultiSelect.module.scss';

interface Props {
    value: MultipleOptionInterface;
    deleteAction: () => void;
}

const Chip = ({ value, deleteAction }: Props): ReactElement => {
    const showChipText = (opt: MultipleOptionInterface) => {
        if (typeof opt.label === 'object') {
            return opt?.title || opt.value;
        } else {
            return opt.label;
        }
    };

    return (
        <div data-clickable="true" className={styles['msl-chip']}>
            {showChipText(value)}
            <div
                role="button"
                data-clickable="true"
                aria-label="delete-value"
                onClick={deleteAction}
                onKeyPress={deleteAction}
                tabIndex={0}
                className={classNames(
                    styles['msl-btn'],
                    styles['msl-chip-delete'],
                    styles['msl-flx']
                )}
            >
                <CloseIcon />
            </div>
            <span />
        </div>
    );
};

export default Chip;
