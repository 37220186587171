import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { useTranslation } from 'utils/localization';
import Table, { createTd, createTh } from 'components/tables/Table';
import {
    ColumnColor,
    ColumnHeadInterface,
    ColumnSize,
    ColumnValueWeight,
    FilterFieldInterface,
    FiltersInterface,
} from 'components/interfaces/GeneralInterface';
import { getMainFilter } from 'components/tables/filters/Filters';
import { PaginatorPageInterface } from 'components/interfaces/Paginator';
import Modal from 'components/modal/Modal';
import { MultipleSelectTableColumnInterface } from 'components/interfaces/MultipleSelectTableInterface';
import Loading from 'components/loading/Loading';
import styles from './MultipleSelectTableModal.module.scss';

interface Props<ModelType> {
    show: boolean;
    onHide: () => void;
    values: number[];
    searchTable: MultipleSelectTableColumnInterface<ModelType>[];
    searchPlaceholder: string;
    advancedFilters: FilterFieldInterface[];
    onSearch: (query: NextApiRequestQuery) => Promise<any>;
    initValuesCb?: (value: string | string[]) => Promise<any>;
    onSelect: (value: ModelType) => void;
    onRemove: (value: ModelType) => void;
    selectIdentifier: (obj: ModelType) => unknown;
    title: string;
}

interface DataInterface<ModelType> extends PaginatorPageInterface {
    data: ModelType[];
}

const MultipleSelectTableModal = <ModelType,>({
    show,
    onHide,
    searchTable,
    searchPlaceholder,
    advancedFilters,
    onSearch,
    initValuesCb,
    onSelect,
    onRemove,
    values,
    selectIdentifier,
    title,
}: Props<ModelType>): ReactElement => {
    const { t } = useTranslation();
    const [data, setData] = useState<DataInterface<ModelType>>();
    const [loading, setLoading] = useState<boolean>(true);

    const initData = async () => {
        if (initValuesCb) {
            const result = await initValuesCb(
                values ? values.map((v) => v + '') : undefined
            );
            if (result.data?.data) {
                setData({
                    data: result.data.data,
                    paginator: result.data.meta,
                });
            } else {
                setData({
                    data: [],
                    paginator: undefined,
                });
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        // init data
        initData();
    }, []);

    const searchHandler = (query: NextApiRequestQuery) => {
        if (onSearch) {
            onSearch(query).then((result) =>
                setData({
                    data: result.data.data,
                    paginator: result.data.meta,
                })
            );
        }
    };

    const tableColumns: ColumnHeadInterface[] = useMemo<ColumnHeadInterface[]>(
        () => [
            ...searchTable.map((tableColumn) => tableColumn.column),
            createTh(t, undefined, ColumnSize.SMFIX, undefined, true),
        ],
        [searchTable]
    );

    const tableData =
        data?.data?.map((toAdd) => {
            const isSelected =
                values && values.includes(selectIdentifier(toAdd) as any);
            return [
                ...searchTable.map((tableColumn) => tableColumn.data(toAdd)),
                createTd(
                    {
                        linkLabel: isSelected
                            ? t('actions.remove')
                            : t('actions.add'),
                        onClick: isSelected
                            ? () => onRemove(toAdd)
                            : () => onSelect(toAdd),
                    },
                    ColumnValueWeight.BOLD,
                    isSelected ? ColumnColor.RED : ColumnColor.BLUE
                ),
            ];
        }) || [];

    const filterConfig: FiltersInterface = {
        fixed: {
            mainField: getMainFilter(
                searchPlaceholder
                    ? t(searchPlaceholder)
                    : 'general.searchPlaceholder',
                'query'
            ),
            showAdvancedBtn: false,
            onSearch: searchHandler,
        },
        advanced: advancedFilters,
    };

    return (
        <Modal
            showCross
            show={show}
            onHide={onHide}
            title={`${t('actions.add')} - ${title}`}
            isTitleTranslated={true}
        >
            {(loading && (
                <div className={styles.wrapper}>
                    <Loading loadingText={t('general.loadingData')} />
                </div>
            )) || (
                <Table
                    filterConfig={filterConfig}
                    columns={tableColumns}
                    data={tableData}
                    paginator={data?.paginator}
                    isModalView
                    actionButtons={{
                        onAction: onHide,
                        onActionLabelKey: 'actions.add',
                    }}
                />
            )}
        </Modal>
    );
};

export default MultipleSelectTableModal;
