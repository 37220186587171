import React, { ReactElement } from 'react';
import { useTranslation } from 'utils/localization';
import Icon from 'components/icon/Icon';
import Cancel from 'components/icon/icons/cancel.svg';
import styles from './ClearFilters.module.scss';

interface Props {
    clearFilters: () => void;
}

const ClearFilters = ({ clearFilters }: Props): ReactElement => {
    const { t } = useTranslation();

    return (
        <div
            className={styles.clearFilter}
            onClick={clearFilters}
            id="test_clearFilters"
        >
            <div className={styles.iconWrap}>
                <Icon icon={Cancel} className={styles.cancelIcon} />
            </div>
            <span>{t('filters.removeFilters')}</span>
        </div>
    );
};

export default ClearFilters;
