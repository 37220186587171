import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import React, {
    ReactElement,
    useState,
    useEffect,
    FormEvent,
    Dispatch,
    SetStateAction,
    useRef,
} from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Filter from './Filter';
import FilterInput from './FilterInput';
import ClearFilters from './ClearFilters';
import Search from './Search';
import { useTranslation } from 'utils/localization';
import {
    FilterFieldInterface,
    FilterMainFieldInterface,
    FiltersInterface,
    FilterSize,
    LabelSize,
    StateInterface,
    ValidateValueType,
} from 'components/interfaces/GeneralInterface';
import Button from 'components/forms/Button';
import { isDateInValidRange, isValidDate } from 'utils/helpers/datetime';
import { FieldTypeApiEnum } from 'submodules/api_middleware';
import { debounce } from 'utils/helpers/helpers';
import {
    FILTER_PREFIX,
    removePrefixFromQuery,
    removePrefixQueryKey,
    deleteUnusedQuery,
    SORT_KEY,
    getPureQuery,
} from 'submodules/api_middleware/src/client';
import styles from './Filters.module.scss';

interface Props {
    query?: any;
    filterValues: StateInterface;
    setFilterValuesInner: (filters: StateInterface) => void;
    filters?: FiltersInterface;
    children: React.ReactChild;
    setLoadingAction: Dispatch<SetStateAction<boolean>>;
    searchQueryKey?: string;
    isModalView?: boolean;
}

export const getMainFilter = (
    placeholder: string,
    name?: string
): FilterMainFieldInterface => ({
    placeholder,
    name: name || 'query',
});

export const getInitFilterData = (
    advanced: FilterFieldInterface[],
    query: NextApiRequestQuery,
    withPrefix?: boolean
): StateInterface => {
    const res =
        (advanced &&
            advanced
                .filter((adv) => adv.initValue)
                .reduce((result: Record<string, any>, adv) => {
                    result[withPrefix ? FILTER_PREFIX + adv.name : adv.name] =
                        adv.initValue;
                    return result;
                }, {})) ||
        {};

    const newQuery = Object.keys(query).reduce(
        (result: NextApiRequestQuery, key) => {
            if (
                ![
                    'discipline',
                    'message',
                    'message_title',
                    'message_type',
                    'translated',
                ].includes(key) &&
                !(
                    key.endsWith('[]') &&
                    Array.isArray(query[`${key}`]) &&
                    query[`${key}`].length === 1 &&
                    query[`${key}`][0] === ''
                )
            ) {
                Object.assign(result, {
                    [key]: query[`${key}`],
                });
            }

            return result;
        },
        {}
    );

    return { ...res, ...newQuery };
};

const onChangeDebounce = (
    filterValues: StateInterface,
    setFilterValues: (obj: { [key: string]: any }) => void,
    resubmit: React.MutableRefObject<boolean>
) => {
    const debounced = debounce(350, async () => {
        resubmit.current = true;
        setFilterValues(filterValues);
    });
    debounced();
};

const Filters = ({
    children,
    filters,
    filterValues,
    setFilterValuesInner,
    setLoadingAction,
    searchQueryKey,
    isModalView,
}: Props): ReactElement => {
    if (!filters || (!filters.fixed && filters.advanced?.length === 0))
        return <>{children}</>;

    const { fixed, advanced = [], showSearchBtn, hideClearBtn } = filters;
    const { t } = useTranslation();
    const Router = useRouter();
    const resubmit = useRef(false);
    const initQuery = fixed?.initQuery;

    const [fieldNameChanged, setFieldNameChanged] = useState<StateInterface>(
        {}
    );

    useEffect(() => {
        if (advanced && initQuery) {
            const initFilterData = advanced.reduce(
                (result: Record<string, any>, filter) => {
                    const prefixFilterName = FILTER_PREFIX + filter.name;
                    if (initQuery[filter.name]) {
                        result[`${prefixFilterName}`] =
                            initQuery[`${filter.name}`];
                    }
                    return result;
                },
                {}
            );

            setFilterValues({ ...initFilterData, ...filterValues });
        }
    }, []);

    useEffect(() => {
        if (resubmit.current) {
            resubmit.current = false;
            handleSubmit();
        }
    }, [filterValues]);

    const setFilterValues = (obj: { [key: string]: any }) => {
        setFilterValuesInner(obj);
        filters.onChangeFilters && filters.onChangeFilters(getPureQuery(obj));
    };

    const anyFilterValueSelected =
        Object.keys(filterValues).filter(
            (key) => key !== 'discipline' && !!filterValues[`${key}`]
        ).length > 0;

    const anyFilterChanged =
        Object.keys(filterValues).filter((key) => {
            const filter = advanced.find(
                (filter) => filter.name === removePrefixQueryKey(key)
            );
            return (
                !!filter &&
                !!filterValues[`${key}`] &&
                !(
                    initQuery &&
                    filter &&
                    initQuery[`${key}`] === filterValues[`${key}`]
                )
            );
        }).length > 0;
    const anyFilterExists = advanced.length > 0;

    const [isShownAdvanced, setShownAdvanced] = useState(
        anyFilterValueSelected
    );
    const handleToggleFilter = () => setShownAdvanced(!isShownAdvanced);

    const clearFilters = () => {
        const filters = Object.keys(filterValues).reduce(
            (obj: NextApiRequestQuery, key) => {
                obj[`${key}`] = '';
                return obj;
            },
            {}
        );

        setFilterValues(filters);
        resubmit.current = true;
    };

    const onChange = (name: string, value: ValidateValueType) => {
        setFieldNameChanged({ [name]: value });
        setFilterValues({
            ...filterValues,
            [FILTER_PREFIX + removePrefixQueryKey(name)]: value,
        });
    };

    let fixedPart;
    const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        delete filterValues.page;
        delete filterValues[`${SORT_KEY}`];
        if (filters.fixed && filters.fixed.onSearch) {
            setLoadingAction(true);
            filters.fixed.onSearch({ ...filterValues, page: undefined });
        } else {
            setLoadingAction(true);
            await Router.replace(
                {
                    pathname: Router.asPath.split('?')[0],
                    query: deleteUnusedQuery(filterValues, ['page']),
                },
                undefined,
                { scroll: false }
            );
        }
    };

    if (fixed?.mainField) {
        const { mainField, showAdvancedBtn } = fixed;
        const SEARCH_NAME = searchQueryKey ? searchQueryKey : 'search';
        const searchName = mainField.name ? mainField.name : SEARCH_NAME;

        const onClearSearch = () => {
            if (fixed.onSearch) {
                fixed.onSearch({
                    ...filterValues,
                    [FILTER_PREFIX + searchName]: '',
                });
                onChange(searchName, '');
            } else {
                onChange(searchName, '');
                resubmit.current = true;
            }
        };

        fixedPart = (
            <div className={styles.firstRow}>
                {!!mainField && (
                    <>
                        <div className={styles.searchBar}>
                            <Search
                                placeholder={t(mainField.placeholder)}
                                onChange={onChange}
                                value={filterValues[FILTER_PREFIX + searchName]}
                                name={searchName}
                                toggleAdvancedCb={handleToggleFilter}
                                onClear={onClearSearch}
                                isModalView={isModalView}
                            />
                        </div>
                        {fixed.contextMenuElement}
                    </>
                )}
                {anyFilterExists && showAdvancedBtn && (
                    <div
                        className={styles.toggleFilter}
                        onClick={handleToggleFilter}
                    >
                        {t('filters.advancedFilterSearch') + ' '}
                        <span
                            className={classNames(styles.toggleIcon, {
                                [styles.open]:
                                    isShownAdvanced || !showAdvancedBtn,
                            })}
                        />
                    </div>
                )}
            </div>
        );
    }

    const fieldInputOnChange = (
        name: string,
        value: ValidateValueType,
        type: FieldTypeApiEnum
    ) => {
        if (type === FieldTypeApiEnum.CHECKBOX && !value) {
            value = '';
        }
        if (
            [
                FieldTypeApiEnum.AUTOCOMPLETE,
                FieldTypeApiEnum.SELECT,
                FieldTypeApiEnum.SELECT_MULTIPLE,
                FieldTypeApiEnum.BREAK,
                FieldTypeApiEnum.CHECKBOX,
                FieldTypeApiEnum.SUBSUBMENU,
            ].includes(type) ||
            (type === FieldTypeApiEnum.DATE &&
                isValidDate(value as string) &&
                isDateInValidRange(value.toString()))
        ) {
            onChangeDebounce(
                { ...filterValues, [FILTER_PREFIX + name]: value },
                setFilterValues,
                resubmit
            );
        }

        onChange(name, value);
    };

    const fieldInputOnBlur = (_: string, value: ValidateValueType) => {
        if (value && value !== '') {
            resubmit.current = true;
        }
    };

    const onNumberArrowClick = () => {
        resubmit.current = true;
    };

    return (
        <>
            <div className={styles.root}>
                <form onSubmit={handleSubmit}>
                    {fixedPart}
                    <div
                        className={classNames(
                            styles.filters,
                            fixed?.advancedFiltersWrapClass,
                            {
                                [styles.hidden]:
                                    (!isShownAdvanced &&
                                        fixed?.showAdvancedBtn) ||
                                    !anyFilterExists,
                            }
                        )}
                    >
                        {advanced.map(
                            (
                                {
                                    size,
                                    label,
                                    name,
                                    placeholder,
                                    type,
                                    min,
                                    max,
                                    values: staticValues,
                                    onSearch,
                                    onSearchProperty,
                                    initValuesCb,
                                    noEmptyOption,
                                    firstEmptyOption,
                                    withoutSort,
                                    disabledIfSuccess,
                                    reInitOnFields,
                                },
                                index
                            ) => {
                                const labelSize = LabelSize.TOP;
                                const labelTranslated = label
                                    ? t(label)
                                    : undefined;
                                const placeholderTranslated = placeholder
                                    ? t(placeholder)
                                    : undefined;
                                const minVal =
                                    typeof min === 'function'
                                        ? min(
                                              removePrefixFromQuery(
                                                  filterValues
                                              )
                                          )
                                        : min;

                                const maxVal =
                                    typeof max === 'function'
                                        ? max(
                                              removePrefixFromQuery(
                                                  filterValues
                                              )
                                          )
                                        : max;
                                return type === FieldTypeApiEnum.BREAK ? (
                                    <div
                                        className={styles.break}
                                        key={
                                            name +
                                            '-' +
                                            labelTranslated +
                                            '-break-' +
                                            index
                                        }
                                    />
                                ) : type === FieldTypeApiEnum.LABEL ? (
                                    <div
                                        className={classNames(
                                            styles.onePerRow,
                                            styles.marginTop2
                                        )}
                                        key={
                                            name +
                                            '-' +
                                            labelTranslated +
                                            '-label-' +
                                            index
                                        }
                                    >
                                        {labelTranslated}
                                    </div>
                                ) : (
                                    <div
                                        className={classNames(
                                            styles.filterGroup,
                                            {
                                                [styles.onePerRow]:
                                                    size === FilterSize.FULL,
                                                [styles.twoPerRow]:
                                                    size === FilterSize.HALF,
                                                [styles.threePerRow]:
                                                    size === FilterSize.THIRD,
                                                [styles.fourPerRow]:
                                                    size === FilterSize.FOURTH,
                                            }
                                        )}
                                        key={
                                            'root-' +
                                            name +
                                            '-' +
                                            labelTranslated
                                        }
                                    >
                                        <Filter
                                            size={size}
                                            label={labelTranslated}
                                            type={type}
                                            labelSize={labelSize}
                                            name={name}
                                            isModalView={isModalView}
                                            onCheckBoxAction={
                                                type ===
                                                FieldTypeApiEnum.CHECKBOX
                                                    ? (name, value) =>
                                                          fieldInputOnChange(
                                                              name,
                                                              value,
                                                              type
                                                          )
                                                    : undefined
                                            }
                                            filterValue={
                                                filterValues[
                                                    FILTER_PREFIX + name
                                                ]
                                            }
                                        >
                                            <FilterInput
                                                disabled={
                                                    disabledIfSuccess
                                                        ? disabledIfSuccess(
                                                              removePrefixFromQuery(
                                                                  filterValues
                                                              )
                                                          )
                                                        : false
                                                }
                                                onChange={(name, value) =>
                                                    fieldInputOnChange(
                                                        name,
                                                        value,
                                                        type
                                                    )
                                                }
                                                onBlur={
                                                    [
                                                        FieldTypeApiEnum.NUMBER,
                                                        FieldTypeApiEnum.TEXT,
                                                    ].includes(type)
                                                        ? (name, value) =>
                                                              fieldInputOnBlur(
                                                                  name,
                                                                  value
                                                              )
                                                        : undefined
                                                }
                                                type={type}
                                                filterValue={
                                                    filterValues[
                                                        FILTER_PREFIX + name
                                                    ]
                                                }
                                                placeholder={
                                                    placeholderTranslated
                                                }
                                                withoutSort={withoutSort}
                                                name={name}
                                                min={minVal}
                                                max={maxVal}
                                                staticValues={staticValues}
                                                onSearch={
                                                    onSearch
                                                        ? (query) =>
                                                              onSearch(
                                                                  query,
                                                                  getPureQuery(
                                                                      filterValues
                                                                  )
                                                              )
                                                        : undefined
                                                }
                                                onSearchProperty={
                                                    onSearchProperty
                                                }
                                                initValuesCb={
                                                    initValuesCb
                                                        ? (value) =>
                                                              initValuesCb(
                                                                  value,
                                                                  getPureQuery(
                                                                      filterValues
                                                                  )
                                                              )
                                                        : undefined
                                                }
                                                fieldNameChanged={
                                                    fieldNameChanged
                                                }
                                                noEmptyOption={noEmptyOption}
                                                firstEmptyOption={
                                                    firstEmptyOption
                                                }
                                                reInitOnFields={reInitOnFields}
                                                onArrowClick={
                                                    onNumberArrowClick
                                                }
                                                snapToMin={true}
                                                snapToMax={true}
                                            />
                                        </Filter>
                                    </div>
                                );
                            }
                        )}
                        <div className={styles.filterActions}>
                            <div
                                className={
                                    showSearchBtn
                                        ? styles.bottomButtons
                                        : styles.hidden
                                }
                            >
                                <Button isSubmitType>
                                    {t('filters.search') as string}
                                </Button>
                            </div>
                            {!hideClearBtn && (
                                <div
                                    className={classNames({
                                        [styles.marignLeft]: !!showSearchBtn,
                                    })}
                                >
                                    {(!fixed || filters.advanced) &&
                                        anyFilterChanged && (
                                            <ClearFilters
                                                clearFilters={clearFilters}
                                            />
                                        )}
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
            {children}
        </>
    );
};

export default Filters;
