import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import ColumnContent from './ColumnContent';
import {
    ColumnHeadInterface,
    ColumnInputInterface,
    ColumnValueInterface,
    ColumnValueType,
    ColumnValueWeight,
} from 'components/interfaces/GeneralInterface';
import { getRoute } from 'constants/routes';
import { RoleApiEnum } from 'submodules/api_middleware';
import { RoleEnum, TNamespaceEnum } from 'types/types';
import { getTranslation } from 'utils/localization';
import styles from './Table.module.scss';

export interface ColumnProps {
    t: TFunction;
    colHead: ColumnHeadInterface;
    col: ColumnValueInterface;
    reactKey: string;
    input?: ColumnInputInterface;
}

interface TdProps extends ColumnProps {
    children: ReactElement;
    colSpan?: number;
}

interface LinkByRoleInterface {
    link: string;
    alt: string;
}

export const getLinkByRole = (
    role: string,
    id: string | number,
    t: TFunction
): LinkByRoleInterface => {
    switch (role) {
        case RoleEnum.coach:
        case RoleApiEnum['coach-football']:
        case RoleApiEnum['coach-futsal']:
            return {
                link: getRoute('/coaches/{0}', [id as string]),
                alt: getTranslation(t, TNamespaceEnum.person, 'role.coach'),
            };
        case RoleEnum.player:
        case RoleApiEnum['player-football']:
        case RoleApiEnum['player-futsal']:
            return {
                link: getRoute('/players/{0}', [id as string]),
                alt: getTranslation(t, TNamespaceEnum.person, 'role.player'),
            };
        case RoleEnum.referee:
        case RoleApiEnum['referee-football']:
        case RoleApiEnum['referee-futsal']:
            return {
                link: getRoute('/referees/{0}', [id as string]),
                alt: getTranslation(t, TNamespaceEnum.person, 'role.referee'),
            };
        case RoleEnum.delegate:
        case RoleApiEnum['delegate-football']:
        case RoleApiEnum['delegate-futsal']:
            return {
                link: getRoute('/delegates/{0}', [id as string]),
                alt: getTranslation(t, TNamespaceEnum.person, 'role.delegate'),
            };
        default:
            return {
                link: getRoute('/members/{0}', [id as string]),
                alt: getTranslation(
                    t,
                    TNamespaceEnum.person,
                    `role.${role.replace(/-football|-futsal/, '')}`
                ),
            };
    }
};

export const getClassNameByValueWeight = (
    valueWeight: ColumnValueWeight,
    value: ColumnValueType
): { [x: string]: boolean } => ({
    [styles.lightFirst]: valueWeight === ColumnValueWeight.LIGHT_FIRST,
    [styles.bold]: valueWeight === ColumnValueWeight.BOLD,
    [styles.boldFirst]: valueWeight === ColumnValueWeight.BOLD_FIRST,
    [styles.boldLast]: valueWeight === ColumnValueWeight.BOLD_LAST,
    [styles.greenLast]: valueWeight === ColumnValueWeight.GREEN_LAST,
    [styles.greenFirst]: valueWeight === ColumnValueWeight.GREEN_FIRST,
    [styles.gray]: valueWeight === ColumnValueWeight.GRAY,
    [styles.grayFirst]: valueWeight === ColumnValueWeight.GRAY_FIRST,
    [styles.grayLast]: valueWeight === ColumnValueWeight.GRAY_LAST,
    [styles.redFirst]: valueWeight === ColumnValueWeight.RED_FIRST,
    [styles.green]: valueWeight === ColumnValueWeight.GREEN,
    [styles.darkCircle]:
        valueWeight === ColumnValueWeight.DARK_CIRCLE && !!value,
    [styles.greenCircle]:
        valueWeight === ColumnValueWeight.GREEN_CIRCLE && !!value,
    [styles.yellowCircle]:
        valueWeight === ColumnValueWeight.YELLOW_CIRCLE && !!value,
    [styles.redCircle]: valueWeight === ColumnValueWeight.RED_CIRCLE && !!value,
    [styles.secondMoreAdditional]:
        valueWeight === ColumnValueWeight.SECOND_MORE_ADDITIONAL,
});

export const Td = ({
    col: { value, valueWeight, color },
    colHead: { size, isCentered },
    reactKey,
    children,
    colSpan,
}: TdProps): ReactElement => {
    return (
        <td
            className={classNames(
                styles.bodyCol,
                styles['colSize-' + size],
                {
                    [styles['colColor-' + color]]: !!color,
                    [styles.centerText]: isCentered,
                },
                getClassNameByValueWeight(valueWeight, value)
            )}
            key={reactKey}
            colSpan={colSpan}
        >
            {children}
        </td>
    );
};

const Column = (props: ColumnProps): ReactElement => (
    <Td {...props}>
        <ColumnContent {...props} />
    </Td>
);

export default Column;
