import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import {
    Option,
    DiacriticOption,
} from '../../../components/interfaces/GeneralInterface';
import { replaceDiacritics } from './string';
import { MemberPlayerListApiInterface } from 'submodules/api_middleware';
import { getPlayerList } from 'services/player.service';
import { PersonService } from 'services/person.service';

export const getDiacriticOptions = (options: Option[]): DiacriticOption[] => {
    const diacriticOptions: DiacriticOption[] = [];
    options?.forEach((o: Option) => {
        diacriticOptions.push({
            value: o.value,
            valueNoDiacritics: replaceDiacritics(o.value + '').toLowerCase(),
            label: o.label,
            labelNoDiacritics: replaceDiacritics(o.label).toLowerCase(),
        });
    });

    return diacriticOptions;
};

export const parseArrayToOptions = <T>(
    array: T[],
    labelKey: keyof T,
    valueKey: keyof T
): Option[] => {
    return array.map((item) => ({
        label: String(item[labelKey]),
        value: String(item[valueKey]),
    }));
};

export const getPersonsOptions = async (
    query: NextApiRequestQuery
): Promise<Option[]> => {
    const response = await new PersonService().getPersonList(query);
    return response.data.map((person: MemberPlayerListApiInterface) => ({
        label: `${person.person.name} ${
            person.member ? '(' + person.member.internal_id + ')' : ''
        }`,
        value: person.person.id,
    }));
};

export const getPlayerOptions = async (
    query: NextApiRequestQuery
): Promise<Option[]> => {
    const response = await getPlayerList(query);
    return response.data.data.map((member: MemberPlayerListApiInterface) => ({
        label: `${member.person.name} (${member.member.internal_id})`,
        value: member.person.id,
    }));
};
