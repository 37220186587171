import React, { ReactElement, KeyboardEvent, VFC } from 'react';
import classNames from 'classnames';
import { getFieldNote } from './utils/helpers';
import {
    EditFieldInterface,
    EditFieldInterfaceOnRemoveType,
    FilterSize,
    StateInterface,
} from 'components/interfaces/GeneralInterface';
import SpecialInput, {
    isSpecialInput,
} from 'components/tables/filters/SpecialInput';
import { FieldTypeApiEnum } from 'submodules/api_middleware';
import Filter from 'components/tables/filters/Filter';
import { useTranslation } from 'utils/localization';
import FilterInput from 'components/tables/filters/FilterInput';
import {
    EditFormOnFieldChangeType,
    FileStateInterface,
    OnButtonClickType,
    OnChangeFileType,
    RequiredFieldsType,
} from 'components/interfaces/EditFormInterface';
import styles from './EditForm.module.scss';

interface Props {
    field: EditFieldInterface;
    initialValues: StateInterface;
    filterValues: StateInterface;
    fieldNameChanged: StateInterface;
    valuesReactDom: StateInterface;
    filterErrors: StateInterface;
    isLoading: boolean;
    fieldsWithBorder: boolean;
    removed: string[];
    getOnButtonClick: OnButtonClickType;
    requiredFields: RequiredFieldsType;
    remove: (name: string, onRemove: EditFieldInterfaceOnRemoveType) => void;
    files: FileStateInterface;
    getOnChange: EditFormOnFieldChangeType;
    setDisabledByImageUploader: React.Dispatch<React.SetStateAction<boolean>>;
    getOnChangeFile: OnChangeFileType;
    onSubmit: () => Promise<void>;
    buttonsDisabled: boolean;
    actionButtonExists: boolean;
}

const EditFormField: VFC<Props> = ({
    field: {
        size,
        label,
        getLabel,
        labelValue,
        name,
        labelSize,
        placeholder,
        type,
        min,
        max,
        values: staticValues,
        attributes,
        triggerAllValidations,
        onSearch,
        onSearchProperty,
        onChange: onInputChange,
        hideArrows,
        onButtonClick,
        buttonText,
        disabled,
        disabledValues,
        disabledIfSuccess,
        noEmptyOption,
        removable,
        onRemove,
        checkboxLabel,
        onePerRow,
        initValuesCb,
        keepSpaceOnHide,
        highlight,
        note,
        tooltip,
        className,
        acceptedFileTypes,
        usePopperContainer,
        textAreaHeight,
        tableSelect,
        withAdditionalModal,
        tableEntities,
        alwaysRerenderOptions,
        linkInBrackets,
        autoFocus,
        autocomplete,
        reInitOnFields,
        reInitWithValueOnFields,
        isDecimalNumber,
        infoType,
        minLength,
        maxLength,
        multipleSelectTable,
        displayIfSuccess,
    },
    filterValues,
    initialValues,
    valuesReactDom,
    getOnButtonClick,
    isLoading,
    remove,
    removed,
    getOnChange,
    requiredFields,
    fieldNameChanged,
    setDisabledByImageUploader,
    getOnChangeFile,
    files,
    filterErrors,
    fieldsWithBorder,
    onSubmit,
    buttonsDisabled,
    actionButtonExists,
}): ReactElement => {
    if (
        !(
            !removed.includes(name) &&
            (!displayIfSuccess ||
                displayIfSuccess(filterValues) ||
                (keepSpaceOnHide && keepSpaceOnHide(filterValues)))
        )
    ) {
        return null;
    }

    const { t } = useTranslation();

    const onKeyPress = (e: KeyboardEvent<HTMLElement>): void => {
        if (e.key === 'Enter') {
            e.preventDefault();

            !isLoading && !buttonsDisabled && actionButtonExists && onSubmit();
        }
    };

    const _isSpecialInput = isSpecialInput(type);

    let field;
    if (_isSpecialInput) {
        field = (
            <SpecialInput
                key={`special-input-master-${type}-${name}`}
                reactKey={`special-input-${type}-${name}`}
                type={type}
                label={label ? label : getLabel ? getLabel(filterValues) : ''}
                value={
                    type === FieldTypeApiEnum.REACT_DOM
                        ? valuesReactDom[`${name}`]
                        : filterValues[`${name}`]
                }
                buttonText={buttonText}
                biggerInput
                onButtonClick={
                    onButtonClick
                        ? () => getOnButtonClick(onButtonClick)
                        : undefined
                }
                disabled={
                    disabled ||
                    isLoading ||
                    (disabledIfSuccess && disabledIfSuccess(filterValues))
                }
                infoType={infoType}
            />
        );
    } else {
        field = (
            <Filter
                size={type === FieldTypeApiEnum.FILE ? FilterSize.FULL : size}
                linkInBrackets={linkInBrackets}
                label={
                    label ? t(label) : getLabel ? t(getLabel(filterValues)) : ''
                }
                type={type}
                labelSize={labelSize}
                showRequiredIndication
                isRequiredField={requiredFields.some(
                    (requiredField) => requiredField.name === name
                )}
                tooltip={tooltip}
                name={name}
                onRemove={
                    removable
                        ? () => {
                              remove(name, onRemove);
                          }
                        : undefined
                }
                key={label + '-' + name + 10 + 'filter'}
                hideContent={keepSpaceOnHide && keepSpaceOnHide(filterValues)}
                highlight={highlight}
                isEditForm
                onCheckBoxAction={
                    type === FieldTypeApiEnum.CHECKBOX
                        ? getOnChange(onInputChange, triggerAllValidations)
                        : undefined
                }
                filterValue={filterValues[`${name}`]}
                disabled={
                    disabled ||
                    isLoading ||
                    (disabledIfSuccess && disabledIfSuccess(filterValues))
                }
            >
                <>
                    <FilterInput
                        isEditForm
                        onChange={getOnChange(
                            onInputChange,
                            triggerAllValidations
                        )}
                        setDisabledByImageUploader={setDisabledByImageUploader}
                        onKeyPress={onKeyPress}
                        onChangeFile={getOnChangeFile}
                        className={classNames(styles.noShadow, className)}
                        type={type}
                        filterValue={
                            type === FieldTypeApiEnum.FILE
                                ? files[`${name}`]
                                : type !== FieldTypeApiEnum.LABEL
                                ? filterValues[`${name}`]
                                : labelValue
                        }
                        placeholder={t(placeholder)}
                        disabled={
                            disabled ||
                            isLoading ||
                            (disabledIfSuccess &&
                                disabledIfSuccess(filterValues))
                        }
                        disabledValues={
                            typeof disabledValues === 'function'
                                ? disabledValues(filterValues)
                                : disabledValues
                        }
                        onSearch={
                            onSearch
                                ? (value) => onSearch(value, filterValues)
                                : undefined
                        }
                        onSearchProperty={onSearchProperty}
                        name={name}
                        biggerInput
                        isDecimalNumber={isDecimalNumber}
                        min={
                            typeof min === 'function' ? min(filterValues) : min
                        }
                        max={
                            typeof max === 'function' ? max(filterValues) : max
                        }
                        minLength={minLength}
                        maxLength={maxLength}
                        staticValues={staticValues}
                        errorMessage={filterErrors[`${name}`]}
                        attributes={attributes}
                        whiteBackground={false}
                        hideArrows={hideArrows}
                        noEmptyOption={noEmptyOption}
                        initValuesCb={
                            initValuesCb
                                ? (value: string) =>
                                      initValuesCb(value, filterValues)
                                : undefined
                        }
                        withBorder={fieldsWithBorder}
                        acceptedFileTypes={acceptedFileTypes}
                        usePopperContainer={usePopperContainer}
                        checkboxLabel={checkboxLabel}
                        sizeFile={size}
                        textAreaHeight={textAreaHeight}
                        multipleSelectTable={
                            multipleSelectTable
                                ? {
                                      ...multipleSelectTable,
                                      initValuesCb:
                                          multipleSelectTable.initValuesCb
                                              ? (value) =>
                                                    multipleSelectTable.initValuesCb(
                                                        value,
                                                        filterValues
                                                    )
                                              : undefined,
                                      onSearch: multipleSelectTable.onSearch
                                          ? (value) =>
                                                multipleSelectTable.onSearch(
                                                    value,
                                                    filterValues
                                                )
                                          : undefined,
                                  }
                                : undefined
                        }
                        tableSelect={
                            tableSelect
                                ? {
                                      ...tableSelect,
                                      initValuesCb: tableSelect.initValuesCb
                                          ? (value) =>
                                                tableSelect.initValuesCb(
                                                    value,
                                                    filterValues
                                                )
                                          : undefined,
                                      onSearch: tableSelect.onSearch
                                          ? (value) =>
                                                tableSelect.onSearch(
                                                    value,
                                                    filterValues
                                                )
                                          : undefined,
                                  }
                                : undefined
                        }
                        tableEntities={tableEntities}
                        withAdditionalModal={withAdditionalModal}
                        alwaysRerenderOptions={alwaysRerenderOptions}
                        autoFocus={autoFocus}
                        autocomplete={autocomplete}
                        fieldNameChanged={fieldNameChanged}
                        reInitOnFields={reInitOnFields}
                        reInitWithValueOnFields={reInitWithValueOnFields}
                    />
                    {getFieldNote(note, name)}
                </>
            </Filter>
        );
    }

    return (
        <div
            className={classNames(styles.filterGroup, {
                [styles.noFlexDirection]: _isSpecialInput,
                [styles.changeInput]:
                    !initialValues ||
                    filterValues[`${name}`] !== initialValues[`${name}`],
                [styles.onePerRow]: onePerRow || size === FilterSize.FULL,
                [styles.twoPerRow]: size === FilterSize.HALF,
                [styles.threePerRow]: size === FilterSize.THIRD,
                [styles.fourPerRow]: size === FilterSize.FOURTH,
                [styles.fillRow]: size === FilterSize.FILL,
                [styles.threeQuarters]: size === FilterSize.THREE_QUARTERS,
            })}
        >
            {field}
        </div>
    );
};

export default EditFormField;
