import classNames from 'classnames';
import React, { ReactElement } from 'react';
import styles from './Label.module.scss';

interface Props {
    value: string;
    className: string;
}

const Label = ({ value, className }: Props): ReactElement => {
    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles.labelWrap}>
                <span>{value}</span>
            </div>
        </div>
    );
};

export default Label;
