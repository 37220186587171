import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { SortableHandle } from 'react-sortable-hoc';
import styles from '../Table.module.scss';
import { ColumnProps } from '../Column';
import ColumnContent from '../ColumnContent';
import Icon from 'components/icon/Icon';
import Burger from 'components/icon/icons/burger.svg';

const SortableColumnContent = (props: ColumnProps): ReactElement => {
    const {
        colHead: { isRowSortable },
    } = props;

    if (isRowSortable) {
        const DragHandle = SortableHandle(() => (
            <Icon
                icon={Burger}
                className={classNames(
                    styles.iconDetail,
                    styles.cursorGrab,
                    styles.burgerIcon
                )}
                noStrip
            />
        ));
        return <DragHandle />;
    }

    return <ColumnContent {...props} />;
};

export default SortableColumnContent;
