import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useDidUpdate = (
    fn: EffectCallback,
    inputs: DependencyList
): void => {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) fn();
        else didMountRef.current = true;
    }, inputs);
};
