import { useRef, useState, useEffect, MutableRefObject } from 'react';

interface Props {
    initialIsVisible: boolean;
    onClickOutside: () => void;
    clearInputValue: () => void;
    optionsRef: MutableRefObject<any>;
    noTab: boolean;
}

interface UseComponentVisibleInterface {
    ref: MutableRefObject<any>;
    isComponentVisible: boolean;
    setIsComponentVisible: (isVisible: boolean) => void;
}

const useComponentVisible = ({
    initialIsVisible,
    onClickOutside,
    optionsRef,
    clearInputValue,
    noTab,
}: Props): UseComponentVisibleInterface => {
    const [isComponentVisible, setIsComponentVisible] =
        useState<boolean>(initialIsVisible);
    const ref = useRef(null);

    const doNothing = (event: KeyboardEvent) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const handleDropdown = (event: KeyboardEvent) => {
        switch (event.key) {
            case 'Tab': {
                if (noTab) {
                    setIsComponentVisible(false);
                    onClickOutside();
                }
                break;
            }
            case 'Escape':
                {
                    setIsComponentVisible(false);
                    onClickOutside();
                }
                break;
            case 'ArrowDown': {
                doNothing(event);
                const active = document.activeElement;
                const isChild = active.hasAttribute('data-child');
                if (isChild && active.nextSibling) {
                    const htmlEl = active.nextSibling as HTMLElement;
                    htmlEl.focus();
                } else {
                    if (optionsRef?.current)
                        optionsRef.current.firstChild.focus();
                }
                break;
            }
            case 'ArrowUp': {
                doNothing(event);
                const active = document.activeElement;
                const isChild = active.hasAttribute('data-child');
                if (isChild && active.previousSibling) {
                    const htmlEl = active.previousSibling as HTMLElement;
                    htmlEl.focus();
                } else {
                    if (optionsRef?.current)
                        optionsRef.current.lastChild.focus();
                }
                break;
            }
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target)) {
            clearInputValue();
            document.removeEventListener('keydown', handleDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
            setIsComponentVisible(false);
            onClickOutside();
        }
    };

    useEffect(() => {
        if (isComponentVisible) {
            document.addEventListener('keydown', handleDropdown, true);
            document.addEventListener('click', handleClickOutside, true);
            return () => {
                document.removeEventListener('keydown', handleDropdown, true);
                document.removeEventListener('click', handleClickOutside, true);
            };
        }
    });

    return { ref, isComponentVisible, setIsComponentVisible };
};

export default useComponentVisible;
