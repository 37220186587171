import React, { ReactElement } from 'react';
import CardBlock from 'components/blocks/CardBlock';
import stylesBasicDetails from 'components/blocks/BasicDetailsBlock.module.scss';

interface Props {
    isCard: boolean;
    title?: string;
    children: ReactElement;
}

const EditFormContentWrapper = ({
    isCard,
    title,
    children,
}: Props): ReactElement =>
    isCard ? (
        <CardBlock className={stylesBasicDetails.root} lightBcg title={title}>
            {children}
        </CardBlock>
    ) : (
        <div>{children}</div>
    );

export default EditFormContentWrapper;
