import React, { ReactElement, useMemo, useState } from 'react';
import Modal from 'components/modal/Modal';
import EditForm from 'components/editForm/EditForm';
import EditFormBreadcrumbs from 'components/editForm/EditFormBreadcrumbs';
import {
    FieldTypeApiEnum,
    ValuesStringInterface,
} from 'submodules/api_middleware';
import {
    EditFieldValueFileInterface,
    FilterSize,
    GroupEditFieldInterface,
    LabelSize,
} from 'components/interfaces/GeneralInterface';

interface Props {
    show: boolean;
    onHide: () => void;
    action: 'add' | 'edit';
    onAction: (
        action: 'add' | 'edit',
        files: EditFieldValueFileInterface[]
    ) => void;
    files: EditFieldValueFileInterface[];
}

const FileAdditionalModal = ({
    show,
    onHide,
    onAction,
    files,
    action,
}: Props): ReactElement => {
    const [currentPage, setCurrentPage] = useState<number>(0);

    const fields = useMemo<GroupEditFieldInterface[]>(
        (): GroupEditFieldInterface[] => [
            {
                fields: [
                    {
                        name: `name_${currentPage}`,
                        value: files[parseInt(`${currentPage}`)].additionalData
                            .name,
                        label: 'File.name',
                        labelSize: LabelSize.TOP,
                        type: FieldTypeApiEnum.TEXT,
                        size: FilterSize.HALF,
                        required: true,
                    },
                    {
                        name: `description_${currentPage}`,
                        value: files[parseInt(`${currentPage}`)].additionalData
                            .description,
                        label: 'File.description',
                        labelSize: LabelSize.TOP,
                        type: FieldTypeApiEnum.TEXT,
                        size: FilterSize.FULL,
                    },
                ],
            },
        ],
        [currentPage]
    );

    const tranformDataOnAction = (
        values: ValuesStringInterface
    ): EditFieldValueFileInterface[] => {
        const updatedFiles: EditFieldValueFileInterface[] = [...files];
        for (const propertyName in values) {
            const fileNumber = Number(
                propertyName.substring(propertyName.lastIndexOf('_') + 1)
            );
            const property = propertyName.substring(
                0,
                propertyName.lastIndexOf('_')
            );

            updatedFiles[parseInt(`${fileNumber}`)].additionalData[
                `${property}`
            ] = values[`${propertyName}`];
        }
        return updatedFiles;
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            showCross
            title={
                <EditFormBreadcrumbs
                    step={currentPage}
                    setStep={setCurrentPage}
                    breadcrumbs={files.map((f) => f.fileName)}
                    allStepsClickable
                />
            }
            centerTitle
        >
            <>
                <EditForm
                    fields={fields}
                    action={(values) => {
                        onAction(action, tranformDataOnAction(values));
                        onHide();
                    }}
                    withCancelChangesBtn
                    cancelButtonTextKey={'general.cancel'}
                    buttonTextKey={
                        action === 'add' ? 'actions.add' : 'general.edit'
                    }
                    cancel={onHide}
                    centerButtons
                />
            </>
        </Modal>
    );
};

export default FileAdditionalModal;
