import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import classNames from 'classnames';
import Cropper from 'react-easy-crop';
import { useDropzone } from 'react-dropzone';
import PhotoHelp from './PhotoHelp';
import { showCroppedImage } from './helpers';
import { toBase64 } from 'utils/helpers/browser/file';
import Button from 'components/forms/Button';
import { useTranslation } from 'utils/localization';
import { MessageType, pushMessage } from 'components/layouts/Toast';
import styles from './PhotoUploader.module.scss';

export enum PhotoUploaderAspectEnum {
    rectangle = 0.6666,
    square = 1,
}

export enum PhotoUploaderAcceptTypesEnum {
    jpeg = 'image/jpeg',
    png = 'image/png',
    jpg = 'image/jpg',
}

export type PhotoUploaderCropShapeType = 'round' | 'rect';

interface Props {
    value: string;
    setValue: (value: string) => void;
    tempPhoto: string;
    setTempPhoto: (value: string) => void;
    disableButtons?: Dispatch<SetStateAction<boolean>>;
    aspect?: PhotoUploaderAspectEnum;
    acceptTypes?: PhotoUploaderAcceptTypesEnum[];
    cropShape?: PhotoUploaderCropShapeType;
    help?: string;
    helpKey?: string;
}

const TEN_MEGABYTES: Readonly<number> = 10485760;

const PhotoUploader = ({
    value,
    setValue,
    tempPhoto,
    setTempPhoto,
    disableButtons,
    aspect = PhotoUploaderAspectEnum.rectangle,
    cropShape = 'rect',
    acceptTypes,
    help,
    helpKey,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const [isPhotoSelected, setIsPhotoSelected] = useState<boolean>(!!value);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    useEffect(() => setIsPhotoSelected(!!value), [value]);

    const [selectedImage, setSelectedImage] = useState<{
        crop: {
            x: number;
            y: number;
        };
        zoom: number;
        aspect: number;
    }>({ crop: { x: 0, y: 0 }, zoom: 1, aspect: aspect });

    const onCropChange = (c: any) => {
        setSelectedImage({ ...selectedImage, crop: c });
    };

    const onZoomChange = (z: any) => {
        setSelectedImage({ ...selectedImage, zoom: z });
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: (acceptTypes
            ? acceptTypes
            : Object.values(PhotoUploaderAcceptTypesEnum)
        ).join(', '),
        onDropAccepted: (files) => {
            if (files[0].size > TEN_MEGABYTES) {
                pushMessage('fileExceedsMaxSize', MessageType.WARNING);
            } else {
                toBase64(files[0], 800).then((b64: any) => setTempPhoto(b64));
                setIsPhotoSelected(true);
                disableButtons && disableButtons(true);
            }
        },
    });

    const setValueLocal = (croppedImage: string) => {
        setValue(croppedImage);
        disableButtons && disableButtons(false);
    };

    const cancelSelectedPhoto = () => {
        setIsPhotoSelected(false);
        setValueLocal(undefined);
        setTempPhoto(undefined);
    };

    const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const helpExists = help || helpKey;
    return (
        <div className={styles.wrapper}>
            {isPhotoSelected ? (
                value && value.length > 1 ? (
                    <div
                        className={classNames(styles.imageContainer, {
                            [styles.size70]: helpExists,
                        })}
                    >
                        {/* eslint-disable @next/next/no-img-element */}
                        <img src={value} className={styles.image} />
                        <div
                            className={styles.deleteSelectedPhotoButton}
                            onClick={cancelSelectedPhoto}
                        >
                            {t('photoUploader.deletePhoto') as string}
                        </div>
                    </div>
                ) : (
                    <div
                        className={classNames(styles.cropperWrapper, {
                            [styles.size70]: helpExists,
                        })}
                    >
                        <div className={styles.imageWrapper}>
                            <Button
                                onClick={cancelSelectedPhoto}
                                anyWidth
                                isWhite
                                className={styles.deletePhotoButton}
                                resolveImmediately
                            >
                                X
                            </Button>
                            <Cropper
                                image={tempPhoto}
                                aspect={selectedImage.aspect}
                                zoom={selectedImage.zoom}
                                crop={selectedImage.crop}
                                onCropChange={onCropChange}
                                onZoomChange={onZoomChange}
                                onCropComplete={onCropComplete}
                                cropShape={cropShape}
                            />
                        </div>
                        <Button
                            onClick={() => {
                                showCroppedImage(
                                    tempPhoto,
                                    croppedAreaPixels,
                                    setValueLocal
                                );
                            }}
                        >
                            {t('photoUploader.confirmSelection') as string}
                        </Button>
                    </div>
                )
            ) : (
                <div
                    {...getRootProps()}
                    className={classNames(styles.dropzoneWrapper, {
                        [styles.size70]: helpExists,
                    })}
                >
                    <input {...getInputProps()} />
                    <div className={styles.dropzone}>
                        <div>{t('photoUploader.dropzone')}</div>
                    </div>
                </div>
            )}
            {(help || helpKey) && (
                <div className={styles.size30}>
                    <PhotoHelp help={help} helpKey={helpKey} />
                </div>
            )}
        </div>
    );
};

export default PhotoUploader;
