import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { AxiosResponse } from 'axios';
import fetcher, {
    MethodEnum,
    fetcherFull,
} from 'utils/helpers/browser/newFetcher';
import { StateInterface } from 'components/interfaces/GeneralInterface';
import {
    DisciplineEnum,
    PersonLicenseCreatePayloadApiInterface,
    PersonRoleNoDisciplineEnum,
    PersonAsbenceEditInterface,
    TransactionListApiInterface,
    ExportApiType,
    PersonUpdateMoreInfoApiInterface,
    PersonUpdateNameApiInterface,
    PersonUpdateContactsApiInterface,
} from 'submodules/api_middleware';
import { ApiResponseInterface } from 'utils/helpers/browser/getResponse';
import { QueryRequestInterface } from 'submodules/api_middleware/src/interfaces/InternalInterfaces';
import { AlertInterface } from 'submodules/api_middleware/src/interfaces/api/AlertInterface';

export interface FormResponseData {
    ok?: boolean;
    error?: string;
}

export class PersonService {
    async getPersonListFull(
        query: NextApiRequestQuery
    ): Promise<ApiResponseInterface> {
        return await fetcherFull(`/api/person/list`, MethodEnum.GET, query);
    }

    async getPersonList(
        query: NextApiRequestQuery
    ): Promise<ApiResponseInterface> {
        return await fetcher(`/api/person/list`, MethodEnum.GET, query);
    }

    async updateName(
        id: number,
        data: PersonUpdateNameApiInterface
    ): Promise<any> {
        const response: ResponseType = await fetcher(
            `/api/person/${id}/name/update`,
            MethodEnum.PUT,
            null,
            { id, data }
        );

        return response;
    }

    async updateMoreInfo(
        id: number,
        data: PersonUpdateMoreInfoApiInterface
    ): Promise<any> {
        const response: ResponseType = await fetcher(
            `/api/person/${id}/more-info/update`,
            MethodEnum.PUT,
            null,
            { id, data }
        );

        return response;
    }

    async updateContacts(
        id: number,
        data: PersonUpdateContactsApiInterface
    ): Promise<any> {
        const response: ResponseType = await fetcher(
            `/api/person/${id}/contacts/update`,
            MethodEnum.PUT,
            null,
            { id, data }
        );

        return response;
    }

    async updateBillingFields(id: number, data: StateInterface): Promise<any> {
        const response: ResponseType = await fetcher(
            `/api/person/${id}/billing/update`,
            MethodEnum.PUT,
            null,
            { id, data }
        );

        return response;
    }

    async updatePersonAddress(
        personId: number,
        addressId: number,
        data: StateInterface
    ): Promise<any> {
        const response: ResponseType = await fetcher(
            `/api/person/${personId}/address/${addressId}/update`,
            MethodEnum.PUT,
            null,
            { data }
        );

        return response;
    }

    async createPersonAddress(
        personId: number,
        data: StateInterface
    ): Promise<FormResponseData> {
        const response: FormResponseData = await fetcher(
            `/api/person/${personId}/address/add`,
            MethodEnum.PUT,
            null,
            { data }
        );

        return response;
    }

    async deletePersonAddress(
        personId: number,
        addressId: number
    ): Promise<any> {
        const response: ResponseType = await fetcher(
            `/api/person/${personId}/address/${addressId}/delete`,
            MethodEnum.DELETE
        );

        return response;
    }

    async createPersonLicense(
        personId: number,
        data: PersonLicenseCreatePayloadApiInterface
    ): Promise<FormResponseData> {
        return await fetcher(
            `/api/person/${personId}/license/create`,
            MethodEnum.POST,
            null,
            { data }
        );
    }

    async deletePersonLicence(
        personId: number,
        role:
            | PersonRoleNoDisciplineEnum.delegate
            | PersonRoleNoDisciplineEnum.referee
            | PersonRoleNoDisciplineEnum.coach,
        discipline: DisciplineEnum
    ): Promise<any> {
        return fetcher(
            `/api/person/${personId}/license/delete`,
            MethodEnum.DELETE,
            { role, discipline }
        );
    }

    async mergePersons(
        sourcePersonId: number,
        targetPersonId: number
    ): Promise<any> {
        return fetcherFull(`/api/person/merge`, MethodEnum.POST, undefined, {
            targetID: targetPersonId,
            sourceID: sourcePersonId,
        });
    }

    async createPersonAbsence(
        personId: number,
        data: PersonAsbenceEditInterface
    ): Promise<FormResponseData> {
        return await fetcher(
            `/api/person/${personId}/absence/create`,
            MethodEnum.POST,
            null,
            { data }
        );
    }

    async updatePersonAbsence(
        personId: number,
        absenceId: number,
        data: PersonAsbenceEditInterface
    ): Promise<any> {
        const response: ResponseType = await fetcher(
            `/api/person/${personId}/absence/${absenceId}/update`,
            MethodEnum.PUT,
            null,
            { data }
        );

        return response;
    }

    async deletePersonAbsence(
        personId: number,
        absenceId: number
    ): Promise<ApiResponseInterface> {
        return await fetcher(
            `/api/person/${personId}/absence/${absenceId}/delete`,
            MethodEnum.DELETE
        );
    }

    async getAccountTransactions(
        personId: number,
        query: QueryRequestInterface
    ): Promise<AxiosResponse<TransactionListApiInterface[] | ExportApiType>> {
        return await fetcher(
            `/api/person/${personId}/account/transactions`,
            MethodEnum.GET,
            query
        );
    }

    async getAlert(): Promise<AlertInterface> {
        return await fetcher(`/api/person/alert`, MethodEnum.GET);
    }
}
