import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { EditFieldValueFileInterface } from 'components/interfaces/GeneralInterface';
import { logErrorMessage } from 'utils/ErrorLogger';
import { useTranslation } from 'utils/localization';
import Plus from 'components/icon/icons/plus.svg';
import { FieldTypeApiEnum } from 'submodules/api_middleware';
import Button from 'components/forms/Button';
import Icon from 'components/icon/Icon';
import styles from 'components/editForm/EditForm.module.scss';

interface Props {
    reactKey: string;
    type: FieldTypeApiEnum;
    label: string;
    value:
        | boolean
        | string
        | number
        | Date
        | string[]
        | EditFieldValueFileInterface[];
    buttonText: string;
    onButtonClick: () => void;
    biggerInput: boolean;
    disabled: boolean;
    infoType?: InfoType;
}

export enum InfoType {
    INFO = 'info',
    DANGER = 'danger',
    WARNING = 'warning',
    SUCCESS = 'success',
    DEFAULT = 'default',
}

export const isSpecialInput = (type: FieldTypeApiEnum): boolean =>
    [
        FieldTypeApiEnum.INFO,
        FieldTypeApiEnum.BUTTON,
        FieldTypeApiEnum.ADDBUTTON,
        FieldTypeApiEnum.BREAK,
        FieldTypeApiEnum.REACT_DOM,
    ].includes(type);

/**
 * A components for fields of type {@link FieldTypeApiEnum.INFO},
 * {@link FieldTypeApiEnum.ADDBUTTON}, {@link FieldTypeApiEnum.BUTTON},
 * {@link FieldTypeApiEnum.REACT_DOM} that represent control elements of a form
 * in the {@link EditForm}.
 */
const SpecialInput = ({
    reactKey,
    type,
    label,
    value,
    buttonText,
    onButtonClick,
    biggerInput,
    disabled,
    infoType,
}: Props): ReactElement => {
    const { t } = useTranslation();

    switch (type) {
        case FieldTypeApiEnum.INFO:
            return (
                <div
                    className={classNames(
                        styles.reasonInfo,
                        styles[`${infoType}`]
                    )}
                    key={reactKey}
                >
                    {label && <div>{t(label)}</div>}
                    {value}
                </div>
            );
        case FieldTypeApiEnum.ADDBUTTON:
            return (
                <div className={styles.specialButtonWrap} key={reactKey}>
                    <div
                        className={classNames(styles.specialAddButton, {
                            [styles.disabled]: disabled,
                        })}
                        onClick={disabled ? undefined : onButtonClick}
                        key={label + '-add-' + reactKey}
                    >
                        <Icon icon={Plus} className={styles.iconPlus} noStrip />
                        <span>{t(buttonText)}</span>
                    </div>
                </div>
            );
        case FieldTypeApiEnum.BUTTON:
            return (
                <div className={styles.specialButtonWrap} key={reactKey}>
                    <div
                        className={classNames(styles.specialButton, {
                            [styles.bigger]: biggerInput,
                        })}
                    >
                        <Button isDisabled={disabled} onClick={onButtonClick}>
                            {buttonText}
                        </Button>
                    </div>
                </div>
            );
        case FieldTypeApiEnum.BREAK:
            return (
                <div
                    className={styles.break}
                    key={label + '-break-' + reactKey}
                />
            );
        case FieldTypeApiEnum.REACT_DOM:
            return <div>{value}</div>;
        default:
            logErrorMessage(
                'SpecialInput FieldTypeApiEnum - unknown value: ' + type
            );
            return null;
    }
};

export default SpecialInput;
