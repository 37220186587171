import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { TFunction } from 'next-i18next';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { sortedByName, SortedByNameInterface } from './utils/sortable';
import { useTranslation } from 'utils/localization';
import SortASC from 'components/icon/icons/sort_asc.svg';
import SortDESC from 'components/icon/icons/sort_desc.svg';
import ArrowUp from 'components/icon/icons/arrow_up.svg';
import ArrowDown from 'components/icon/icons/arrow_down.svg';
import Icon from 'components/icon/Icon';
import {
    ColumnHeadSortInterface,
    ColumnHeadSortType,
    StateInterface,
} from 'components/interfaces/GeneralInterface';
import {
    getLocationSearchQuery,
    SORT_KEY,
} from 'submodules/api_middleware/src/client';
import styles from './SortableHeader.module.scss';

interface Props {
    label: string;
    sortBy: ColumnHeadSortType;
    activeFilters?: StateInterface;
    onSearch?: (s: NextApiRequestQuery) => void;
    setLoadingAction: Dispatch<SetStateAction<boolean>>;
    icon: ReactElement;
    isCentered?: boolean;
    sortedBy: string[];
    setSortedBy: (sorted: string[]) => void;
}

export enum SortType {
    ASC = 'asc',
    DESC = 'desc',
}

const SortableHeader = ({
    label,
    sortedBy: searchSorted,
    setSortedBy,
    sortBy,
    setLoadingAction,
    activeFilters,
    onSearch,
    icon,
    isCentered,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const Router = useRouter();
    const isSingleSort = typeof sortBy === 'string';

    const routerSortedQuery = Router.query[`${SORT_KEY}`] as string | string[];
    const sortedBy: string[] = onSearch
        ? searchSorted
        : routerSortedQuery
        ? Array.isArray(routerSortedQuery)
            ? routerSortedQuery
            : [routerSortedQuery]
        : [];

    const handleSort = async (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        type: SortType | null,
        sortKey: string
    ) => {
        setLoadingAction(true);

        let sorted: string[] = [];
        const names: string[] = [];

        if (!type) {
            const sIndex = sortedBy.findIndex((sort) => {
                const parts = sort.split(':');
                if (parts[0] === sortKey) return true;
                return false;
            });
            if (sIndex !== -1) {
                sortedBy.splice(sIndex, 1);
            }

            sorted = sorted.concat(sortedBy);
        } else {
            sortedBy.forEach((sort) => {
                const parts = sort.split(':');

                if (parts[0] === sortKey) {
                    if (parts[1] !== type) {
                        sorted.push(`${sortKey}:${type}`);
                    }
                } else {
                    if (e.shiftKey) {
                        sorted.push(sort);
                    }
                }

                names.push(parts[0]);
            });

            if (!names.some((name) => name === sortKey)) {
                sorted.push(`${sortKey}:${type}`);
            }
        }

        if (onSearch) {
            delete activeFilters.page;
            setSortedBy(sorted);
            onSearch({
                ...activeFilters,
                SORT_KEY: !sorted.length ? undefined : sorted,
            });
        } else {
            const query = getLocationSearchQuery();
            if (!sorted.length) {
                delete query[`${SORT_KEY}`];
            } else {
                query[`${SORT_KEY}`] = sorted;
            }
            delete query.page;
            await Router.push(
                {
                    pathname: Router.asPath.split('?')[0],
                    query: query,
                },
                undefined,
                { scroll: false }
            );
        }
    };

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.centerText]: isCentered,
            })}
        >
            {(isSingleSort &&
                getSingleSortHeader({
                    sortBy: sortBy as string,
                    label,
                    icon,
                    sortedBy,
                    handleSort,
                })) ||
                getMultiSortHeader({
                    t,
                    sortBy: sortBy as ColumnHeadSortInterface[],
                    sortedBy,
                    handleSort,
                })}
        </div>
    );
};

export default SortableHeader;

const getSortType = (sortType: string): SortType => {
    if (!sortType) {
        return SortType.ASC;
    }

    switch (sortType) {
        case SortType.ASC:
            return SortType.DESC;
        case SortType.DESC:
            return null;
        default:
            return SortType.ASC;
    }
};

const getIconBySortType = (sortType: string): ReactElement => {
    let icon;
    if (!sortType) {
        icon = (
            <>
                <Icon icon={ArrowUp} />
                <Icon icon={ArrowDown} />
            </>
        );
    }

    switch (sortType) {
        case SortType.ASC:
            icon = <Icon icon={SortASC} />;
            break;
        case SortType.DESC:
            icon = <Icon icon={SortDESC} />;
            break;
        default:
            icon = (
                <>
                    <Icon icon={ArrowUp} />
                    <Icon icon={ArrowDown} />
                </>
            );
    }

    return <div className={styles.icon}>{icon}</div>;
};

interface HeaderSortInterface {
    sortedName: SortedByNameInterface;
    newSortType: SortType;
    sortBy: string;
    sortedBy: string[];
    label: string;
    icon: ReactElement;
    handleSort: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        type: SortType | null,
        sortKey: string
    ) => Promise<void>;
}

interface SingleSortInterface {
    sortBy: string;
    sortedBy: string[];
    label: string;
    icon: ReactElement;
    handleSort: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        type: SortType | null,
        sortKey: string
    ) => Promise<void>;
}

interface MultiSortInterface {
    t: TFunction;
    sortBy: ColumnHeadSortInterface[];
    sortedBy: string[];
    handleSort: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        type: SortType | null,
        sortKey: string
    ) => Promise<void>;
}

const getSortHeader = ({
    sortedName,
    newSortType,
    sortBy,
    label,
    icon,
    sortedBy,
    handleSort,
}: HeaderSortInterface): ReactElement => (
    <div
        className={styles.sortWrapper}
        onClick={(e) => handleSort(e, newSortType, sortBy)}
    >
        <span className={styles.inputIcon}>
            {getIconBySortType(sortedName?.sortedType)}
        </span>
        {label}
        {icon && icon}
        {sortedBy.length > 1 && sortedName?.index && (
            <span className={styles.round}>{sortedName.index}</span>
        )}
    </div>
);

const getSingleSortHeader = ({
    sortBy,
    label,
    icon,
    sortedBy,
    handleSort,
}: SingleSortInterface): ReactElement => {
    const sortedName = sortedByName(sortBy, sortedBy);
    const newSortType = getSortType(sortedName?.sortedType);
    return getSortHeader({
        sortedName,
        newSortType,
        handleSort,
        icon,
        label,
        sortBy,
        sortedBy,
    });
};

const getMultiSortHeader = ({
    t,
    sortBy,
    sortedBy,
    handleSort,
}: MultiSortInterface): ReactElement[] => {
    return sortBy.map((sort, index) => {
        const sortedName = sortedByName(sort.key, sortedBy);
        const newSortType = getSortType(sortedName?.sortedType);

        return (
            <div key={`multiSort-${sort.key}-${index}`}>
                {getSortHeader({
                    sortedName,
                    newSortType,
                    handleSort,
                    icon: null,
                    label: t(sort.labelKey),
                    sortBy: sort.key,
                    sortedBy,
                })}
                {index < sortBy.length - 1 && (
                    <span className={styles.joiner}>/</span>
                )}
            </div>
        );
    });
};
